<template>
  <LayoutMain>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Pegawai" name="pegawai" class="flex flex-column gap-16">
        <table-pegawai/>
      </el-tab-pane>
      <el-tab-pane label="Presensi pegawai" name="absen"  class="flex flex-column gap-16">
        <table-pegawai-absensi />
      </el-tab-pane>
      <el-tab-pane label="Rekap gaji" name="gaji"  class="flex flex-column gap-16">
        <table-pegawai-rekap-gaji/>
      </el-tab-pane>
    </el-tabs>
  </LayoutMain>
</template>
    
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  // import DoughnutChart from '@/components/components/ChartDoughnut.vue'
  import TablePegawai from '@/components/components/TablePegawai.vue'
  import TablePegawaiAbsensi from '@/components/components/TablePegawaiAbsensi.vue'
  import TablePegawaiRekapGaji from '@/components/components/TablePegawaiRekapGaji.vue'
  
  export default {
    name: "KepegawaianPage",
    components: {
      LayoutMain,
      // DoughnutChart,
      TablePegawai,
      TablePegawaiAbsensi,
      TablePegawaiRekapGaji,
    },
    data() {
      return {
        activeName: 'pegawai',
      }
    },
    mounted() {
      console.log(this.$route.query)
      switch (this.$route.query.tab) {
        case 'absen':
          this.activeName = 'absen'
          break;
        case 'gaji':
          this.activeName = 'gaji'
          break;
        default:
          this.activeName = 'pegawai'
          break;
      }
    },
    methods: {
      // code
    },
  }
</script>