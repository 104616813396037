<template>
  <LayoutMain>
    <div>
      <button
        class="btn link distance"
        @click="$router.go(-1)">
        <Back class="fa"/>
        {{'Kembali' }}
      </button>
      <div class="box gap-32 p-32">
        <div class="flex between">
          <div class="card profile">
            <img class="avatar" :src="`${siswa.avatar}`"/>
            <div class="info">
              <span class="title single-line">{{ siswa.nama_lengkap }}</span>
              <span class="desc">{{ siswa.nis }}</span>
              <span class="btn">Kelas {{ siswa.kelas }}</span>
            </div>
          </div>
          <router-link
            :to="{ name: 'FormSiswaEdit' }"
            class="btn link p-16">
            Edit siswa
          </router-link>
        </div>
        <aside>
            <el-tabs v-model="activeName">
              <el-tab-pane
                label="Siswa"
                name="siswa"
                class="flex flex-column gap-16">
                <div class="form-group">
                  <label>Nama lengkap</label>
                  <input v-model="siswa.nama_lengkap" disabled>
                </div>
                <div class="form-group">
                  <label>Nama Panggila</label>
                  <input v-model="siswa.nama_panggilan" disabled>
                </div>
                <div class="form-group">
            <label>Nomor induk kependudukan</label>
            <input v-model="siswa.nik" disabled>
          </div>
          <div class="form-group">
            <label>Nomor induk siswa</label>
            <input v-model="siswa.nis" disabled>
          </div>
          <div class="form-group">
            <label>Jenis Kelamin</label>
            <el-select v-model="siswa.jenis_kelamin" disabled>
              <el-option
                v-for="item in jenisKelaminArray"
                :key="item.id"
                :value="item.id"
                :label="item.label"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Tempat lahir</label>
            <input v-model="siswa.tempat_lahir" disabled>
          </div>
          <div class="form-group">
            <label>Tanggal lahir</label>
            <el-date-picker
              v-model="siswa.tanggal_lahir"
              type="date"
              disabled/>
          </div>
          <div class="form-group">
            <label>Agama</label>
            <el-select v-model="siswa.agama" disabled>
              <el-option
                v-for="item in agamaArray"
                :key="item.id_agama"
                :value="item.id_agama"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Alamat lengkap</label>
            <input v-model="siswa.alamat" disabled>
          </div>
          <div class="form-group">
            <label/>
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input v-model="siswa.kelurahan" disabled>
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input v-model="siswa.kecamatan" disabled>
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input v-model="siswa.kota" disabled>
              </div>
              <div class="form-group">
                <label>provinsi</label>
                <input v-model="siswa.provinsi" disabled>
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input v-model="siswa.kode_pos" disabled>
              </div>
            </div>
          </div>
              </el-tab-pane>
              <el-tab-pane label="Orang tua" name="ortu"  class="flex flex-column gap-16">
                <!-- ayah -->
                <label class="fw-500">Data ayah</label>
                <div class="form-group">
                  <label>Nama lengkap</label>
                  <input v-model="ortu.nama_ayah" disabled>
                </div>
                <div class="form-group">
                  <label>Nomor induk kependudukan</label>
                  <input v-model="ortu.nik_ayah" disabled>
                </div>
                <div class="form-group">
                  <label>E-mail</label>
                  <input type="email" v-model="ortu.email_ayah" disabled>
                </div>
                <div class="form-group">
                  <label>No. handphone</label>
                  <input v-model="ortu.no_telp_ayah" disabled>
                </div>
                <div class="form-group">
                  <label>Agama</label>
                  <el-select v-model="ortu.agama_ayah" disabled>
                    <el-option
                      v-for="item in agamaArray"
                      :key="item.id_agama"
                      :value="item.id_agama"
                      :label="item.keterangan"/>
                  </el-select>
                </div>
                <div class="form-group">
                  <label>Pekerjaan</label>
                  <input v-model="ortu.pekerjaan_ayah" disabled>
                </div>
                <div class="form-group">
                  <label>Alamat lengkap</label>
                  <input v-model="ortu.alamat_ayah" disabled>
                </div>
                <div class="form-group">
                  <label/>
                  <div class="flex flex-column gap-16">
                    <div class="form-group">
                      <label>Kelurahan/desa</label>
                      <input v-model="ortu.kelurahan_ayah" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kecamatan</label>
                      <input v-model="ortu.kecamatan_ayah" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kabupaten/kota</label>
                      <input v-model="ortu.kota_ayah" disabled>
                    </div>
                    <div class="form-group">
                      <label>provinsi</label>
                      <input v-model="ortu.provinsi_ayah" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kode pos</label>
                      <input v-model="ortu.kode_pos_ayah" disabled>
                    </div>
                  </div>
                </div>
                <!-- ibu -->
                <label class="fw-500">Data ibu</label>
                <div class="form-group">
                  <label>Nama lengkap</label>
                  <input v-model="ortu.nama_ibu" disabled>
                </div>
                <div class="form-group">
                  <label>Nomor induk kependudukan</label>
                  <input v-model="ortu.nik_ibu" disabled>
                </div>
                <div class="form-group">
                  <label>E-mail</label>
                  <input type="email" v-model="ortu.email_ibu" disabled>
                </div>
                <div class="form-group">
                  <label>No. handphone</label>
                  <input v-model="ortu.no_telp_ibu" disabled>
                </div>
                <div class="form-group">
                  <label>Agama</label>
                  <el-select v-model="ortu.agama_ibu" disabled>
                    <el-option
                      v-for="item in agamaArray"
                      :key="item.id_agama"
                      :value="item.id_agama"
                      :label="item.keterangan"/>
                  </el-select>
                </div>
                <div class="form-group">
                  <label>Pekerjaan</label>
                  <input v-model="ortu.pekerjaan_ibu" disabled>
                </div>
                <div class="form-group">
                  <label>Alamat lengkap</label>
                  <input v-model="ortu.alamat_ibu" disabled>
                </div>
                <div class="form-group">
                  <label/>
                  <div class="flex flex-column gap-16">
                    <div class="form-group">
                      <label>Kelurahan/desa</label>
                      <input v-model="ortu.kelurahan_ibu" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kecamatan</label>
                      <input v-model="ortu.kecamatan_ibu" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kabupaten/kota</label>
                      <input v-model="ortu.kota_ibu" disabled>
                    </div>
                    <div class="form-group">
                      <label>provinsi</label>
                      <input v-model="ortu.provinsi_ibu" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kode pos</label>
                      <input v-model="ortu.kode_pos_ibu" disabled>
                    </div>
                  </div>
                </div>
                <!-- wali -->
                <label class="fw-500">Data wali</label>
                <div class="form-group">
                  <label>Nama lengkap</label>
                  <input v-model="ortu.nama_wali" disabled>
                </div>
                <div class="form-group">
                  <label>Nomor induk kependudukan</label>
                  <input v-model="ortu.nik_wali" disabled>
                </div>
                <div class="form-group">
                  <label>E-mail</label>
                  <input type="email" v-model="ortu.email_wali" disabled>
                </div>
                <div class="form-group">
                  <label>No. handphone</label>
                  <input v-model="ortu.no_telp_wali" disabled>
                </div>
                <div class="form-group">
                  <label>Agama</label>
                  <el-select v-model="ortu.agama_wali" disabled>
                    <el-option
                      v-for="item in agamaArray"
                      :key="item.id_agama"
                      :value="item.id_agama"
                      :label="item.keterangan"/>
                  </el-select>
                </div>
                <div class="form-group">
                  <label>Pekerjaan</label>
                  <input v-model="ortu.pekerjaan_wali" disabled>
                </div>
                <div class="form-group">
                  <label>Alamat lengkap</label>
                  <input v-model="ortu.alamat_wali" disabled>
                </div>
                <div class="form-group">
                  <label/>
                  <div class="flex flex-column gap-16">
                    <div class="form-group">
                      <label>Kelurahan/desa</label>
                      <input v-model="ortu.kelurahan_wali" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kecamatan</label>
                      <input v-model="ortu.kecamatan_wali" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kabupaten/kota</label>
                      <input v-model="ortu.kota_wali" disabled>
                    </div>
                    <div class="form-group">
                      <label>provinsi</label>
                      <input v-model="ortu.provinsi_wali" disabled>
                    </div>
                    <div class="form-group">
                      <label>Kode pos</label>
                      <input v-model="ortu.kode_pos_wali" disabled>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Administrasi" name="administrasi"  class="flex flex-column gap-16">
                <!-- administrasi -->
              </el-tab-pane>
            </el-tabs>
          </aside>
      </div>
    </div>
  </LayoutMain>
  </template>
  
<script>
  import { mapGetters } from 'vuex';
  import LayoutMain from '../layout/LayoutMain.vue';
  import { jenisKelamin } from '@/constant'

  export default {
    name: "ViewSiswa",
    components: { LayoutMain },
    data() {
      return {
        jenisKelaminArray: jenisKelamin,
        activeName: 'siswa',
        siswa: [],
        ortu: [],
      }
    },
    computed: {
      ...mapGetters({
        agamaArray: 'agama',
        kategoriWali: 'kategoriWali',
        kelasArray: 'kelas',
      }),
    },
    mounted() {
      const param = {
          "id":this.$route.params.id,
        }
        this.$http.post('v1/siswa/detilsiswa', JSON.stringify(param))
          .then((res) => {
            this.siswa = res.data.data.siswa
            this.ortu = res.data.data.ortu
          })
          .catch((err) => {
            console.log(err)
          });
    }
  }
</script>