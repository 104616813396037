// create consttant variable
export const jenisPegawai =  [
    {
      id: 1,
      label: 'Guru'
    },
    {
      id: 2,
      label: 'Pegawai'
    },
    {
      id: 3,
      label: 'Staf'
    },
    {
      id: 4,
      label: 'Maggang'
    }
]

export const statusSiswa = [
    {
        id: '1',
        label: 'Aktif'
    },
    {
        id: '2',
        label: 'Tidak aktif'
    }
]

export const bulan = [
    {
        id: 1,
        label: 'Januari'
    },
    {
        id: 2,
        label: 'Februari'
    },
    {
        id: 3,
        label: 'Maret'
    },
    {
        id: 4,
        label: 'April'
    },
    {
        id: 5,
        label: 'Mei'
    },
    {
        id: 6,
        label: 'Juni'
    },
    {
        id: 7,
        label: 'Juli'
    },
    {
        id: 8,
        label: 'Agustus'
    },
    {
        id: 9,
        label: 'September'
    },
    {
        id: 10,
        label: 'Oktober'
    },
    {
        id: 11,
        label: 'November'
    },
    {
        id: 12,
        label: 'Desember'
    },
]

export const kategoriPembayaran = [
    {
        id: 1,
        label: 'SPP'
    },
    {
        id: 2,
        label: 'Uang pangkal'
    },
    {
        id: 3,
        label: 'Lainya'
    },
]

export const statusPembayaran = [
    {
        id: 1,
        label: 'Lunas'
    },
    {
        id: 2,
        label: 'Belum dibayar'
    },
    {
        id: 3,
        label: 'Pendding'
    },
    {
        id: 4,
        label: 'Ditolak'
    },
]

export const absenStatus = [
    {
        id: 1,
        label: 'Masuk'
    },
    {
        id: 2,
        label: 'Cuti'
    },
    {
        id: 3,
        label: 'Izin'
    },
    {
        id: 4,
        label: 'Sakit'
    },
]

export const jenisKelamin = [
    {
        id: 'l',
        label: 'Laki-laki'
    },
    {
        id: 'p',
        label: 'Perempuan'
    }
]

export const tahun = Array.from({length: 10}, (_, i) => (new Date().getFullYear()+3) - i)

export const tanggal = Array.from({length: 31}, (_, i) => 1 + i)

export const tipePembayaran = [
    {
        id: 1,
        label: 'Tunggal'
    },
    {
        id: 2,
        label: 'Berkala'
    }
]


export const hakAksesArray = [
    {
        id: 1,
        label: 'Admin/TU'
    },
    {
        id: 2,
        label: 'Guru'
    },
    {
        id: 3,
        label: 'Kepala sekolah'
    },
]

export const jenisSekolah = [
    {
        id: 1,
        label: 'Sekolah negeri'
    },
    {
        id: 2,
        label: 'Sekolah swasta'
    }
]

export const jenjangPendidikan = [
    {
        id: 1,
        label: 'Taman kanak-kanak'
    },
    {
        id: 2,
        label: 'Sekolah dasar'
    },
    {
        id: 3,
        label: 'Sekolah menengah pertama'
    },
    {
        id: 4,
        label: 'Sekolah menengah atas'
    }
]

export const daftarBank = [
    {
        id: 1,
        label: 'BCA'
    },
    {
        id: 2,
        label: 'BNI'
    },
    {
        id: 3,
        label: 'BRI'
    },
    {
        id: 3,
        label: 'BSI'
    },
    {
        id: 4,
        label: 'BTN'
    },
    {
        id: 5,
        label: 'Mandiri'
    }
]