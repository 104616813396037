<template>
  <div class="flex gap-16 m-tb-8 w-100">
    <div class="input-suffix">
      <input
        v-model="searchTerm"
        placeholder="Cari email"
        @keyup="getDataSearch">
      <Search class="fa"/>
    </div> 
    
    <button class="btn reset" @click="reset">Atur ulang</button>
    <router-link :to="{ name: 'FormAkunWali' }" class="btn link right p-16">Tambah data</router-link>
  </div>
  <table>
    <thead>
      <tr>
        <th v-for="header in header" :key="header">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="wali in wali"
        :key="wali.id">
        <td class="single-line">
          <router-link
            :to="{ name: 'EditFormAkunWali', params: {id:wali.id} }" 
            class="text-link">
            {{ wali.email }}
          </router-link>
        </td>
        <td >{{ wali.nama }}</td>
        <td >{{ wali.no_hp }}</td>
        <td>***************</td> 
      </tr>
      <tr>
        <td colspan="6" class="text-center">
          <span
            v-if="wali.length"
            class="pagination">
            <button
              v-if="page > 1"
              @click="getDataPrevious"
              class="btn link"> 
                <ArrowLeft class="fa"/>
                Sebelumnya
              </button>
              <button
                v-if="nextPage"
                @click="getDataNext"
               class="btn link">
                Selanjutnya
                <ArrowRight class="fa"/> 
              </button>
          </span>
          <span v-else>
            Tidak ada data
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
    
<script>
import { statusSiswa } from '@/constant';

  //   import axios
  export default {
    name: 'TableAkunWali',
    props: {

    },

    components: {  },
    data() {
      return {
        searchKelas: null,
        searchTerm: '',
        statusArray: statusSiswa,
        kelasArray: [],
        header: ['Email', 'Nama', 'No. Hp', 'Password'],
        wali: [],
        nextPage: false,
        page: 1,
        searchStatus: null,
      }
    },

    mounted() {
      this.getData();
    },

    methods: {
      getDataPrevious() {
        this.page -= 1;
        this.getData();
      },
      getDataNext() {
        this.page += 1;
        this.getData();
      },
      getDataSearch() {
        this.page = 1;
        this.getData();
      },
      getData() {
        const param = {
          "q":this.searchTerm,
          "status":this.searchStatus,
          "kelas":this.searchKelas,
          "page":this.page
        }
        this.$http.post('v1/account/wali', JSON.stringify(param))
          .then((res) => {
            this.wali = res.data.data
            this.nextPage = res.data.data.length == 15;
          })
          .catch((err) => {
            console.log(err)
          });
      },
      jenisKelamin(val) {
        if (val === 'l') return 'Laki-laki'
        else return 'Perempuan' 
      },
      statusSiswa(val) {
        if (val == 1) return 'Aktif'
        else 'Tidak aktif'
      },
      reset() {
        this.page = 1;
        this.searchTerm = '';
        this.searchKelas = null;
        this.searchStatus = null;
        this.getData();
        },
    },
  }
</script>