<template>
  <LayoutMain> 
    <el-tabs v-model="activeName">
      <el-tab-pane label="Tagihan" name="Tagihan" class="flex flex-column gap-16">
        <table-pembayaran/>
      </el-tab-pane>
      <el-tab-pane label="Proses verifikasi" name="pembayaran" class="flex flex-column gap-16">
        <table-verifikasi-pembayaran/>
      </el-tab-pane>
      <el-tab-pane label="Transaksi" name="Transaksi" class="flex flex-column gap-16">
        <table-riwayat-pembayaran/>
      </el-tab-pane>
    </el-tabs>
  </LayoutMain>
</template>
    
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import TablePembayaran from '@/components/components/TablePembayaran.vue'
  import TableVerifikasiPembayaran from '@/components/components/TableVerifikasiPembayaran.vue'
  import TableRiwayatPembayaran from '@/components/components/TableRiwayatPembayaran.vue'
  
  export default {
    name: "PembayaranPage",
    components: {
      LayoutMain,
      TablePembayaran,
      TableVerifikasiPembayaran,
      TableRiwayatPembayaran
    },
    data() {
      return {
        activeName: 'Tagihan',
        totalSiswa: 300,
        totalPembayaran: 240,
        siswaTotal: [
          {
            label: 'Aktif',
            color: '#0197CF',
            value: 240
          },
          {
            label: 'Tidak aktif',
            color: '#FFC01E',
            value: 60
          }
        ],
        statusPembayaran: [
          {
            label: 'Berhasil',
            color: '#43936C',
            value: 100
          },
          {
            label: 'Menunggu',
            color: '#FFA537',
            value: 50
          },
          {
            label: 'Belum',
            color: '#FACC15',
            value: 98
          },
          {
            label: 'Ditolak',
            color: '#CB3A31',
            value: 2
          }
        ]
      }
    },
    created() {
      //created code
    },
    methods: {
      // code
    },
  }
</script>