<template>
  <div class="PlacesAutocomplete">
    <input
      v-model.trim="input"
      placeholder="Cari lokasi..."
      type="text"
      :readOnly="placeSelected"
      @keydown.esc="reset">
    <div
      v-if="placeSelected || input"
      class="PlacesAutocomplete__Reset"
    >
    <Close class="fa" @click="reset" />
    </div>
    <ul
      v-if="!placeSelected && places.length"
      class="list-unstyled PlacesAutocomplete__Ul"
    >
      <li
        v-if="noPlaces"
        class="PlacesAutocomplete__NoPlaces"
      >
        <div class="PlacesAutocomplete__NoPlaces__Icons">
          <span class="fa-stack">
            <i class="fa fa-globe fa-stack-1x"/>
            <i class="fa fa-ban fa-stack-2x"/>
          </span>
        </div>
        <div class="PlacesAutocomplete__NoPlaces__Message">
          {{ trans('timeline.postbox.no_places_found') }}
        </div>
      </li>
      <li
        v-for="place in places"
        :key="place.place_id"
        class="PlacesAutocomplete__Place"
        @click="select(place)"
      >{{ place.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'PlacesAutocomplete',

  props: {
    alamat: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      placeSelected: false,
      input: '',
      places: [],
      noPlaces: false,
      place: {},
    };
  },

  watch: {
    input: debounce(function debounced(newInput) {
      this.places = [];
      this.noPlaces = false;

      if (this.input) {
        this.$http.get(`https://nominatim.openstreetmap.org/search?q=${newInput}&format=json&limit=10`)
          .then((predictions) => {
            this.places = predictions.data.map(i => ({
              place_id: i.place_id,
              name: i.display_name,
              lat: i.lat,
              long: i.lon,
            }));
          })
          .catch(() => {
            this.noPlaces = true;
          });
      }
      this.$emit('getInput', this.input);
    }, 700),
  },
  mounted() {
    this.$nextTick(() => {
      if(this.alamat.length) {
        this.input = this.alamat
      this.placeSelected = true;
        this.places = [];
        this.noPlaces = false;
      }
    })
  },
  methods: {
    select(place) {
      this.placeSelected = true;
      this.place = place;
      this.input = this.place.name;
      this.$emit('placeSelected', place);
    },

    reset() {
      this.placeSelected = false;
      this.places = [];
      this.noPlaces = false;
      this.input = '';
      this.place = {};
      this.$emit('placeReset');
    },
  },
};
</script>

<style rel="stylesheet/stylus"
       lang="stylus"
       scoped>
  .PlacesAutocomplete
    width 100%
    input
      padding: 8px 26px 8px 8px;
    &__Reset
      float right
      position relative
      top -30px
      font-size 14px
      color #e0e0e0
      cursor pointer
      margin-right 8px

    &__NoPlaces
      color #78909c
      padding 30px 0 10px 0
      text-align center

      &__Icons
        font-size 1.7rem

      &__Message
        margin-top 20px

    &__Ul
      border-radius 0 0 8px 8px
      border-left 1px solid #e0e0e0
      border-right 1px solid #e0e0e0
      border-bottom 1px solid #e0e0e0
      list-style-type none
      padding 0
      margin 0
    &__Place
      cursor pointer
      padding 4px 6px
      border-top 1px solid #e0e0e0
      &:first-child
        border none

      &:hover
        background-color #0378a3
        color white
</style>
