<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">  
        <el-tabs v-model="activeName">
          <el-tab-pane label="Detil pembayaran" name="Pembayaran" class="flex flex-column gap-16">
            <div class="body mb-16">
              <div class="form-group">
                <label>No Transaksi</label>
                <input disabled type="text" v-model="noTransaksi">
              </div>
              <div class="form-group">
                <label>Jumlah</label>
                <input disabled type="text" :value="`Rp ${jumlah}`">
              </div>
              <div class="form-group">
                <label>Metode Pambayaran</label>
                <input disabled type="text" v-model="metodePembayaran">
              </div>  
              <div class="form-group">
                <label>Bukti pembayaran</label>
                <div class="flex center">
                  <input disabled type="text" v-model="bukti_code">
                <label @click="download" class="absolute right mr-8 text-link">Download</label>
                </div>
              </div>
              <div class="form-group">
                <label>Tanggal</label>
                <input disabled type="text" v-model="tanggal">
              </div>  
              <div class="form-group">
                <label>Status Pambayaran</label>
                <input disabled type="text" v-model="statusString">
              </div>  
            </div>

            <div class="body"> 
                <b class="title mb-16">Rincian transaksi</b>
                <div class="body" v-for="(item, index) in detil"
                :key="item.id">  
                  <b class="title">Transaksi {{index+1}}</b>
                    <div class="form-group">
                        <label>Jenis pembayaran</label>
                        <input disabled type="text" v-model="item.jenisPembayaran">
                      </div>
                      <div class="form-group">
                        <label>Bulan</label>
                        <input disabled type="text" v-model="item.bulan">
                      </div>
                      <div class="form-group">
                        <label>Jatuh tempo</label>
                        <input disabled type="text" v-model="item.jatuhTempo">
                      </div>   
                      <div class="form-group">
                        <label>Nominal</label>
                        <input disabled type="text" :value="`Rp ${formatCurrency(item.nominal)}`">
                      </div>   
                </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="download-invoice">
          <button
            class="btn primary"
            @click="downloadInvoice">
            Download invoice
          </button>
        </div>
        <div v-if="status==2" class="footer gap-24">
          <button v-if="!angsuran" class="btn danger" @click="this.$router.push({ name: 'FormAngsuran', params: {id:$route.params.id}})">Batal</button>
          <button class="btn primary" @click="save">Verifikasi</button>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="Pembayaran Tunai"
      width="90vh">
      <span class="form">
       Pembayaran berhasil dikonfirmasi
      </span>
      <template #footer>
        <span class="dialog-footer flex gap-24 end"> 
          <button class="btn primary" @click="exit">Oke</button>
        </span>
      </template>
    </el-dialog>
  </LayoutMain>
</template>
  
<script>
  // import axios from 'axios'
  import LayoutMain from '@/components/layout/LayoutMain.vue' 
  import helper from '@/lib/helper'
  import { ElMessage } from 'element-plus'
  export default {
    name: 'FormKonfirmasiPembayaran',
    components: { LayoutMain },
    props: {
      isEdit: {
        Type: Boolean,
        require: false,
        default: false
      }
    },

    data() {
      return {
        activeName: 'Pembayaran',
        dialogVisible: false, 
        noTransaksi: '',
        jenisPembayaran: '',
        bulan: '', 
        jatuhTempo: '', 
        jumlah: '',
        tanggal: null,
        status: '',
        statusString: '',
        angsuran: null,
        bukti_code: null,
        metodePembayaran:'',
        detil:[]
      }
    },
    created() {
     this.getData();
    },
    methods: {
      getData() { 
        const param = {
        "id":this.$route.params.id, 
      }
      this.$http.post('v1/pembayaran/detilpembayaran', JSON.stringify(param))
        .then((res) => {
          this.noTransaksi =res.data.data.pembayaran.id; 
          this.metodePembayaran =res.data.data.pembayaran.metodePembayaran;
          this.bukti_code=res.data.data.pembayaran.bukti_code===""?"Bukti bayar tidak tersedia":
          res.data.data.pembayaran.bukti_code;
          this.jatuhTempo =res.data.data.jatuhTempo;
          this.status =res.data.data.pembayaran.status;
          this.statusString =res.data.data.pembayaran.statusString;
          this.tanggal =helper.dateFormat(res.data.data.pembayaran.tanggal);
          this.jumlah =helper.formatCurrency(res.data.data.pembayaran.nominal);
          this.detil=res.data.data.detil;

        })
        .catch((err) => {
          console.log(err)
        });
      },
      formatCurrency(x){
        return helper.formatCurrency(x);
      }, 
      async save() {
        const data = {
        "id":this.$route.params.id, 
        }
        this.$http.post('v1/pembayaran/verifikasipembayaran', data)
        .then((res) => {
          if(res){
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
          this.$router.push('/pembayaran');
          }
        })
        .catch((err) => { 
          if(err.response.status===400){
            var arrErr=JSON.parse(err.response.data.message);
          console.log(arrErr);
            var textErr=''; 
            for (const key in arrErr) {  
              textErr+=arrErr[key][0]+'\n'; 
              }  
          alert(textErr)
          }
        });
      },
      downloadInvoice() {
        this.$http.put('v1/pembayaran/download', JSON.stringify(
        {
          'id': this.$route.params.id
        }))
        .then((res) => {
          const link = document.createElement('a');
          link.href = res.data.data.url;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          link.remove();
          return false;
        })
        .catch((err) => {
          console.log(err)
        });
      },
      async download() {
        const data = {
        "id":this.$route.params.id, 
        }
        this.$http.post('v1/pembayaran/downloadbuktibayar', data, { responseType: 'blob' })
        .then((res) => {
          if(res){
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url; 
            console.log(this.$route.params.id+"."+res.headers['content-type']); 
            link.setAttribute('download', this.$route.params.id+"."+res.headers['content-type']); // Atur nama_file dan ekstensi yang sesuai
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          console.log(err)
        });
      },
    },
  }
</script>