<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">   
        <b class="header">
          Akun Pegawai
        </b>
              <div class="body mb-16">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" v-model="email">
                </div>
                  <div class="form-group">
                    <label>Cari Pegawai</label>
                    <div class="relative"> 
                      <input type="text" v-model="searchTerm" @keyup="getPegawaiData" @click="getPegawaiData">
                      <div
                        v-if="listPegawai.length"
                        class="dropdown-box">
                        <div
                          v-for="(item, i) in listPegawai"
                          :key="i"
                          class="item"
                          @click="pilihPegawai(item)">
                          {{ item.nama_lengkap }} ({{ item.nip }})
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="form-group">
                  <label>Nama</label>
                  <input :disabled="true" type="text" v-model="nama_lengkap">
                </div> 
                <div class="form-group">
                  <label>NIP</label>
                  <input :disabled="true" type="text" v-model="pegawai.nip">
                </div> 
                <div class="form-group">
                  <label>Password</label>
                  <input type="password" v-model="password">
                </div>  
              </div>    
            <div class="footer gap-24">
              <!-- <button class="btn default" @click="dialogVisible=true">Angsuran</button> -->
              <button class="btn primary" @click="save">Simpan</button>
            </div>
      </div>
    </div> 
  </LayoutMain>
</template>
  
<script>
  // import axios from 'axios'
  import LayoutMain from '@/components/layout/LayoutMain.vue' 
  import { ElMessage } from 'element-plus'
  export default {
    name: 'FormAkunPegawai',
    components: { LayoutMain },
    props: {
      isEdit: {
        Type: Boolean,
        require: false,
        default: false
      }
    },

    data() {
      return {
        activeName: 'Pembayaran',
        dialogVisible: false,  
        email:'',
        nama_lengkap:'',
        id_pegawai:'',
        password:'', 
        page: 1,
        pegawai: {nip:''},
        nextPage: null,
        searchTerm: '',
        isConfirm: false, 
        listPegawai: [],
      }
    },
    created() { 
      if(this.$route.params.id){
        this.getData();
      }
    },
    methods: { 
      getPegawaiData() {
        if (this.searchTerm.length < 2) {
          this.listPegawai = [];
        } else {
          const param = {
          "q":this.searchTerm,
          "jabatan":null,
          "page":1
          }
          this.$http.post('v1/pegawai/index', JSON.stringify(param))
            .then((res) => {
              this.listPegawai = res.data.data;
            })
            .catch((err) => {
              console.log(err)
            });
        }
      },
      pilihPegawai(val) {
        this.listPegawai = [];
        this.pegawai = val;
        this.id_pegawai = val.id;
        this.nama_lengkap  = val.nama_lengkap;
      },
      getData() { 
        const param = {
        "id":this.$route.params.id, 
      }
      this.$http.post('v1/account/detilwali', JSON.stringify(param))
        .then((res) => {
          console.log()
          this.email =res.data.wali.email;
          this.namawali =res.data.wali.nama;
          this.id_wali =res.data.wali.id;  
          this.siswaTerpilih=res.data.siswa;
        })
        .catch((err) => {
          console.log(err)
        });
      },
      openModal() {
        this.searchTerm = ''
        this.isConfirm = false
        this.dialogVisible = true
        this.getDataSiswa()
      },
      async save() {
        const data = {
        "id_sekolah":1,  
        "id_pegawai":this.pegawai.id,  
        "email":this.email, 
        "password":this.password, 
        }
        this.$http.put('v1/account/pegawai', data)
        .then((res) => {
          if(res){
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            }) 
          this.$router.push('/konfigurasi/akun-pegawai');
          }
        })
        .catch((err) => { 
          if(err.response.status===400){
            var arrErr=JSON.parse(err.response.data.message);
          console.log(arrErr);
            var textErr=''; 
            for (const key in arrErr) {  
              textErr+=arrErr[key][0]+'\n\n'; 
              }  

            ElMessage({
              showClose: true,
              message: textErr,
              type: 'error',
            }) 
          }
        });
      },
      pilihSiswa(item) {
        this.siswaTerpilih.push({
          id: item.id,
          nis: item.nis,
          nama_lengkap: item.nama_lengkap,
          kelas: item.kelas
        })
        this.dialogVisible = false
      },
      hapusSiswa(index) {
        this.siswaTerpilih.splice(index, 1)
      },
      getDataPrevious() {
        this.page -= 1;
        this.getDataSiswa();
      },
      getDataNext() {
        this.page += 1;
        this.getDataSiswa();
      },
      getDataSearch() {
        this.page = 1;
        this.getDataSiswa();
      },
      getDataSiswa() {
        const param = {
          "q":this.searchTerm,
          "status":null,
          "kelas":null,
          "page":this.page
        }
        this.$http.post('v1/siswa/index', JSON.stringify(param))
          .then((res) => {
            this.siswa = res.data.data
            this.nextPage = res.data.data.length == 15;
          })
          .catch((err) => {
            console.log(err)
          });
      },
    },
  }
</script>