<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="back">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          {{ header }}
        </b>
        <div class="body">
          <div v-if="!route_id && !route_tanggal" class="form-group">
            <label>Cari pegawai (Nama/NIP)</label>
            <div class="relative"> 
              <input type="text" v-model="searchTerm" @keyup="getPegawaiData" @click="getPegawaiData">
              <div
                v-if="listPegawai.length"
                class="dropdown-box">
                <div
                  v-for="(item, i) in listPegawai"
                  :key="i"
                  class="item"
                  @click="pilihPegawai(item)">
                  {{ item.nama_lengkap }} ({{ item.nip }})
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Nama lengkap</label>
            <input type="text" v-model="pegawai.nama_lengkap" disabled>
          </div>
          <div class="form-group">
            <label>Nomor induk pegawai</label>
            <input type="text" v-model="pegawai.nip" disabled>
          </div>
          <div class="form-group">
            <label>Jabatan</label>
            <input type="text" v-model="pegawai.jabatan" disabled>
          </div>
          <div class="form-group">
            <label>Tanggal<i class="required ml-6">*</i></label>
            <el-date-picker
              v-model="tanggal"
              type="date"
              placeholder="Pilih tanggal"
              class="w-50"/>
          </div>
          <div class="form-group">
            <label>Jam masuk<i class="required ml-6">*</i></label>
            <input
              type="time"
              v-model="jam_masuk"
              class="w-50"
              min="00:00"
              max="24:00"
              placeholder="00:00"
              @keydown="inputTime">
          </div>
          <div class="form-group">
            <label>Jam keluar<i class="required ml-6">*</i></label>
            <input
              type="time"
              v-model="jam_keluar"
              class="w-50"
              min="00:00"
              max="24:00"
              placeholder="00:00"
              @keydown="inputTime">
          </div>
        </div>
        <div class="footer flex gap-16">
          <button class="btn danger" @click="back">Batal</button>
          <button class="btn primary" @click="action">Simpan</button>
        </div>
      </div>
      <!-- <div class="aside">
        <button class="btn link"  @click="get">Import CSV</button>
        {{ 'Unduh template file' }}
        <router-link to="#">di sini</router-link>
      </div> -->
    </div>
  </LayoutMain>
</template>
  
<script>
  // import axios from 'axios'
  import 'dayjs/locale/id'
  import dayjs from 'dayjs'
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import helpers from '@/lib/helper'

  export default {
    name: 'FormPegawaiAbsen',
    components: { LayoutMain },

    data() {
      return {
        searchTerm: '',
        listPegawai: [],
        pegawai: {},
        tanggal: null,
        nip: '',
        id_pegawai: null,
        jam_masuk: null,
        jam_keluar: null,
        route_id: this.$route.params.id,
        route_tanggal: this.$route.params.tanggal,
        id_masuk: null,
        id_keluar: null
      }
    },
    computed: {
      header() {
        return this.route_id ? 'Edit absen' : 'Tambah absen'
      },
    },
    mounted() {
      if(this.route_id && this.route_tanggal) {
        const data = {
          "id":this.route_id,
          "tanggal": this.route_tanggal
        }
        this.$http.post('v1/pegawai/detailpresensi', JSON.stringify(data))
        .then((res) => {
          this.id_pegawai = res.data.data[0].pegawai_id
          this.tanggal = res.data.data[0].tanggal
          this.id_masuk = res.data.data[0].id_presensi
          this.jam_masuk = res.data.data[0].jam
          this.id_keluar = res.data.data[1].id_presensi
          this.jam_keluar = res.data.data[1].jam
          this.pegawai = {
            nip: res.data.data[0].nip,
            nama_lengkap: res.data.data[0].nama_lengkap,
            jabatan: res.data.data[0].jabatan,
          }
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
    methods: {
      back() {
        return this.$router.push({ path: '/kepegawaian', query: { tab: 'absen' } })
      },
      inputTime($event) {
        helpers.formatTime($event)
      },
      getPegawaiData() {
        if (this.searchTerm.length < 2) {
          this.listPegawai = [];
        } else {
          const param = {
          "q":this.searchTerm,
          "jabatan":null,
          "page":1
          }
          this.$http.post('v1/pegawai/index', JSON.stringify(param))
            .then((res) => {
              this.listPegawai = res.data.data
            })
            .catch((err) => {
              console.log(err)
            });
        }
      },
      pilihPegawai(val) {
        this.listPegawai = [];
        this.pegawai = val;
        this.id_pegawai = val.id;
      },
      action() {
        if(this.route_id && this.route_tanggal) {
          this.update()
        } else {
          this.save()
        }
      },
      async save() {
        const data = {
            "pegawai_id": this.id_pegawai,
            "tanggal": dayjs(this.tanggal).locale('id').format('YYYY-MM-DD'),
            "jam_masuk": this.jam_masuk,
            "jam_keluar": this.jam_keluar
        }
        this.$http.put('v1/pegawai/presensi', JSON.stringify(data))
          .then(() => {
            this.back()
          })
        .catch((err) => {
          console.log(err)
        });
      },
      async update() {
        const data = {
            "pegawai_id": this.id_pegawai,
            "id_masuk": this.id_masuk,
            "id_keluar": this.id_keluar,
            "tanggal": dayjs(this.tanggal).locale('id').format('YYYY-MM-DD'),
            "jam_masuk": this.jam_masuk,
            "jam_keluar": this.jam_keluar
        }
        this.$http.patch('v1/pegawai/presensi', JSON.stringify(data))
          .then(() => {
            this.back()
          })
        .catch((err) => {
          console.log(err)
        });
      },
    },
  }
</script>