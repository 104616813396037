<template>
  <LayoutMain>
    <view-sekolah/>
  </LayoutMain>
  </template>
  
<script>
import LayoutMain from '@/components/layout/LayoutMain.vue';
import ViewSekolah from '@/components/pages/ViewSekolah'

export default {
  name: "KonfigurasiSekolahPage",
  components: { LayoutMain, ViewSekolah },
  data() {
    return {
      tabActive: 1,
    }
  },
}
</script>