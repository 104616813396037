<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="back">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          Input gaji pegawai
        </b>
        <div class="body">
          <div class="form-group">
            <label>Bulan</label>
            <el-select v-model="bulan" placeholder="Bulan" class="w-50">
              <el-option
                v-for="item in bulanArray"
                :key="item.id"
                :label="item.label"
                :value="item.id"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Cari pegawai</label>
            <div class="relative"> 
              <input
                type="text"
                v-model="searchTerm"
                @keyup="getPegawaiData"
                @click="getPegawaiData"
                placeholder="Nip/Nama pagawai..">
              <div
                v-if="listPegawai.length"
                class="dropdown-box">
                <div
                  v-for="(item, i) in listPegawai"
                  :key="i"
                  class="item"
                  @click="pilihPegawai(item)">
                  {{ item.nama_lengkap }} ({{ item.nip }})
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Nama lengkap</label>
            <input type="text" v-model="nama_lengkap" disabled>
          </div>
          <div class="form-group">
            <label>Nomor induk pegawai</label>
            <input type="text" v-model="nip" disabled>
          </div>
          <div class="form-group">
            <label>Jabatan</label>
            <input type="text" v-model="jabatan" disabled>
          </div>
          <div class="form-group">
            <label>Gaji pokok</label>
            <input type="text" v-model="gaji_pokok" disabled>
          </div>
          <label class="title">Tunjangan</label>
          <div class="form-group">
            <label>Tunjangan jabatan</label>
            <input type="text" v-model="tj_jabatan" disabled>
          </div>
          <div class="form-group">
            <label>Jumlah kehadiran</label>
            <input
              type="text"
              v-model="jumlah_hadir"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="2"
              class="w-50">
          </div>
          <div class="form-group">
            <label>Tunjangan kehadiran</label>
            <input
              type="text"
              v-model="tj_hadir"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="8">
          </div>
          <div class="form-group">
            <label>Tunjangan makan</label>
            <input
              type="text"
              v-model="tj_makan"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="8">
          </div>
          <div class="form-group">
            <label>Tunjangan transportasi</label>
            <input
              type="text"
              v-model="tj_transport"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="8">
          </div>
          <div class="form-group">
            <label>Tunjangan hari raya</label>
            <input
              type="text"
              v-model="tj_hari_raya"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="8">
          </div>
          <div class="form-group">
            <label>Total tunjangan</label>
            <input type="text" v-model="total_tunjangan" disabled>
          </div>
          <label class="title">Potongan</label>
          <div class="form-group">
            <label>Pph21</label>
            <input
              type="text"
              v-model="ptg_pph21"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="8">
          </div>
          <div class="form-group">
            <label>Bpjs</label>
            <input
              type="text"
              v-model="ptg_bpjs"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="8">
          </div>
          <div class="form-group">
            <label>Lain-lain</label>
            <input
              type="text"
              v-model="ptg_lainya"
              @keydown="inputNumber"
              @keyup="calculate"
              maxlength="8">
          </div>
          <div class="form-group">
            <label>Total potongan</label>
            <input type="text" v-model="total_potongan" disabled>
          </div>
          <label class="title">Total gaji</label>
          <div class="form-group">
            <label>Total gaji</label>
            <input type="text" v-model="total_gaji" disabled>
          </div>
          
        </div>
        <div class="footer flex gap-16">
          <button class="btn danger" @click="back">Batal</button>
          <button class="btn primary" @click="save">Simpan</button>
        </div>
      </div>
      <div class="aside">
        <button class="btn link"  @click="get">Import CSV</button>
        {{ 'Unduh template file' }}
        <router-link to="#">di sini</router-link>
      </div>
    </div>
  </LayoutMain>
</template>
  
<script>
  import { bulan as getBulan } from '@/constant'
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import helpers from '@/lib/helper'
  import { ElMessage } from 'element-plus'

  export default {
    name: 'FormPegawaiGaji',
    components: { LayoutMain },

    data() {
      return {
        bulanArray: getBulan,
        searchTerm: '',
        listPegawai: [],
        jumlah_hadir: null,
        nama_lengkap: '',
        nip: '',
        jabatan: '',
        pegawai_id: null,	
        tahun: null,	
        bulan: null,	
        gaji_pokok: 0,	
        tj_hadir: 0,	
        tj_jabatan: 0,	
        tj_transport: 0,	
        tj_makan: 0,	
        tj_hari_raya: 0,	
        total_tunjangan: 0,	
        ptg_pph21: 0,		
        ptg_bpjs: 0,		
        ptg_lainya: 0,		
        total_potongan: 0,		
        total_gaji: 0
      }
    },
    methods: {
      back() {
        return this.$router.push({ path: '/kepegawaian', query: { tab: 'gaji' } })
      },
      inputNumber($event) {
        helpers.numberOnly($event)
      },
      calculate() {
        const jabatan = this.tj_jabatan ? this.tj_jabatan : 0
        const hadir = this.tj_hadir ? this.tj_hadir : 0;
        const makan = this.tj_makan ? this.tj_makan : 0;
        const transport = this.tj_transport ? this.tj_transport : 0;
        const lebaran = this.tj_hari_raya ? this.tj_hari_raya : 0;
        const pph21 = this.ptg_pph21 ? this.ptg_pph21 : 0;
        const bpjs = this.ptg_bpjs ? this.ptg_bpjs : 0;
        const lainya = this.ptg_lainya ? this.ptg_lainya : 0;
        this.total_tunjangan = Number(jabatan)+Number(hadir)+Number(makan)+Number(transport)+Number(lebaran)
        this.total_potongan = Number(bpjs)+Number(pph21)+Number(lainya)
        this.total_gaji = Number((this.gaji_pokok + this.total_tunjangan) - this.total_potongan)
      },
      getPegawaiData() {
        if (this.searchTerm.length < 2) {
          this.listPegawai = [];
        } else {
          const param = {
          "q":this.searchTerm,
          "jabatan":null,
          "page":1
          }
          this.$http.post('v1/pegawai/index', JSON.stringify(param))
            .then((res) => {
              this.listPegawai = res.data.data
            });
        }
      },
      pilihPegawai(val) {
        this.listPegawai = [];
        this.pegawai_id = val.id;
        this.nama_lengkap = val.nama_lengkap
        this.nip = val.nip
        this.jabatan = val.jabatan
        this.gaji_pokok = val.gaji_pokok
        this.tj_jabatan = val.tunjangan_jabatan
        this.ptg_pph21 = val.pph21
        this.ptg_bpjs = val.bpjs
        this.ptg_lainya = val.lainnya
        this.$http.post('v1/pegawai/jumlahpresensi', {"id":val.id})
          .then((res) => {
            this.jumlah_hadir = res.data.data
          });
        this.total_tunjangan = Number(val.tunjangan_jabatan)
        this.total_potongan = Number(val.pph21+val.bpjs+val.lainnya)
        this.total_gaji = Number((this.gaji_pokok + this.total_tunjangan) - this.total_potongan)
      },
      async save() {
        const data = {
            "bulan": this.bulan,
            "pegawai_id": this.pegawai_id,
            "jumlah_hadir": this.jumlah_hadir,
            "gaji_pokok": this.gaji_pokok,
            "tj_jabatan": this.tj_jabatan,
            "tj_hadir": this.tj_hadir,
            "tj_transport": this.tj_transport,
            "tj_makan": this.tj_makan,
            "tj_hari_raya": this.tj_hari_raya,
            "ptg_bpjs": this.ptg_bpjs,
            "ptg_lainya": this.ptg_lainya,
            "ptg_pph21": this.ptg_pph21,
            "total_tunjangan": this.total_tunjangan,
            "total_potongan": this.total_potongan,
            "total_gaji": this.total_gaji
        }
        this.$http.put('v1/pegawai/gaji', JSON.stringify(data))
          .then(() => {
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
            this.back()
          })
        .catch((err) => {
            ElMessage({
              showClose: true,
              message: 'Gagal, '+err.message,
              type: 'error',
            })
        });
      },
    },
  }
</script>