<template>
  <LayoutMain>
    <div class="dashboard mb-16">
      <div class="grid">
        <div class="flex flex-column mr-8">
          <label class="title mb-8">Total siswa</label>
          <doughnut-chart
            v-if="siswaTotal.length"
            :data="siswaTotal"
            :total="totalSiswa"
            class="mr-8"/>
          <div v-else class="box center"> Tidak ada data </div>
        </div>
      </div>
      <div class="widget flex end">
        <button
          v-if="canConfig"
          class="btn primary"
          :disabled="!canConfig"
          @click="dialogVisible = true">
          Konfigurasi tahun ajaran
        </button>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Tagihan siswa" name="tagihan" class="flex flex-column gap-16">
        <table-tagihan-siswa :can-add-tagihan="canAddTagihan"/>
      </el-tab-pane>
      <el-tab-pane label="Tahun ajaran baru" name="tahun-ajaran" class="flex flex-column gap-16">
        <table-tahun-ajaran :can-add-tagihan="canAddTagihan"/>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      v-model="dialogVisible"
      title="Konfigurasi tahun ajaran"
      width="90vh">
      <span class="form">
        <p>
          Dengan pengaturan ini, Anda menyetujui penonaktifan tahun ajaran lama dan diganti menjadi 
          tahun ajaran baru. Data tahun ajaran lama akan diarsipkan. Anda juga dapat mengatur 
          pembayaran terkait dengan tahun ajaran baru. Harap pertimbangkan keputusan ini dengan baik 
          sebelum melanjutkan.
        </p>
        <span class="flex center gap-16 w-50">
          <input type="text" v-model="old_tahun" disabled>
          <label>Menjadi</label>
          <input type="text" :value="`${tahun_awal}/${tahun_akhir}`" disabled>
        </span>
      </span>
      <template #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn danger" @click="dialogVisible = false">Batal</button>
          <button class="btn primary" @click="simpanKonfigurasi"> Simpan</button>
        </span>
      </template>
    </el-dialog>
  </LayoutMain>
</template>
    
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue';
  import DoughnutChart from '@/components/components/ChartDoughnut.vue'
  import TableTagihanSiswa from '@/components/components/TableTagihanSiswa.vue'
  import TableTahunAjaran from '@/components/components/TableTahunAjaran.vue'
  import { ElMessage } from 'element-plus'
  
  export default {
    name: "KonfigurasiPage",
    components: { LayoutMain, DoughnutChart, TableTagihanSiswa, TableTahunAjaran },
    data() {
      return {
        dialogVisible: false,
        activeName: 'tagihan',
        today: new Date(),
        old_tahun: null,
        tahun_awal: '',
        tahun_akhir: '',
        canConfig: false,
        canAddTagihan: false,
        siswaTotal: [],
        totalSiswa: 0,
      }
    },
    async created() {
      this.$http.get('v1/siswa/chart')
          .then((res) => {
            this.siswaTotal = res.data.total.map((data) => ({
              label: data.label,
              color: data.label === 'Aktif' ? '#0197CF' : '#FFC01E',
              value: data.value,
            }));
            this.siswaAktif = res.data.aktif.map((data) => ({
              label: data.label,
              color: data.label === 'Laki-laki' ? '#FFAC2E' : '#15FF64',
              value: data.value,
            }));

            res.data.aktif.forEach(data => {
              this.totalSiswa = Number(this.totalSiswa) + Number(data.value)
          });
            res.data.total.forEach(data => {
              this.totalAktif = Number(this.totalAktif) + Number(data.value)
          });
          })
      .catch((err) => {
        console.log(err)
      });
    },
    mounted() {
      this.tahun_awal  = this.today.getFullYear()
      this.tahun_akhir = Number(this.today.getFullYear()+1);
      this.getTahunAjaran();
    },
    methods: {
      getTahunAjaran() {
      this.$http.get('v1/configtahunajaran/status')
        .then((res) => {
          this.canConfig = res.data.data.can_config;
          this.canAddTagihan = res.data.data.can_add_tagihan;
          if (res.data.data.deskripsi) {
            this.old_tahun = res.data.data.deskripsi;
          } else {
            this.old_tahun = this.today.getFullYear()-1+'/'+this.today.getFullYear();
          }
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.log(err)
        });
      },
      simpanKonfigurasi() {
        const data = {
          "tahun_awal": this.tahun_awal,
          "tahun_akhir": this.tahun_akhir
        }
        this.$http.put('v1/configtahunajaran/index', JSON.stringify(data))
        .then(() => {
          this.dialogVisible = false
          ElMessage({
            showClose: true,
            message: 'Berhasil.',
            type: 'success',
          })
          this.getTahunAjaran();
          this.$store.dispatch('reload');
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
  }
</script>