<template>
    <div class="flex gap-16 m-tb-8 w-100">
        <div class="input-suffix">
            <input type="text" v-model="searchTerm" placeholder="Cari siswa / NIS" @keyup="getDataSearch"/>
            <Search class="fa"/>
        </div>
        <el-select v-model="selectKategori" placeholder="Kategori">
            <el-option
                v-for="item in kategoriPembayaran"
                :key="item.id_jenis_pembayaran"
                :label="item.keterangan"
                :value="item.id_jenis_pembayaran"
                @click="getDataSearch"/>
        </el-select>
        <el-select v-model="selectBulan" placeholder="Bulan">
            <el-option
                v-for="item in bulan"
                :key="item.id"
                :label="item.label"
                :value="item.id"
                @click="getDataSearch"/>
        </el-select> 
        <button class="btn reset" @click="reset">Atur ulang</button>
    </div>
    <table>
        <thead>
            <tr>
                <th v-for="header in header" :key="header">{{ header }}</th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="pembayaran in pembayaran"
                :key="pembayaran.nip"
                class="text-link"
                @click="gotoDetile(pembayaran.id)">
                <td>{{ pembayaran.id }}</td>
                <td class="single-line">{{ pembayaran.namaSiswa }}</td>
                <td>{{ pembayaran.nis }}</td>
                <td>{{ pembayaran.kategori }}</td>
                <td>{{ pembayaran.bulanString }}</td>
                <td>{{ dateFormat(pembayaran.jatuhTempo) }}</td>
                <td>{{ currencyFormat(pembayaran.nominal) }}</td>
                <td>{{ pembayaran.StatusString }}</td>
            </tr>
      <tr>
        <td colspan="6" class="text-center">
          <span
            v-if="pembayaran.length"
            class="pagination">
            <button
              v-if="page > 1"
              @click="getDataPrevious"
              class="btn link"> 
                <ArrowLeft class="fa"/>
                Sebelumnya
              </button>
              <button
                v-if="nextPage"
                @click="getDataNext"
               class="btn link">
                Selanjutnya
                <ArrowRight class="fa"/> 
              </button>
          </span>
          <span v-else>
            Tidak ada data
          </span>
        </td>
      </tr>
        </tbody>
    </table>
</template>
    
<script>
  //   import axios
import { bulan } from '@/constant'
import helpers from '@/lib/helper'

export default {
    name: 'TablePembayaran',
    props: {

    },

    components: {  },
    data() {
    return {
        selectKategori: null,
        selectBulan: null, 
        searchTerm: '',
        bulan: bulan,
        kategoriPembayaran: [], 
        jenisPegawai: [
        {
            value: 1,
            label: 'Guru'
        },
        {
            value: 2,
            label: 'Pegawai'
        },
        {
            value: 3,
            label: 'Staf'
        },
        {
            value: 4,
            label: 'Maggang'
        }
        ],
        header: ['No.', 'Nama lengkap', 'NIS', 'Kategori', 'Bulan', 'Jatuh Tempo', 'Jumlah', 'Status'],
        pembayaran: [],
        nextPage: false,
        page: 1,
        searchStatus: null,
    }
    },

    mounted() {
    this.getData();
    this.getDataKategori();
    },

    methods: {
      dateFormat(date) {
        return helpers.dateFormat(date)
      },
      getDataPrevious() {
        this.page -= 1;
        this.getData();
      },
      getDataNext() {
        this.page += 1;
        this.getData();
      },
      getDataSearch() {
        this.page = 1;
        this.getData();
      },
      gotoDetile(param) {
        this.$router.push({ name: 'FormPembayaran', params: { id:param } })
      },
      getData() {
        const param = {
        "q":this.searchTerm,
        "kategori":this.selectKategori,
        "bulan":this.selectBulan,
        "page":this.page
      }
      this.$http.post('v1/tagihansiswa/allsiswa', JSON.stringify(param))
        .then((res) => {
          this.pembayaran = res.data.data
          this.nextPage = res.data.data.length == 15;
        })
        .catch((err) => {
          console.log(err)
        });
      },
      getDataKategori() {
        const param = {
        "q":this.searchTerm
      }
      this.$http.post('v1/jenispembayaran/index', JSON.stringify(param))
        .then((res) => {
          this.kategoriPembayaran = res.data.data 
        })
        .catch((err) => {
          console.log(err)
        });
      },
      reset() {
        this.selectKategori = null;
        this.selectBulan = null;
        this.selectStatus = null;
        this.searchTerm = '';
      },
      currencyFormat(price) {
        return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR"}).format(price)
      }
    },
}
</script>