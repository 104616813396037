<template>
  <LayoutMain>
    <button class="btn link distance" @click="$router.go(-1)">
      <Back class="fa" />
      {{ "Kembali" }}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header"> Akun admin sekolah </b>
        <div class="body mb-16">
          <label class="title">Biodata admin</label>
          <div class="form-group">
            <label>Photo profile</label>
            <div class="flex">
              <div class="avatar">
                <Camera v-if="!img" class="fa" />
                <img v-else :src="img" />
              </div>
              <a class="link" @click="$refs.avatar.click()">Ubah foto</a>
              <input
                ref="avatar"
                type="file"
                class="none"
                accept="image/png,image/jpg,image/jpeg"
                @change="onFileChange"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Nama</label>
            <input type="text" v-model="nama" />
          </div>
          <div class="form-group">
            <label>Email</label>
            <input type="text" v-model="email" />
          </div>
          <div class="form-group">
            <label>No. Handphone</label>
            <input type="text" v-model="no_hp" />
          </div>
          <div class="form-group">
            <label>Alamat lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="alamat" />
          </div>
          <div class="form-group">
            <label />
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input type="text" v-model="kelurahan" />
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input type="text" v-model="kecamatan" />
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input type="text" v-model="kota" />
              </div>
              <div class="form-group">
                <label>provinsi</label>
                <input type="text" v-model="provinsi" />
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input type="text" v-model="kodepos" />
              </div>
            </div>
          </div>
        </div>
        <div class="footer gap-24">
          <!-- <button class="btn default" @click="dialogVisible=true">Angsuran</button> -->
          <button class="btn primary" @click="save">Simpan</button>
        </div>
      </div>
    </div>
  </LayoutMain>
</template>

<script>
// import axios from 'axios'
import LayoutMain from "@/components/layout/LayoutMain.vue";
export default {
  name: "FormAkunAdmin",
  components: { LayoutMain },
  props: {
    isEdit: {
      Type: Boolean,
      require: false,
      default: false,
    },
  },

  data() {
    return {
      activeName: "Pembayaran",
      dialogVisible: false,
      no_hp: "",
      alamat: "",
      kelurahan: "",
      kecamatan: "",
      kota: "",
      provinsi: "",
      kodepos: "",
      email: "",
      nama: "",
      password: "",
      page: 1,
      nextPage: null,
      searchTerm: "",
      isConfirm: false,
      sekolah_id: null,
      avatar: "",
      img: null,
      route: this.$route.params.id,
    };
  },
    mounted() {
      if(this.route) {
        this.$http.post("v1/account/detiladmin", JSON.stringify({"id":this.route}))
        .then((res) => {
          console.log();
          this.no_hp = res.data.admin.no_hp
          this.alamat = res.data.admin.alamat
          this.kelurahan = res.data.admin.kelurahan
          this.kecamatan = res.data.admin.kecamatan
          this.kota = res.data.admin.kota
          this.provinsi = res.data.admin.provinsi
          this.kodepos = res.data.admin.kodepos
          this.email = res.data.admin.email
          this.nama = res.data.admin.nama
          this.img =  res.data.admin.avatar
          this.avatar =  res.data.admin.photo
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
  methods: {
    openModal() {
      this.searchTerm = "";
      this.isConfirm = false;
      this.dialogVisible = true;
      this.getDataSiswa();
    },
    async save() {
      let data = {
        email: this.email,
        nama: this.nama,
        password: this.password,
        no_hp: this.no_hp,
        alamat: this.alamat,
        kelurahan: this.kelurahan,
        kecamatan: this.kecamatan,
        kota: this.kota,
        provinsi: this.provinsi,
        kodepos: this.kodepos,
        photo: this.avatar,
      };

      if(this.route) {
        data=Object.assign(data,{id:this.route});
      this.$http
        .patch("v1/account/admin",  data)
        .then((res) => {
          if (res) {
            this.$router.push("/konfigurasi/akun-admin");
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            var arrErr = JSON.parse(err.response.data.message);
            var textErr = "";
            arrErr.forEach((element) => {
              textErr = element[0];
            });
          }
          console.log(textErr);
          alert(textErr);
        });
      }else{
      this.$http
        .put("v1/account/admin", data)
        .then((res) => {
          if (res) {
            this.$router.push("/konfigurasi/akun-admin");
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            var arrErr = JSON.parse(err.response.data.message);
            var textErr = "";
            arrErr.forEach((element) => {
              textErr = element[0];
            });
          }
          console.log(textErr);
          alert(textErr);
        });
      }
    },
    onFileChange(e) {
      this.avatar = e.target.files[0];
      this.img = URL.createObjectURL(this.avatar);
      this.$http
        .post(
          "v1/attachment/upload",
          { UploadFile: this.avatar },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.avatar = res.data ? res.data.data : null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
