<template>
  <div class="flex gap-16 m-tb-8 w-100">
    <div class="input-suffix">
      <input v-model="searchTerm" placeholder="Cari.." @keyup="getDataSearch">
      <Search class="fa" />
    </div>
    <button class="btn reset" @click="reset">Atur ulang</button>
    <button class="btn link right p-16" @click="dialogVisible = true">Tambah data</button>
  </div>
  <table>
    <thead>
      <tr>
        <th v-for="header in header" :key="header">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="item.id_kelas">
        <td>{{ index + 1}}</td>
        <td>{{ item.keterangan }}</td>
        <td>{{ item.level }}</td>
        <td width="1%"><b class="flex center" @click="getEdit(item)">...</b></td>
      </tr>
    </tbody>
  </table>

  <el-dialog v-model="dialogVisible" :title="modalKelasTitle" width="50vh">
    <span class="form">
      <!-- <span v-if="isEdit" class="form-group">
        <label>Id</label>
        <input type="text" v-model="id" disabled>
      </span> -->
      <span class="form-group">
        <label>Deskripsi</label>
        <input type="text" v-model="keterangan">
      </span>
      <div class="form-group">
        <label>Level<i class="required ml-6">*</i></label>
        <el-select v-model="level">
          <el-option v-for="item in arrLevel" :key="item" :value="item" :label="item" />
        </el-select>
      </div>
    </span>
    <template #footer>
      <span class="dialog-footer flex gap-24 end">
        <button class="btn danger" @click="reset">Batal</button>
        <button class="btn primary" @click="save"> Simpan</button>
      </span>
    </template>
  </el-dialog>
</template>
  
<script>
export default {
  name: 'KonfigKelas',
  props: {

  },

  components: {},
  data() {
    return {
      isEdit: false,
      dialogVisible: false,
      searchTerm: '',
      page: 1,
      header: ['No.', 'Deskripsi', 'level', ''],
      data: [],
      id: 1,
      keterangan: '',
      level: 0,
      arrLevel: [
        1,
        2,
        3,
        4,
        5,
        6
      ]
    }
  },
  computed: {
    modalKelasTitle() {
      let title = 'Tambah kelas';
      if (this.isEdit) {
        title = 'Edit kelas'
      }
      return title;
    },
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      const param = {
        "q": this.searchTerm,
        "page": this.page
      }
      this.$http.post('v1/kelas/index', JSON.stringify(param))
        .then((res) => {
          this.data = res.data.data
          this.nextPage = res.data.data.length == 15;
        })
        .catch((err) => {
          console.log(err)
        });
    },
    save() {
      const param = {
        "keterangan": this.keterangan,
        "level": this.level
      }
      if (this.isEdit) {
        this.$http.patch(`v1/kelas/index/${this.id}`, JSON.stringify(param))
          .then(() => {
            this.reset();
          });
      } else {
        this.$http.put('v1/kelas/index', JSON.stringify(param))
          .then(() => {
            this.reset();
          });
      }
    },
    getEdit(val) {
      this.dialogVisible = true;
      this.isEdit = true;
      this.id = val.id_kelas;
      this.keterangan = val.keterangan;
      this.level = val.level;
    },
    getDataPrevious() {
      this.page -= 1;
      this.getData();
    },
    getDataNext() {
      this.page += 1;
      this.getData();
    },
    getDataSearch() {
      this.page = 1;
      this.getData();
    },
    reset() {
      this.dialogVisible = false;
      this.isEdit = false;
      this.searchTerm = '';
      this.id = null;
      this.keterangan = '';
      this.getData();
    },
  }
}
</script>