<template>
  <div class="OsmMap">
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @click="onMapClick"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-marker :lat-lng="withPopup" />
    </l-map>
  </div>
</template>

<script>
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'OsmMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    place: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zoom: 13,
      center: latLng(-5.4460713, 105.2643742),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://siap.com">Siap</a> contributors',
      withPopup: latLng(-5.4460713, 105.2643742),
      withTooltip: latLng(-5.4460713, 105.2643742),
      currentZoom: 11.5,
      currentCenter: latLng(-5.4460713, 105.2643742),
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: false,
    }
  },
  mounted() {
    this.center = latLng(this.place.lat, this.place.long);
    this.withPopup = latLng(this.place.lat, this.place.long);
    this.withTooltip = latLng(this.place.lat, this.place.long);
    this.currentCenter = latLng(this.place.lat, this.place.long);
    this.showMap = true;
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    onMapClick(e) {
      const { lat, lng } = e.latlng;
      this.withPopup = [lat, lng];
      this.center = [lat, lng];
    },
  },
};
</script>

<style rel="stylesheet/stylus"
       lang="stylus"
       scoped>
  .OsmMap
    height 260px
    display flex
    align-items center
    justify-content center
    position relative
    z-index: 0
    margin:5px
    #map
      border-radius 8px

    &__Background
      filter blur(10px)
      position absolute
      left 0
      right 0
      top 0
      bottom 0

    &__Loading
      text-align center
      z-index 1
      font-size .85rem
</style>
