<template>
  <div>
    <div class="box gap-32 p-32">
      <div class="flex between">
        <div class="card profile-sekolah">
          <div class="avatar">
            <!-- <img :src="`${sekolah.logo}`"/> -->
            <img class="img" :src="sekolah.avatar" />
          </div>
          <div class="info">
            <span class="title single-line">{{ sekolah.nama }}</span>
            <span class="desc">{{ sekolah.alamat }}</span>
          </div>
        </div>
        <router-link
          :to="{ name: 'FormSekolah', params: { id: sekolah.id } }"
          class="btn link btn p-16"
          >Edit profile</router-link
        >
      </div>
      <aside>
        <el-tabs v-model="activeName">
          <el-tab-pane
            label="Informasi sekolah"
            name="info"
            class="flex flex-column gap-16"
          >
            <div class="form-group">
              <label>Nama sekolah</label>
              <input v-model="sekolah.nama" disabled />
            </div>
            <div class="form-group">
              <label>NPSN</label>
              <input v-model="sekolah.nspn" disabled />
            </div>
            <div class="form-group">
              <label>Jenis sekolah</label>
              <input :value="jenisSekolahLabel" disabled />
            </div>
            <div class="form-group">
              <label>Jenjang pendidikan</label>
              <input :value="jenjangPendidikanLabel" disabled />
            </div>
            <div class="form-group">
              <label>Tagline</label>
              <input v-model="sekolah.tagline" disabled />
            </div>
            <div class="form-group">
              <label>E-mail</label>
              <input v-model="sekolah.email" disabled />
            </div>
          <div class="form-group">
              <label>No. handphone</label>
              <input v-model="sekolah.no_telp" disabled />
            </div>
            <div class="form-group">
              <label>Radius Lokasi</label>
              <input v-model="sekolah.radius" disabled >
              <span class="suffix">Meter</span>
            </div>
            <div class="form-group">
              <label>Alamat lengkap</label>
              <input v-model="sekolah.alamat" disabled />
            </div>
            <div class="form-group">
              <label />
              <div class="flex flex-column gap-16">
                <div class="form-group">
                  <label>Kelurahan/desa</label>
                  <input v-model="sekolah.desa" disabled />
                </div>
                <div class="form-group">
                  <label>Kecamatan</label>
                  <input v-model="sekolah.kecamatan" disabled />
                </div>
                <div class="form-group">
                  <label>Kabupaten/kota</label>
                  <input v-model="sekolah.kabupaten" disabled />
                </div>
                <div class="form-group">
                  <label>Provinsi</label>
                  <input v-model="sekolah.provinsi" disabled />
                </div>
                <div class="form-group">
                  <label>Kode pos</label>
                  <input v-model="sekolah.kode_pos" disabled />
                </div>
                <div class="form-group">
                  <label> </label>
                  <div class="flex column">
                    <osm-map
                      v-if="place.lat"
                      :place="place"/>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Rekening sekolah" name="bank">
            <div
              v-for="(bank, index) in sekolah.bank"
              :key="bank.id"
              class="flex flex-column gap-16"
            >
              <label class="fw-500">Rekening {{ index + 1 }}</label>
              <div class="form-group">
                <label>Nama bank</label>
                <input :value="bank.bankString" disabled />
              </div>
              <div class="form-group">
                <label>Nomor rekening</label>
                <input :value="bank.no_rek" disabled />
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </aside>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { jenisSekolah, jenjangPendidikan } from "@/constant";
  import OsmMap from '@/components/components/OsmMap'

export default {
  name: "ViewSekolah",
    components: { OsmMap },

  data() {
    return {
      activeName: "info",
      jenisSekolahArray: jenisSekolah,
      jenjangPendidikanArray: jenjangPendidikan,
      place: {},
    };
  },
  computed: {
    ...mapGetters({
      sekolah: "sekolah",
    }),
    jenisSekolahLabel() {
      return this.jenisSekolahArray.filter(
        (item) => item.id === this.sekolah.jenis_sekolah
      )[0].label;
    },
    jenjangPendidikanLabel() {
      return this.jenjangPendidikanArray.filter(
        (item) => item.id === this.sekolah.jenjang_pendidikan
      )[0].label;
    },
  },
    mounted() {
      this.place = {
        place_id: null,
        name: this.sekolah.alamat,
        lat: this.sekolah.lat,
        long: this.sekolah.long,
      }
    },
};
</script>
