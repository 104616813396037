<template>
  <div v-if="id === 0" class="flex gap-16 m-tb-8">
    <div class="input-suffix">
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Cari pegawai / NIP"
        @keyup="getData">
      <Search class="fa"/>
    </div>
    <button class="btn reset" @click="reset">Atur ulang</button>
    <router-link
      :to="{ name: 'FormPegawaiAbsen' }"
      class="btn link right p-16">
      Absen manual
    </router-link>
  </div>
  <table>
    <thead>
      <tr>
        <th v-for="header in headerTablePegawai" :key="header">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="pegawai in pegawaiData"
        :key="pegawai.nip">
        <td><router-link
          :to="{ name: 'ViewPegawai', params: {id:pegawai.pegawai_id} }"
          class="text-link">
          {{ pegawai.nama_lengkap }}
        </router-link></td>
        <td>{{ pegawai.nip }}</td>
        <td>{{ pegawai.tanggal }}</td>
        <td>{{ pegawai.sumberString }}</td>
        <td>
          <router-link
            v-if="pegawai.sumber === 3"
            :to="{ name: 'FormPegawaiAbsenEdit',
              params: {
                id:pegawai.pegawai_id,
                tanggal:pegawai.tanggal
              }
            }" 
            class="text-link">
            Edit
          </router-link>
        </td>
      </tr>
    <tr>
      <td colspan="6" class="text-center">
        <span
          v-if="pegawaiData.length"
          class="pagination">
          <button
            v-if="page > 1"
            @click="getDataPrevious"
            class="btn link"> 
              <ArrowLeft class="fa"/>
              Sebelumnya
            </button>
            <button
              v-if="nextPage"
              @click="getDataNext"
              class="btn link">
              Selanjutnya
              <ArrowRight class="fa"/> 
            </button>
        </span>
        <span v-else>
          Tidak ada data
        </span>
      </td>
    </tr>
    </tbody>
  </table>
</template>
    
<script>
  export default {
    name: 'TablePegawaiAbsensi',
    props: {
      id: {
        type: Number,
        required: false,
        default: 0,
      }
    },

    components: {  },
    data() {
      return {
        searchTerm: '',
        headerTablePegawai: ['Nama lengkap', 'NIP', 'Tanggal', 'Sumber', ''],
        pegawaiData: [],
        nextPage: false,
        page: 1,
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      reset() {
        this.searchTerm = '';
        this.getData();
      },
      getData() {
        const data =
          this.id !== 0 ?
          {
            "id_pegawai": this.id,
            "page": this.page
          } : {
            "q": this.searchTerm,
            "page": this.page
          }
        this.$http.post('v1/pegawai/riwayatpresensi', JSON.stringify(data))
          .then((res) => {
            this.pegawaiData = res.data.data;
            this.nextPage = res.data.data.length == 15;
          })
        .catch((err) => {
          console.log(err)
        });
      },
      getDataPrevious() {
        this.page -= 1;
        this.getData();
      },
      getDataNext() {
        this.page += 1;
        this.getData();
      },
      getDataSearch() {
        this.page = 1;
        this.getData();
      },
    },
  }
</script>