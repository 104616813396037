<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          {{ header }}
        </b>
        <div class="body">
          <label class="title">Biodata siswa</label>
          <div class="form-group">
            <label>Photo profile</label>
            <div class="flex">
              <div class="avatar">
                <Camera
                  v-if="!img"
                  class="fa" />
                <img
                  v-else
                  :src="img">
              </div>
              <a class="link" @click="$refs.avatar.click()">Ubah foto</a>
              <input
                ref="avatar"
                type="file"
                class="none"
                accept="image/png,image/jpg,image/jpeg"
                @change="onFileChange">
            </div>
          </div>
          <div class="form-group">
            <label>Nama lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="siswa.nama_lengkap">
          </div>
          <div class="form-group">
            <label>Nama panggilan</label>
            <input type="text" v-model="siswa.nama_panggilan">
          </div>
          <div class="form-group">
            <label>Nomor induk kependudukan<i class="required ml-6">*</i></label>
            <input type="text" v-model="siswa.nik">
          </div>
          <div class="form-group">
            <label>Nomor induk siswa<i class="required ml-6">*</i></label>
            <input type="text" v-model="siswa.nis">
          </div>
          <div class="form-group">
            <label>Kelas<i class="required ml-6">*</i></label>
            <el-select v-model="siswa.kelas">
              <el-option
                v-for="item in kelasArray"
                :key="item.id_kelas"
                :value="item.id_kelas"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Jenis Kelamin<i class="required ml-6">*</i></label>
            <el-select v-model="siswa.jenis_kelamin">
              <el-option
                v-for="item in jenisKelaminArray"
                :key="item.id"
                :value="item.id"
                :label="item.label"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Tempat lahir<i class="required ml-6">*</i></label>
            <input type="text" v-model="siswa.tempat_lahir">
          </div>
          <div class="form-group">
            <label>Tanggal lahir<i class="required ml-6">*</i></label>
            <el-date-picker
              v-model="siswa.tanggal_lahir"
              type="date"
              placeholder="Pilih tanggal"/>
          </div>
          <div class="form-group">
            <label>Agama<i class="required ml-6">*</i></label>
            <el-select v-model="siswa.agama">
              <el-option
                v-for="item in agamaArray"
                :key="item.id_agama"
                :value="item.id_agama"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Kategori wali<i class="required ml-6">*</i></label>
            <el-select v-model="siswa.kategori_wali">
              <el-option
                v-for="item in kategoriWaliArray"
                :key="item.id_kategori_wali"
                :value="item.id_kategori_wali"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Alamat lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="siswa.alamat">
          </div>
          <div class="form-group">
            <label/>
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input type="text" v-model="siswa.kelurahan">
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input type="text" v-model="siswa.kecamatan">
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input type="text" v-model="siswa.kota">
              </div>
              <div class="form-group">
                <label>provinsi</label>
                <input type="text" v-model="siswa.provinsi">
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input type="text" v-model="siswa.kode_pos">
              </div>
            </div>
          </div>
          <!-- orang tua -->
          <label class="title fs-500">Orang tua</label>
          <!-- ayah -->
          <label class="fs-500">A. Ayah</label>
          <div class="form-group">
            <label>Nama lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.nama_ayah">
          </div>
          <div class="form-group">
            <label>Nomor induk kependudukan<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.nik_ayah">
          </div>
          <div class="form-group">
            <label>E-mail</label>
            <input type="email" v-model="ortu.email_ayah">
          </div>
          <div class="form-group">
            <label>No. handphone</label>
            <input type="text" v-model="ortu.no_telp_ayah" maxlength="16" required>
          </div>
          <div class="form-group">
            <label>Agama<i class="required ml-6">*</i></label>
            <el-select v-model="ortu.agama_ayah">
              <el-option
                v-for="item in agamaArray"
                :key="item.id_agama"
                :value="item.id_agama"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Pekerjaan</label>
            <input type="text" v-model="ortu.pekerjaan_ayah">
          </div>
          <div class="form-group">
            <label>Alamat lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.alamat_ayah">
          </div>
          <div class="form-group">
            <label/>
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input type="text" v-model="ortu.kelurahan_ayah">
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input type="text" v-model="ortu.kecamatan_ayah">
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input type="text" v-model="ortu.kota_ayah">
              </div>
              <div class="form-group">
                <label>provinsi</label>
                <input type="text" v-model="ortu.provinsi_ayah">
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input type="text" v-model="ortu.kode_pos_ayah">
              </div>
            </div>
          </div>
          <!-- ibu -->
          <label class="fs-500">B. Ibu</label>
          <div class="form-group">
            <label>Nama lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.nama_ibu">
          </div>
          <div class="form-group">
            <label>Nomor induk kependudukan<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.nik_ibu">
          </div>
          <div class="form-group">
            <label>E-mail</label>
            <input type="email" v-model="ortu.email_ibu">
          </div>
          <div class="form-group">
            <label>No. handphone</label>
            <input type="text" v-model="ortu.no_telp_ibu" maxlength="16">
          </div>
          <div class="form-group">
            <label>Agama<i class="required ml-6">*</i></label>
            <el-select v-model="ortu.agama_ibu">
              <el-option
                v-for="item in agamaArray"
                :key="item.id_agama"
                :value="item.id_agama"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Pekerjaan</label>
            <input type="text" v-model="ortu.pekerjaan_ibu">
          </div>
          <div class="form-group">
            <label>Alamat lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.alamat_ibu">
          </div>
          <div class="form-group">
            <label/>
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input type="text" v-model="ortu.kelurahan_ibu">
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input type="text" v-model="ortu.kecamatan_ibu">
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input type="text" v-model="ortu.kota_ibu">
              </div>
              <div class="form-group">
                <label>provinsi</label>
                <input type="text" v-model="ortu.provinsi_ibu">
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input type="text" v-model="ortu.kode_pos_ibu">
              </div>
            </div>
          </div>
          <!-- wali -->
          <label class="fs-500">C. Wali</label>
          <el-radio-group v-model="ambil_wali_dari">
            <el-radio :label="1" @click="copyData(1)">Data Ayah</el-radio>
            <el-radio :label="2" @click="copyData(2)">Data Ibu</el-radio>
            <el-radio :label="3" @click="copyData(3)">Data Wali baru</el-radio>
          </el-radio-group>
          <div class="form-group">
            <label>Nama lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.nama_wali" @keyup="oldWali.nama_wali = ortu.nama_wali">
          </div>
          <div class="form-group">
            <label>Nomor induk kependudukan<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.nik_wali" @keyup="oldWali.nik_wali = ortu.nik_wali">
          </div>
          <div class="form-group">
            <label>E-mail</label>
            <input type="email" v-model="ortu.email_wali" @keyup="oldWali.email_wali = ortu.email_wali">
          </div>
          <div class="form-group">
            <label>No. handphone</label>
            <input
              type="text"
              v-model="ortu.no_telp_wali"
              maxlength="16"
              @keyup="oldWali.no_telp_wali = ortu.no_telp_wali">
          </div>
          <div class="form-group">
            <label>Agama<i class="required ml-6">*</i></label>
            <el-select v-model="ortu.agama_wali" @change="oldWali.agama_wali = ortu.agama_wali">
              <el-option
                v-for="item in agamaArray"
                :key="item.id_agama"
                :value="item.id_agama"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Pekerjaan</label>
            <input type="text" v-model="ortu.pekerjaan_wali" @keyup="oldWali.pekerjaan_wali = ortu.pekerjaan_wali">
          </div>
          <div class="form-group">
            <label>Alamat lengkap<i class="required ml-6">*</i></label>
            <input type="text" v-model="ortu.alamat_wali" @keyup="oldWali.alamat_wali = ortu.alamat_wali">
          </div>
          <div class="form-group">
            <label/>
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input type="text" v-model="ortu.kelurahan_wali" @keyup="oldWali.kelurahan_wali = ortu.kelurahan_wali">
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input type="text" v-model="ortu.kecamatan_wali" @keyup="oldWali.kecamatan_wali = ortu.kecamatan_wali">
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input type="text" v-model="ortu.kota_wali" @keyup="oldWali.kota_wali = ortu.kota_wali">
              </div>
              <div class="form-group">
                <label>provinsi</label>
                <input type="text" v-model="ortu.provinsi_wali" @keyup="oldWali.provinsi_wali = ortu.provinsi_wali">
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input type="text" v-model="ortu.kode_pos_wali" @keyup="oldWali.kode_pos_wali = ortu.kode_pos_wali">
              </div>
            </div>
          </div>
        </div>
        <div class="footer flex gap-16">
          <button class="btn danger" @click="$router.go(-1)">Batal</button>
          <button class="btn primary" @click="save">Simpan</button>
        </div>
      </div>
      <!-- <div class="aside">
        <button class="btn link"  @click="get">Import CSV</button>
        {{ 'Unduh template file' }}
        <router-link to="#">di sini</router-link>
      </div> -->
    </div>
  </LayoutMain>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import { jenisKelamin } from '@/constant'
  import { ElMessage } from 'element-plus'

  export default {
    name: 'FormSiswa',
    components: { LayoutMain },

    data() {
      return {
        jenisKelaminArray: jenisKelamin,
        img: null,
        siswa: {
          id: 1,
          nama_lengkap: "",
          nama_panggilan: "",
          nis: "",
          nik: "",
          jenis_kelamin: "",
          status: "",
          tempat_lahir: "",
          tanggal_lahir: "",
          photo: "",
          agama: null,
          kategori_wali: null,
          alamat: "",
          kelurahan: "",
          kecamatan: "",
          kota: "",
          provinsi: "",
          kode_pos: "",
          kelas: null,
          update_class_status: "",
          kelasString: "",
          namaSekolah: "",
          id_sekolah: 1,
          avatar: ""
        },
        ortu: {
          id: null,
          siswa_id: null,
          nik_wali: "",
          nama_wali: "",
          alamat_wali: "",
          kelurahan_wali: "",
          kecamatan_wali: "",
          kota_wali: "",
          provinsi_wali: "",
          kode_pos_wali: "",
          no_telp_wali: "",
          agama_wali: null,
          pekerjaan_wali: "",
          email_wali: "",
          nik_ayah: "",
          nama_ayah: "",
          alamat_ayah: "",
          kelurahan_ayah: "",
          kecamatan_ayah: "",
          kota_ayah: "",
          provinsi_ayah: "",
          kode_pos_ayah: "",
          no_telp_ayah: "",
          agama_ayah: "",
          pekerjaan_ayah: "",
          email_ayah: "",
          nik_ibu: "",
          nama_ibu: "",
          alamat_ibu: "",
          kelurahan_ibu: "",
          kecamatan_ibu: "",
          kota_ibu: "",
          provinsi_ibu: "",
          kode_pos_ibu: "",
          no_telp_ibu: "",
          agama_ibu: "",
          pekerjaan_ibu: "",
          email_ibu: "",
        },
        route: this.$route.params.id,
        ambil_wali_dari: 3,
        oldWali: {},
      }
    },
    computed: {
      ...mapGetters({
        agamaArray: 'agama',
        kategoriWaliArray: 'kategoriWali',
        kelasArray: 'kelas',
      }),
      header() {
        return this.route ? 'Edit siswa' : 'Tambah siswa'
      },
    },
    created() {
      if(this.route) {
        this.$http.post('v1/siswa/detilsiswa', JSON.stringify({"id":this.route}))
          .then((res) => {
            this.siswa = res.data.data.siswa
            this.img   = res.data.data.siswa.avatar
            this.ortu  = res.data.data.ortu
            this.ortu.agama_ayah = Number(res.data.data.ortu.agama_ayah)
            this.ortu.agama_ibu = Number(res.data.data.ortu.agama_ibu)
            this.ortu.agama_wali = Number(res.data.data.ortu.agama_wali)
            this.oldWali = {
              nik_wali:res.data.data.ortu.nik_wali,
              nama_wali:res.data.data.ortu.nama_wali,
              alamat_wali:res.data.data.ortu.alamat_wali,
              kelurahan_wali:res.data.data.ortu.kelurahan_wali,
              kecamatan_wali:res.data.data.ortu.kecamatan_wali,
              kota_wali:res.data.data.ortu.kota_wali,
              provinsi_wali:res.data.data.ortu.provinsi_wali,
              kode_pos_wali:res.data.data.ortu.kode_pos_wali,
              no_telp_wali:res.data.data.ortu.no_telp_wali,
              agama_wali:Number(res.data.data.ortu.agama_wali),
              pekerjaan_wali:res.data.data.ortu.pekerjaan_wali,
              email_wali:res.data.data.ortu.email_wali
            };
          });
      }
    },
    methods: {
      async save() {
          const data =  {
            "id": this.route ?this.route : null,
            "nama_lengkap": this.siswa.nama_lengkap,
            "nama_panggilan":this.siswa.nama_panggilan,
            "nik":this.siswa.nik,
            "nis":this.siswa.nis,
            "jenis_kelamin":this.siswa.jenis_kelamin,
            "tempat_lahir":this.siswa.tempat_lahir,
            "tanggal_lahir":this.siswa.tanggal_lahir,
            "photo": this.img !== this.siswa.avatar ? this.siswa.avatar : null,
            "agama":this.siswa.agama,
            "kategori_wali":this.siswa.kategori_wali,
            "kelas":this.siswa.kelas,
            "alamat":this.siswa.alamat, 
            "kelurahan":this.siswa.kelurahan, 
            "kecamatan":this.siswa.kecamatan, 
            "kota":this.siswa.kota, 
            "provinsi":this.siswa.provinsi, 
            "kode_pos":this.siswa.kode_pos,
            //ayah
            "nik_ayah":this.ortu.nik_ayah,
            "nama_ayah":this.ortu.nama_ayah,
            "email_ayah": this.ortu.email_ayah,
            "no_telp_ayah":this.ortu.no_telp_ayah,
            "tanggal_lahir_ayah":"90",
            "tempat_lahir_ayah":"90",
            "agama_ayah":this.ortu.agama_ayah,
            "pekerjaan_ayah":this.ortu.pekerjaan_ayah,
            "alamat_ayah":this.ortu.alamat_ayah,
            "kelurahan_ayah":this.ortu.alamat_ayah,
            "kecamatan_ayah":this.ortu.kecamatan_ayah,
            "kota_ayah":this.ortu.kota_ayah,
            "provinsi_ayah":this.ortu.provinsi_ayah,
            "kode_pos_ayah":this.ortu.kode_pos_ayah,
            // ibu
            "nik_ibu":this.ortu.nik_ibu,
            "nama_ibu":this.ortu.nama_ibu,
            "email_ibu": this.ortu.email_ibu,
            "no_telp_ibu":this.ortu.no_telp_ibu,
            "tanggal_lahir_ibu":"90",
            "tempat_lahir_ibu":"90",
            "agama_ibu":this.ortu.agama_ibu,
            "pekerjaan_ibu":this.ortu.pekerjaan_ibu,
            "alamat_ibu":this.ortu.alamat_ibu,
            "kelurahan_ibu":this.ortu.alamat_ibu,
            "kecamatan_ibu":this.ortu.kecamatan_ibu,
            "kota_ibu":this.ortu.kota_ibu,
            "provinsi_ibu":this.ortu.provinsi_ibu,
            "kode_pos_ibu":this.ortu.kode_pos_ibu,
            // wali
            "nik_wali":this.ortu.nik_wali,
            "nama_wali":this.ortu.nama_wali,
            "email_wali": this.ortu.email_wali,
            "no_telp_wali":this.ortu.no_telp_wali,
            "tanggal_lahir_wali":"90",
            "tempat_lahir_wali":"90",
            "agama_wali":this.ortu.agama_wali,
            "pekerjaan_wali":this.ortu.pekerjaan_wali,
            "alamat_wali":this.ortu.alamat_wali,
            "kelurahan_wali":this.ortu.alamat_wali,
            "kecamatan_wali":this.ortu.kecamatan_wali,
            "kota_wali":this.ortu.kota_wali,
            "provinsi_wali":this.ortu.provinsi_wali,
            "kode_pos_wali":this.ortu.kode_pos_wali,
          }
          if(!this.route) {
            this.$http.put('v1/siswa/index', JSON.stringify(data))
            .then(() => {
              ElMessage({
                showClose: true,
                message: 'Data berhasil di simpan',
                type: 'success',
              })
              this.$router.push({ path: '/kesiswaan' })
            })
            .catch((err) => {
              ElMessage({
                showClose: true,
                message: 'Gagal simpan! :'+ err.response.data.message,
                type: 'error',
              })
            });
          } else {
            this.$http.patch('v1/siswa/index', JSON.stringify(data))
            .then(() => {
              ElMessage({
                showClose: true,
                message: 'Data berhasil di simpan',
                type: 'success',
              })
              this.$router.push({ path: '/kesiswaan' })
            })
            .catch((err) => {
              console.log(err)
              ElMessage({
                showClose: true,
                message: 'Gagal simpan! :'+ err.response.data.message,
                type: 'error',
              })
            });
          }
      },
      copyData(val) {
        if(val === 1) {
          this.ortu.nik_wali = this.ortu.nik_ayah
          this.ortu.nama_wali = this.ortu.nama_ayah
          this.ortu.alamat_wali = this.ortu.alamat_ayah
          this.ortu.kelurahan_wali = this.ortu.kelurahan_ayah
          this.ortu.kecamatan_wali = this.ortu.kecamatan_ayah
          this.ortu.kota_wali = this.ortu.kota_ayah
          this.ortu.provinsi_wali = this.ortu.provinsi_ayah
          this.ortu.kode_pos_wali = this.ortu.kode_pos_ayah
          this.ortu.no_telp_wali = this.ortu.no_telp_ayah
          this.ortu.agama_wali = Number(this.ortu.agama_ayah)
          this.ortu.pekerjaan_wali = this.ortu.pekerjaan_ayah
          this.ortu.email_wali = this.ortu.email_ayah
        } else if(val === 2) {
          this.ortu.nik_wali = this.ortu.nik_ibu
          this.ortu.nama_wali = this.ortu.nama_ibu
          this.ortu.alamat_wali = this.ortu.alamat_ibu
          this.ortu.kelurahan_wali = this.ortu.kelurahan_ibu
          this.ortu.kecamatan_wali = this.ortu.kecamatan_ibu
          this.ortu.kota_wali = this.ortu.kota_ibu
          this.ortu.provinsi_wali = this.ortu.provinsi_ibu
          this.ortu.kode_pos_wali = this.ortu.kode_pos_ibu
          this.ortu.no_telp_wali = this.ortu.no_telp_ibu
          this.ortu.agama_wali = Number(this.ortu.agama_ibu)
          this.ortu.pekerjaan_wali = this.ortu.pekerjaan_ibu
          this.ortu.email_wali = this.ortu.email_ibu
        } else {
          this.ortu.nik_wali = this.route ? this.oldWali.nik_wali : ''
          this.ortu.nama_wali = this.route ? this.oldWali.nama_wali : ''
          this.ortu.alamat_wali = this.route ? this.oldWali.alamat_wali : ''
          this.ortu.kelurahan_wali = this.route ? this.oldWali.kelurahan_wali : ''
          this.ortu.kecamatan_wali = this.route ? this.oldWali.kecamatan_wali : ''
          this.ortu.kota_wali = this.route ? this.oldWali.kota_wali : ''
          this.ortu.provinsi_wali = this.route ? this.oldWali.provinsi_wali : ''
          this.ortu.kode_pos_wali = this.route ? this.oldWali.kode_pos_wali : ''
          this.ortu.no_telp_wali = this.route ? this.oldWali.no_telp_wali : ''
          this.ortu.agama_wali = this.route ? Number(this.oldWali.agama_wali) : ''
          this.ortu.pekerjaan_wali = this.route ? this.oldWali.pekerjaan_wali : ''
          this.ortu.email_wali = this.route ? this.oldWali.email_wali : ''
        }
      },
      onFileChange(e) {
        this.siswa.avatar = e.target.files[0];
        this.img = URL.createObjectURL(this.siswa.avatar);
        this.$http.post(
          'v1/attachment/upload',
          { "UploadFile":this.siswa.avatar },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        )
        .then((res) => {
          this.siswa.avatar = res.data ? res.data.data : null;
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
  }
</script>