<template>
  <div class="login">
    <div class="img">
      <div class="watch">
        <span class="title">
          {{ getTime }}
        </span>
        <span class="desc">
          {{ getDate }}
        </span>
      </div>
    </div>
    <div class="box">
      <form
        class="form m-auto"
        @submit.prevent="save">
        <div class="header mb-32">
          <span class="logo"/>
          <label>Sistem Informasi Administrasi Pendidikan</label>
        </div>
        <label class="mb-16">Pulihkan kata sandi</label>
        <div :class="error ? 'error-form' : ''" class="form-column w-100">
          <label for="password">Kata sandi baru</label>
          <div class="input-suffix w-100">
            <input type="password" ref="password" v-model="password" @keyup="passwordValidation">
            <Hide v-if="hidePassword" class="fa" @click="showHidePassword"/>
            <View v-else class="fa"  @click="showHidePassword"/>
          </div>
          <span v-if="error">Password minimal 6 karakter</span>
        </div>
        <div class="form-column w-100">
          <label for="repassword">Ulangi kata sandi baru</label>
          <div :class="notmatch ? 'error-form' : ''" class="input-suffix w-100">
            <input type="password" ref="repassword" v-model="repassword" @keyup="matchMassword">
            <Hide v-if="hideRePassword" class="fa" @click="showHideRePassword"/>
            <View v-else class="fa"  @click="showHideRePassword"/>
          </div>
          <span :class="notmatch ? 'error-form': ''">
            <span>{{ notmatch }}</span>
          </span>
        </div>
        <button type="submit" class="btn primary w-100" :disabled="notmatch || error">Kirim</button>
        <label class="link">
        </label>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElMessage } from 'element-plus'

export default {
  name: 'ResetPasswordPage',
  
  data() {
    return {
      password: '',
      repassword: '',
      hidePassword: true,
      hideRePassword: true,
      today: new Date(),
      hari: ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
      bulan: ['Januari','Febuari','Maret','April','Mei','Juni','Juli','Agustus','September','November','Desember'],
      notmatch: null,
      error: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      party: 'sekolah',
    }),
    getDate() {
      return this.hari[this.today.getDay()]+', '+
        this.today.getDate()+' '+
        this.bulan[(this.today.getMonth())]+' '+
        this.today.getFullYear();
    },
    getTime() {
      return this.today.getHours() + ':' + this.today.getMinutes();
    }
  },
  methods: {
    save() {
      const param = {
        "token": this.$route.query.token,
        "password": this.password
      }
      this.$http.post('v1/auth/resetpassword', JSON.stringify(param)).then(() => {
        ElMessage({
          showClose: true,
          message: 'Kata sandi baru berhasil di simpan.',
          type: 'success',
        })
        this.$router.push({ name: 'Login' });
      })
      .catch((err) => {
        this.error = err?.response?.data?.message
      });
    },
    showHidePassword() {
      this.hidePassword = !this.hidePassword;
      if(this.hidePassword) {
        this.$refs.password.type = 'password';
      } else {
        this.$refs.password.type = 'text';
      }
    },
    showHideRePassword() {
      this.hideRePassword = !this.hideRePassword;
      if(this.hideRePassword) {
        this.$refs.repassword.type = 'password';
      } else {
        this.$refs.repassword.type = 'text';
      }
    },
    passwordValidation() {
      if(this.password.length && this.password.length < 6) {
        this.error = 'Kata sandi minimal 6 karakter'
      } else {
        this.error = null
      }
      this.matchMassword()
    },
    matchMassword() {
      if(this.password !== this.repassword && this.repassword.length >= 6) {
        this.notmatch = 'Kata sandi tidak cocok'
      } else {
        this.notmatch = null
      }
    },
  }
}
</script>