<template>
  <LayoutMain>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Pengumuman" name="pengumuman" class="flex flex-column gap-16">
        <div class="flex gap-16 m-tb-8 w-100">
          <div class="input-suffix">
            <input
              type="text" v-model="searchTerm"
              placeholder="Judul pengumuman"
              @keyup="getData">
            <Search class="fa"/>
          </div> 
          <el-select v-model="searchKategori" placeholder="Penerima">
            <el-option
              v-for="item in kategori"
              :key="item.id"
              :label="item.label"
              :value="item.id"
              @click="getData"/>
          </el-select>
          <button class="btn reset" @click="reset">Atur ulang</button>
          <router-link
            :to="{ name: 'FormPengumuman' }"
            class="btn link right p-16">
            Tambah pengumuman
          </router-link>
          
        </div>
        <div class="box pengumuman gap-16">
          <div
            v-for="item in pengumumanData"
            :key="item.id"
            class="list">
            <div class="flex between">
              <b class="title">{{ item.judul }}</b>
              <button
                class="text-red pointer"
                @click="deletePengumuman(item.id)">Hapus</button>
            </div>
            <p class="short-content">{{ item.description }}</p>
            <span v-if="item.files.length">
              <img v-if="item.files[0].extensi !== 'pdf'" :src="`${item.files[0].url}`" class="img-200 mb-16">
              <p v-else>
                <a :href="item.files[0].url" target="_blank" class="mb-16">{{ item.files[0].nama }}</a>
              </p>
            </span>
            <p>
              <label class="sub-title mr-8">{{dateFormat(item.CreatedTime)}}</label>
              <router-link
                :to="{ name: 'ViewPengumuman', params: {id:item.id} }" 
                class="text-link">
                Lihat detil
              </router-link>
            </p>
          </div>
          <p v-if="!pengumumanData.length" class="flex center">
            Tidak ada pengumuman
          </p>
        </div>
      </el-tab-pane>
    </el-tabs>
  </LayoutMain>
</template>
    
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import 'dayjs/locale/id'
  import dayjs from 'dayjs'
  
  export default {
    name: "PengumumanPage",
    components: { LayoutMain },
    data() {
      return {
        activeName: 'pengumuman',
        searchTerm: '',
        searchKategori: null,
        page: 1,
        kategori: [
          {
            id: 1,
            label: 'Semua'
          },
          {
            id: 2,
            label: 'Guru'
          },
          {
            id: 3,
            label: 'Siswa'
          }
        ],
        pengumumanData: [],
      }
    },
    created() {
      //created code
      this.getData()
    },
    methods: {
      dateFormat(date) {
        return dayjs(date).locale('id').format('DD-MMMM-YYYY')
      },
      getData() {
        const param = {
          "q":this.searchTerm,
          "penerima":this.searchKategori,
          "page":this.page
        }
        this.$http.post('v1/pengumuman/index', JSON.stringify(param))
          .then((res) => {
            this.pengumumanData = res.data.data ?? [];
          })
          .catch((err) => {
            console.log(err)
          });
      },
      reset() {
        this.searchTerm = '';
        this.searchKategori = null;
        this.getData()
      },
      deletePengumuman(id) {
        this.$http.delete(`v1/pengumuman/index?id=${id}`)
          .then(() => {
            this.getData()
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },
  }
</script>