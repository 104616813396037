<template>
    <LayoutMain>
      // graphic
    </LayoutMain>
    </template>
    
  <script>
  import LayoutMain from '@/components/layout/LayoutMain.vue';
  
  export default {
    name: "KeuanganPage",
    components: { LayoutMain },
    data() {
      return {
        tabActive: 1,
      }
    },
  }
  </script>