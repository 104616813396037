<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">   
        <b class="header">
          Akun wali siswa
        </b>
              <div class="body mb-16">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" v-model="email" placeholder="example@mail.com">
                </div>
                <div class="form-group">
                  <label>Nama</label>
                  <input type="text" v-model="namawali" placeholder="Nama wali">
                </div> 
                <div class="form-group">
                  <label>Nomor Handphone</label>
                  <input
                    type="text"
                    v-model="no_hp" placeholder="08xxxxxxxxxxxx"
                    maxlength="14"
                    @keydown="inputNumber">
                </div> 
                <div class="form-group">
                  <label>Password</label>
                  <input type="password" v-model="password">
                </div>  
              </div>   
              <div class="flex end">
              <button class="btn default p-24" @click="openModal">
                Tambah siswa
              </button>
              </div>
              Siswa/anak yang dipilih
          <table>
            <thead>
              <tr>
                <th v-for="header in header" :key="header">{{ header }}</th>
              </tr>
            </thead>
            <tbody v-if="siswaTerpilih.length">
              <tr
                v-for="(siswa, index) in siswaTerpilih"
                :key="siswa.id">
                <td >{{ siswa.nis }}</td>
                <td>{{ siswa.nama_lengkap }}</td>
                <td>{{ siswa.kelasString }}</td>
                <td> <button class="btn danger w-100" @click="hapusSiswa(index)">Hapus</button></td>
              </tr>
              
            </tbody>
          </table>
            <div class="footer gap-24">
              <!-- <button class="btn default" @click="dialogVisible=true">Angsuran</button> -->
              <button class="btn primary" @click="save">Simpan</button>
            </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="Tambah siswa"
      width="80vh">
      <span
        v-if="!isConfirm"
        class="form">
        <div class="input-suffix w-50">
          <input
            v-model="searchTerm"
            placeholder="Cari siswa / NIS"
            @keyup="getDataSearch">
          <Search class="fa"/>
        </div> 
        <table>
          <thead>
            <tr>
              <th v-for="header in header" :key="header">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="siswa in filterSiswa"
              :key="siswa.id">
              <td >{{ siswa.nis }}</td>
              <td class="single-line">{{ siswa.nama_lengkap }}</td>
              <td>{{ siswa.kelasString }}</td>
              <td> <button class="btn primary w-100" @click="pilihSiswa(siswa)">Pilih</button></td>
            </tr>
            <tr>
              <td colspan="6" class="text-center">
                <span
                  v-if="siswa.length"
                  class="pagination">
                  <button
                    v-if="page > 1"
                    @click="getDataPrevious"
                    class="btn link"> 
                      <ArrowLeft class="fa"/>
                      Sebelumnya
                    </button>
                    <button
                      v-if="nextPage"
                      @click="getDataNext"
                    class="btn link">
                      Selanjutnya
                      <ArrowRight class="fa"/> 
                    </button>
                </span>
                <span v-else>
                  Tidak ada data
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
      <span v-else>
        Apakah Anda yakin ingin memulai tahun ajaran baru dengan data siswa yang tinggal kelas ini? 
        Pastikan keputusan ini telah didiskusikan dan disetujui oleh pihak sekolah terlebih dahulu.
      </span>
      <template 
        v-if="!isConfirm"
        #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn danger" @click="dialogVisible = false">Batal</button>
        </span>
      </template>
      <template 
        v-else
        #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn primary" @click="dialogVisible = false">Batal</button>
          <button class="btn danger" @click="save">Simpan</button>
        </span>
      </template>
    </el-dialog>
  </LayoutMain>
</template>
  
<script>
  import helpers from '@/lib/helper'
  import LayoutMain from '@/components/layout/LayoutMain.vue' 
  import { ElMessage } from 'element-plus'
  export default {
    name: 'FormAkunWali',
    components: { LayoutMain },
    props: {
      isEdit: {
        Type: Boolean,
        require: false,
        default: false
      }
    },
    data() {
      return {
        activeName: 'Pembayaran',
        dialogVisible: false, 
        header: ['Nomor induk siswa', 'Nama lengkap', 'Kelas saat ini', ''],
        siswaTerpilih:[],
        email:'',
        namawali:'',
        no_hp:'',
        id_wali:'',
        password:'', 
        page: 1,
        nextPage: null,
        searchTerm: '',
        isConfirm: false, 
        siswa: [],
      }
    },
    computed: {
      filterSiswa() {
        return this.siswa.filter(item => !this.siswaTerpilih.map(x => x.id).includes(item.id))
      }
    },
    created() { 
      if(this.$route.params.id){
        this.getData();
      }
    },
    methods: {
      inputNumber($event) {
        helpers.numberOnly($event)
      },
      getData() { 
        const param = {
        "id":this.$route.params.id, 
      }
      this.$http.post('v1/account/detilwali', JSON.stringify(param))
        .then((res) => {
          console.log()
          this.email =res.data.wali.email;
          this.namawali =res.data.wali.nama;
          this.no_hp =res.data.wali.no_hp;
          this.id_wali =res.data.wali.id;  
          this.siswaTerpilih=res.data.siswa;
        })
        .catch((err) => {
          console.log(err)
        });
      },
      openModal() {
        this.searchTerm = ''
        this.isConfirm = false
        this.dialogVisible = true
        this.getDataSiswa()
      },
      async save() {

      if(this.$route.params.id){const data = {
        "id":this.$route.params.id, 
        "email":this.email, 
        "nama":this.namawali,
        "no_hp":this.no_hp, 
        "password":this.password, 
        "siswa":this.siswaTerpilih.map((item)=>{
          return item.id;
        }), 
        }
        this.$http.patch('v1/account/wali', data)
        .then((res) => {
          if(res){
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            }) 
          this.$router.push('/konfigurasi/akun');
          }
        })
        .catch((err) => { 
          if(err.response.status===400){
            var arrErr=JSON.parse(err.response.data.message);
          console.log(arrErr);
            var textErr=''; 
            for (const key in arrErr) {  
              textErr+=arrErr[key][0]+'\n\n'; 
              }  

            ElMessage({
              showClose: true,
              message: textErr,
              type: 'error',
            }) 
          }
        });
      }else{
        const data = {
        "email":this.email,
        "nama":this.namawali,
        "no_hp":this.no_hp,
        "password":this.password, 
        "siswa":this.siswaTerpilih.map((item)=>{
          return item.id;
        }), 
        }
        this.$http.put('v1/account/wali', data)
        .then((res) => {
          if(res){
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            }) 
          this.$router.push('/konfigurasi/akun');
          }
        })
        .catch((err) => { 
          if(err.response.status===400){
            var arrErr=JSON.parse(err.response.data.message);
          console.log(arrErr);
            var textErr=''; 
            for (const key in arrErr) {  
              textErr+=arrErr[key][0]+'\n\n'; 
              }  

            ElMessage({
              showClose: true,
              message: textErr,
              type: 'error',
            }) 
          }
        });
      }
      },
      pilihSiswa(item) {
        this.siswaTerpilih.push({
          id: item.id,
          nis: item.nis,
          nama_lengkap: item.nama_lengkap,
          kelas: item.kelas,
          kelasString: item.kelasString
        })
        this.dialogVisible = false
      },
      hapusSiswa(index) {
        this.siswaTerpilih.splice(index, 1)
      },
      getDataPrevious() {
        this.page -= 1;
        this.getDataSiswa();
      },
      getDataNext() {
        this.page += 1;
        this.getDataSiswa();
      },
      getDataSearch() {
        this.page = 1;
        this.getDataSiswa();
      },
      getDataSiswa() {
        const param = {
          "q":this.searchTerm,
          "status":null,
          "kelas":null,
          "page":this.page
        }
        this.$http.post('v1/siswa/index', JSON.stringify(param))
          .then((res) => {
            this.siswa = res.data.data
            this.nextPage = res.data.data.length == 15;
          })
          .catch((err) => {
            console.log(err)
          });
      },
    },
  }
</script>