import { createStore } from 'vuex'
import axios from "axios";

const store = createStore({
    state: {
        user: {},
        sekolah: {},
        jenisPembayaran: {},
        kategoriWali: {},
        agama: {},
        kelas: {},
        tahunAjaran: {},
        jabatan: {},
        bank: [],
        token: null,
    },
    mutations: {
        login(state, token) {
            localStorage.setItem("token", token);
            state.token = token
        },
        logout() {
            localStorage.setItem("token", null);
        },
        reload(state) {
            const param = { 
                "page":1,
                "q":""
            }
            axios.get('v1/configtahunajaran/status')
            .then((res) => {
                state.tahunAjaran = res.data.data;
            })
            .catch((err) => {
              if (err.code === 'ERR_NETWORK') {
                this.commit('logout')
              }
            });
            axios.post('v1/agama/index',  JSON.stringify(param))
            .then((res) => {
                state.agama = res.data.data;
            });
            axios.post('v1/ketwali/index', JSON.stringify(param))
            .then((res) => {
                state.kategoriWali = res.data.data;
            });
            axios.post('v1/kelas/index', JSON.stringify(param))
            .then((res) => {
                state.kelas = res.data.data;
            });
            axios.post('v1/jenispembayaran/index', JSON.stringify(param))
            .then((res) => {
                state.jenisPembayaran = res.data.data;
            });
            axios.post('v1/jabatan/index', JSON.stringify(param))
              .then((res) => {
                state.jabatan = res.data.data;
              });
            axios.post('v1/sekolah/index', {"id":1})
            .then((res) => {
                state.sekolah = res.data.data;
            });
            axios.get('v1/master/bank')
            .then((res) => {
                state.bank = res.data.data;
            });
        }
    },
    actions: {
        async login({commit},{username,password}) {
            // get data from backend
            const param = {
                "email": username,
                "password": password
            }
            axios.post('v1/auth/loginadmin', JSON.stringify(param)).then((res) => {
                commit('login',res?.data?.data?.token)
            });
        },
        logout() {
            this.commit('logout')
        },
        async reload({commit}) {
            commit('reload');
        }
    },
    getters: {
        user: state => state.user,
        sekolah: state => state.sekolah,
        agama: state => state.agama,
        tahunAjaran: state => state.tahunAjaran,
        kategoriWali: state => state.kategoriWali,
        jenisPembayaran: state => state.jenisPembayaran,
        kelas: state => state.kelas,
        jabatan: state => state.jabatan,
        bank: state => state.bank,
    }
})

export default store