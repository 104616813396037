<template>
  <div class="box flex column gap-16">
    <div
      v-if="total !== 0"
      class="chart">
      <div class="flex center">
          <Doughnut
            id="canvas"
            :options="chartOptions"
            :data="chartData"
          />
          <div :style="`gap:${canvasSize/20}px`" class="text-box absolute center">
            <div :style="`font-size:${canvasSize/5}px`" class="title">{{ total }}</div>
            <div :style="`font-size:${canvasSize/8}px`" class="desc">Total</div>
          </div>
      </div>
      <div class="text-box">
        <span 
          v-for="item in data"
          :key="item.label"
          class="legend">
          <i
            class="icon"
            :style="`background-color: ${item.color};`"/>
          <label>{{ item.label }}</label>
        </span>
      </div>
    </div>
    <div
      v-else
      class="flex center">
      Tidak ada data
    </div>
    <div v-if="url.length" class="flex center">
      <router-link :to="{name: url}">Lihat detil</router-link>
    </div>
  </div>
</template>
  
<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  
ChartJS.register(ArcElement, Tooltip, Legend)
  
  export default {
    name: 'DoughnutChart',
    components: { Doughnut },
    props: {
      data: {
        Type: Array,
        require: true,
        default: [
          {
            label: 'Pengajar',
            color: '#5A3FFF',
            value: 40
          },
          {
            label: 'Honorer',
            color: '#C0DBCE',
            value: 30
          },
          {
            label: 'Pegawai',
            color: '#FFE226',
            value: 10
          },
          {
            label: 'Maggang',
            color: '#FF6D59',
            value: 60
          }
        ]
      },
      total: {
        Type: Number,
        require: true,
        default: 0
      },
      url: {
        Type: String,
        require: false,
        default: '',
      },
    },
    data() {
      return {
        canvasSize: 0,
        chartData: {
            datasets: [
              {
                label: 'Jumlah',
                backgroundColor: this.data.map((i) => i.color),
                data: this.data.map((i) => i.value)
              }
            ]
        },
        chartOptions: {
            cutout: '75%',
            plugins: {
                legend: {
                  display: false
                },
                responsive: false,
                maintainAspectRatio: false
            }
        }
      }
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.canvasSize = document.getElementById('canvas').offsetWidth;
    },
    methods: {
      onResize() {
        this.canvasSize = document.getElementById('canvas').offsetWidth;
      },
    },
  }
  </script>