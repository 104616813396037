<template>
  <div class="left-menu">
    <div class="content">
      <div class="logo">
        <img class="img" :src="sekolah.avatar"/>
        <label class="title">{{sekolah.nama}}</label>
      </div>
      <div class="link">
        <router-link
          :to="{name: 'Home'}">
          <Monitor class="fa"/>
          {{'Dashboard'}}
        </router-link>
        <router-link
          :to="{name: 'Kepegawaian'}"
          :class="{'active': activePage === 'Kepegawaian'}">
          <User class="fa"/>
          {{'Kepegawaian'}}
        </router-link>
        <router-link
          :to="{name: 'Kesiswaan'}"
          :class="{'active': activePage === 'Kesiswaan'}">
          <Reading class="fa"/>
          {{'Kesiswaan'}}
        </router-link>
        <router-link
          :to="{name: 'Pembayaran'}">
          <CreditCard class="fa"/>
          {{'Pembayaran'}}
        </router-link>
        <!-- <router-link
          :to="{name: 'Keuangan'}">
          <Collection class="fa"/>
          {{'Keuangan'}}
        </router-link> -->
        <router-link
          :to="{name: 'Laporan'}">
          <Notebook class="fa"/>
          {{'Laporan'}}
        </router-link>
        <router-link
          :to="{name: 'Pengumuman'}">
          <Bell class="fa"/>
          {{'Pengumuman'}}
        </router-link>
        <a
          :class="{'active': konfigurasiMenu}"
          @click.prevent="isExpan = !isExpan">
          <Setting class="fa"/>
          <span class="dropdown-btn">
            {{'Konfigurasi'}}
            <ArrowUp
              :class="{'invers': isExpan}"
              class="fa arrow"/>
          </span>
        </a>
        <div
          :class="{'colapse-menu': !isExpan}"
          class="dropdown-menu">
        <router-link
          :to="{name: 'KonfigurasiUmum'}">
          {{'Tahun ajaran'}}
        </router-link>
        <router-link
          :to="{name: 'KonfigurasiSekolah'}"
          :class="{'active': activePage === 'Sekolah'}">
          {{'Profile sekolah'}}
        </router-link>
        <router-link
          :to="{name: 'KonfigurasiAkun'}">
          {{'Akun wali murid'}}
        </router-link>
        <router-link
          :to="{name: 'KonfigurasiAkunPegawai'}">
          {{'Akun pegawai'}}
        </router-link>
        <router-link
          :to="{name: 'KonfigurasiAkunAdmin'}">
          {{'Akun Admin'}}
        </router-link>
        <router-link
          :to="{name: 'KonfigurasiMasterData'}">
          {{'Data master'}}
        </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'LeftMenu',
    data() {
      return {
        isExpan: false,
        konfigurasiMenu: false,
      }
    },
    computed: {
    ...mapGetters({
      sekolah: 'sekolah',
    }),
      activePage () {
        let activePage = '';
        switch(this.$route.name) {
          case 'FormSiswa':
          case 'ViewSiswa':
            activePage = 'Kesiswaan';
            break;
          case 'FormPegawai':
            activePage = 'Kepegawaian';
            break;
          case 'FormSekolah':
            activePage = 'Sekolah';
            break;
        }
        return activePage;
      }
    },
    mounted() {
      switch(this.$route.name) {
          case 'KonfigurasiUmum':
          case 'FormTagihanSiswa':
          case 'KonfigurasiSekolah':
          case 'FormSekolah':
          case 'KonfigurasiAkunAdmin':
          case 'KonfigurasiAkunPegawai':
          case 'KonfigurasiAkun':
          case 'KonfigurasiMasterData':
            this.isExpan = true;
            this.konfigurasiMenu = true;
            break;
            default:
              this.isExpan = false;
              break;
        }
    },
  }
</script>