<template>
  <LayoutMain>
    <div class="dashboard mb-16">
      <div class="grid">
        <div class="flex flex-column mr-8">
          <label class="title mb-8">Total siswa</label>
          <doughnut-chart
            v-if="siswaTotal.length"
            class="mr-8"
            :data="siswaTotal"
            :total="totalSiswa"/>
          <div v-else class="box center"> Tidak ada data </div>
        </div>
        <div class="flex flex-column mr-8">
          <label class="title mb-8">Siswa</label>
          <doughnut-chart
            v-if="siswaAktif.length"
            class="mr-8"
            :data="siswaAktif"
            :total="totalAktif"/>
          <div v-else class="box center"> Tidak ada data </div>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Siswa" name="siswa" class="flex flex-column gap-16">
        <table-siswa/>
      </el-tab-pane>
    </el-tabs>
  </LayoutMain>
</template>
    
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import DoughnutChart from '@/components/components/ChartDoughnut.vue'
  import TableSiswa from '@/components/components/TableSiswa.vue'
  
  export default {
    name: "KesiswaanPage",
    components: {
      LayoutMain,
      DoughnutChart,
      TableSiswa,
    },
    data() {
      return {
        activeName: 'siswa',
        totalSiswa: 0,
        totalAktif: 0,
        siswaTotal: [],
        siswaAktif: []
      }
    },
    async created() {
      this.$http.get('v1/siswa/chart')
          .then((res) => {
            this.siswaTotal = res.data.total.map((data) => ({
              label: data.label,
              color: data.label === 'Aktif' ? '#0197CF' : '#FFC01E',
              value: data.value,
            }));
            this.siswaAktif = res.data.aktif.map((data) => ({
              label: data.label,
              color: data.label === 'Laki-laki' ? '#FFAC2E' : '#15FF64',
              value: data.value,
            }));

            res.data.aktif.forEach(data => {
              this.totalSiswa = Number(this.totalSiswa) + Number(data.value)
          });
            res.data.total.forEach(data => {
              this.totalAktif = Number(this.totalAktif) + Number(data.value)
          });
          })
      .catch((err) => {
        console.log(err)
      });
    },
    methods: {
      // code
    },
  }
  </script>