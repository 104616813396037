<template>
  <div class="box flex column gap-16">
    <Bar
      :options="chartOptions"
      :data="chartData"/>
    <div v-if="url.length" class="flex center">
      <router-link :to="{name: url, query: {tab: 'absen'}}">Lihat detil</router-link>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartData: {
      Type: Object,
      require: true,
      default: {
        labels: ['January', 'February'],
        datasets: [
          {
            label: 'Hadir',
            backgroundColor: '#165BAA',
            data: [40, 39]
          },
        ]
      },
    },
    url: {
      Type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  }
}
</script>