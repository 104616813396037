<template>
  <div class="login">
    <div class="img">
      <div class="watch">
        <span class="title">
          {{ getTime }}
        </span>
        <span class="desc">
          {{ getDate }}
        </span>
      </div>
    </div>
    <div class="box">
      <form
        class="form m-auto"
        @submit.prevent="login">
        <div class="header mb-32">
          <span class="logo"/>
          <label>Sistem Informasi Administrasi Pendidikan</label>
        </div>
        <label class="mb-16">Login dibawah untuk masuk ke dashboard</label>
        <div
          :class="error ? 'error-form' : ''"
          class="form-column w-100">
          <label>User ID atau email</label>
          <input v-model="username" @keydown="error = ''">
          <span>{{ error }}</span>
        </div>
        <div class="form-column w-100">
          <label for="password">Password</label>
          <div class="input-suffix w-100">
            <input type="password" ref="password" v-model="password" @keydown="error = ''">
            <Hide v-if="hidePassword" class="fa" @click="showHidePassword"/>
            <View v-else class="fa"  @click="showHidePassword"/>
          </div>
        </div>
        <button type="submit" class="btn primary w-100">Login</button>
        <router-link to="#" @click.prevent ="dialogVisible = true">
          Tidak bisa login? Lupa akun/password?
        </router-link>
        <label class="link">
        </label>
      </form>
      <div class="footer">
        <button class="btn link">Bantuan?</button>
      </div>
    </div>
  </div>

  <el-dialog
    v-model="dialogVisible"
    title="Lupa password"
    width="60vh"
    class="dialog">
    <span class="form">
      <span class="text-left">
        Silakan masukkan alamat email yang Anda. Kami akan segera mengirimkan email berisi
        link untuk mereset kata sandi Anda. Pastikan untuk memeriksa kotak masuk serta folder
        spam/junk email Anda jika email kami tidak muncul dalam beberapa menit.
      </span>
      <span class="form-group">
        <label>E-mail</label>
        <span :class="error ? 'error-form' : ''">
          <input required type="email" v-model="email" @keydown="error = ''">
          <span>{{ error }}</span>
        </span>
      </span>
    </span>
    <template #footer>
      <span class="dialog-footer flex gap-24 end">
        <button class="btn danger" @click="dialogVisible = false">Batal</button>
        <button class="btn primary" @click="sendMail"> Kirim</button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElMessage } from 'element-plus'

export default {
  name: 'LoginPage',
  
  data() {
    return {
      username: '',
      password: '',
      hidePassword: true,
      today: new Date(),
      hari: ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
      bulan: ['Januari','Febuari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'],
      error: '',
      dialogVisible: false,
      email: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      party: 'sekolah',
    }),
    getDate() {
      return this.hari[this.today.getDay()]+', '+
        this.today.getDate()+' '+
        this.bulan[(this.today.getMonth())]+' '+
        this.today.getFullYear();
    },
    getTime() {
      return this.today.getHours() + ':' + this.today.getMinutes();
    }
  },
  methods: {
    login() {
      const param = {
        "email": this.username,
        "password": this.password
      }
      this.$http.post('v1/auth/loginadmin', JSON.stringify(param)).then((res) => {
        localStorage.setItem("token", res?.data?.data?.token);
        this.$store.dispatch('reload');
        this.$router.push({ name: 'Home' });
      })
      .catch((err) => {
        this.error = err?.response?.data?.message
      });
    },
    showHidePassword() {
      this.hidePassword = !this.hidePassword;
      if(this.hidePassword) {
        this.$refs.password.type = 'password';
      } else {
        this.$refs.password.type = 'text';
      }
    },
    sendMail() {
      // eslint-disable-next-line
      const re = /@./gm
      if(!this.email || !re.test(this.email)) {
        this.error = 'Harap masukan alamat email dengan benar.'
      } else {
        
      const param = {
        "email": this.email
      }
      this.$http.post('v1/auth/requestpassword', JSON.stringify(param)).then(() => {
        ElMessage({
          showClose: true,
          message: 'Permintaan ubah password berhasil di kirim.',
          type: 'success',
        })
      this.dialogVisible = false
      });
      }
    }
  }
}
</script>