<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          Edit profile sekolah
        </b>
        <div class="body">
          <label class="title">Informasi</label>
          <div class="form-group">
            <label>Photo profile</label>
            <div class="flex">
              <div class="avatar">
                <Camera
                  v-if="!img"
                  class="fa" />
                <img
                  v-else
                  :src="img">
              </div>
              <a class="link" @click="$refs.avatar.click()">Ubah foto</a>
              <input
                ref="avatar"
                type="file"
                class="none"
                accept="image/png,image/jpg,image/jpeg"
                @change="onFileChange">
            </div>
          </div>
          <div class="form-group">
            <label>Nama sekolah<i class="required ml-6">*</i></label>
            <input type="text" v-model="sekolah.nama">
          </div>
          <div class="form-group">
            <label>NPSN<i class="required ml-6">*</i></label>
            <input type="text" v-model="sekolah.nspn">
          </div>
          <div class="form-group">
            <label>Jenis sekolah</label>
            <el-select  v-model="sekolah.jenis_sekolah">
              <el-option
                v-for="item in jenisSekolahArray"
                :key="item.id"
                :value="item.id"
                :label="item.label"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Jenjang pendidikan</label>
            <el-select  v-model="sekolah.jenjang_pendidikan">
              <el-option
                v-for="item in jenjangPendidikanArray"
                :key="item.id"
                :value="item.id"
                :label="item.label"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Tagline<i class="required ml-6"></i></label>
            <input type="text" v-model="sekolah.tagline">
          </div>
          <div class="form-group">
            <label>E-mail</label>
            <input
              type="email"
              v-model="sekolah.email"
              placeholder="contoh@email.com"
              maxlength="190">
          </div>
          <div class="form-group">
            <label>No. handphone</label>
            <input
              type="text"
              v-model="sekolah.no_telp"
              placeholder="No. hp"
              @keydown="inputNumber"
              maxlength="16">
          </div>
          <div class="form-group">
            <label>Radius Lokasi</label>
            <input
              type="text"
              v-model="sekolah.radius"
              placeholder="20"
              @keydown="inputNumber"
              maxlength="16">
            <span class="suffix">Meter</span>
          </div>
          <div class="form-group">
            <label>Alamat lengkap<i class="required ml-6">*</i></label>
            <!-- <input type="text" v-model="sekolah.alamat" placeholder="alamat lengkap"> -->
            <places-autocomplete
              id="location"
              ref="placesAutocomplete"
              class="x-input"
              :alamat="sekolah.alamat"
              @getInput="getInputLocation"
              @placeSelected="geoplaceSelected"
              @placeReset="geoplaceReset"/>
          </div>
          <div class="form-group">
            <label/>
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input type="text" v-model="sekolah.desa">
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input type="text" v-model="sekolah.kecamatan">
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input type="text" v-model="sekolah.kabupaten">
              </div>
              <div class="form-group">
                <label>Profinsi</label>
                <input type="text" v-model="sekolah.provinsi">
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input type="text" v-model="sekolah.kode_pos">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label> </label>
            <div class="flex column">
              <osm-map
                v-if="place.lat"
                :place="place"/>
            </div>
          </div>
          <label class="title">Rekening sekolah</label>
          <div
            v-for="(item, index) in sekolah.bank"
            :key="item.bank"
            class="body">
            <label class="title fw-500">Rekening {{ index+1 }}</label>
            <div class="form-group">
              <label>Nama bank</label>
              <el-select  v-model="item.bank">
                <el-option
                  v-for="item in bankArray"
                  :key="item.id"
                  :value="item.id"
                  :label="item.keterangan"/>
              </el-select>
            </div>
            <div class="form-group">
              <label>Nomor rekening</label>
              <input type="text" v-model="item.no_rek">
            </div>
          </div>
          <div class="flex end">
            <div
              class="add-item mb-16"
              @click="tambahRekening">
              + Tambah rekening
            </div>
          </div>
        </div>
        <div class="footer">
          <button class="btn primary" @click="save">Simpan</button>
        </div>
      </div>
    </div>
  </LayoutMain>
</template>
  
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import { jenisSekolah, jenjangPendidikan } from '@/constant'
  import { mapGetters } from 'vuex'
  import OsmMap from '@/components/components/OsmMap'
  import PlacesAutocomplete from '@/components/components/PlacesAutocomplete'
  import { ElMessage } from 'element-plus'
  import helpers from '@/lib/helper'
  export default {
    name: 'FormSekolah',
    components: { LayoutMain, OsmMap, PlacesAutocomplete },
    props: {
      isEdit: {
        Type: Boolean,
        require: false,
        default: true
      }
    },

    data() {
      return {
        img: null,
        jenisSekolahArray: jenisSekolah,
        jenjangPendidikanArray: jenjangPendidikan,
        sekolah: {
          nama: '',
          logo: '',
          nspn: '',
          tagline: '',
          alamat: '',
          desa: '',
          kecamatan: '',
          kabupaten: '',
          provinsi: '',
          kode_pos: '',
          jenjang_pendidikan: null,
          jenis_sekolah: null,
          no_telp: '',
          email: '',
          radius: null,
          lat: null,
          long: null,
          bank: [{
            bank: '',
            bankString: '',
            no_rek: '',
          }]
        },
        place: {},
      }
    },
    computed: {
      ...mapGetters({
        getSekolah: 'sekolah',
        bankArray: 'bank',
      })
    },
    mounted() {
      this.sekolah = this.getSekolah;
      this.img = this.sekolah.avatar
      this.place = {
        place_id: null,
        name: this.sekolah.alamat,
        lat: this.sekolah.lat,
        long: this.sekolah.long,
      }
    },
    methods: {
      inputNumber($event) {
        helpers.numberOnly($event)
      },
      getInputLocation(value) {
        this.sekolah.alamat = value;
      },
      geoplaceSelected(place) {
        this.place = place;
        this.sekolah.lat = place.lat
        this.sekolah.long = place.long
      },
      geoplaceReset() {
        this.sekolah.alamat = ''
        this.place = {}
      },
      async save() {
        this.$http.patch(`v1/sekolah/index/${this.sekolah.id}`, JSON.stringify(this.sekolah))
        .then((res) => {
          console.log(res)
          ElMessage({
            showClose: true,
            message: res.data.message,
            type: 'success',
          })
          this.$router.push({ name: 'KonfigurasiSekolah' })
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            message: err,
            type: 'error',
          })
        });
      },
      onFileChange(e) {
        const image = e.target.files[0];
        this.img = URL.createObjectURL(image);
        this.$http.post(
          'v1/attachment/upload',
          { "UploadFile":image },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        )
        .then((res) => {
          this.sekolah.logo = res.data ? res.data.data : null;
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            message: err,
            type: 'error',
          })
        });
      },
      tambahRekening() {
        this.sekolah.bank.push({
          bank_id: '',
          bankString: '',
          no_rek: '',
        });
      }
    },
  }
</script>