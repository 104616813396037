<template>
  <LayoutMain>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Akun pegawai" name="wali" class="flex flex-column gap-16">
        <table-akun-pegawai/>
      </el-tab-pane>
    </el-tabs>
  </LayoutMain>
  </template>
  
<script>
import LayoutMain from '@/components/layout/LayoutMain.vue';
import TableAkunPegawai from '@/components/components/TableAkunPegawai.vue'

export default {
  name: "KonfigurasiAkunPegawai",
  components: { LayoutMain, TableAkunPegawai },
  data() {
    return {
      activeName: 'wali',
      tabActive: 1,
    }
  },
}
</script>