<template>
  <div class="flex gap-16 m-tb-8 w-100">
    <div class="input-suffix">
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Nama pegawai / NIP"
        @keyup="getDataSearch">
      <Search class="fa"/>
    </div> 
    <el-select v-model="selectJabatan" placeholder="Pilih jabatan">
      <el-option
        v-for="item in jabatanArray"
        :key="item.id_jabatan"
        :value="item.id_jabatan"
        :label="item.keterangan"
        @click="getDataSearch"/>
    </el-select>
    <button class="btn reset" @click="reset">Atur ulang</button>
    <router-link
      :to="{ name: 'FormPegawai' }"
      class="btn link right p-16">
      Tambah pegawai
    </router-link>
  </div>
  <table>
    <thead>
      <tr>
        <th v-for="header in headerTablePegawai" :key="header">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="pegawai in pegawaiData"
        :key="pegawai.nip">
        <td class="single-line">
          <router-link
            :to="{ name: 'ViewPegawai', params: {id:pegawai.id} }" 
            class="text-link">
            {{ pegawai.nama_lengkap }}
          </router-link>
          </td>
        <td>{{ pegawai.nip }}</td>
        <td>{{ pegawai.jenisKelaminString }}</td>
        <td>{{ pegawai.agama }}</td>
        <td>{{ pegawai.tempat_lahir }}, {{ dateFormat(pegawai.tanggal_lahir) }}</td>
        <td>{{ pegawai.jabatan }}</td>
      </tr>
      <tr>
        <td colspan="6" class="text-center">
          <span
            v-if="pegawaiData.length"
            class="pagination">
            <button
              v-if="page > 1"
              @click="getDataPrevious"
              class="btn link"> 
                <ArrowLeft class="fa"/>
                Sebelumnya
              </button>
              <button
                v-if="nextPage"
                @click="getDataNext"
                class="btn link">
                Selanjutnya
                <ArrowRight class="fa"/> 
              </button>
          </span>
          <span v-else>
            Tidak ada data
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import 'dayjs/locale/id'
  import dayjs from 'dayjs'

  export default {
    name: 'TablePegawai',
    props: {
      // handle props
    },
    data() {
      return {
        selectJabatan: null,
        searchTerm: '',
        headerTablePegawai: [
          'Nama lengkap',
          'NIP',
          'Jenis kelamin',
          'Agama',
          'Tempat, tanggal lahir',
          'Jabatan'],
        pegawaiData:[],
        nextPage: null,
        page: 1,
      }
    },

    computed: {
      ...mapGetters({
        jabatanArray: 'jabatan',
      }),
    },
    mounted() {
      this.getData();
    },

    methods: {
      dateFormat(date) {
        return dayjs(date).locale('id').format('DD-MMMM-YYYY')
      },
      getDataPrevious() {
        this.page -= 1;
        this.getData();
      },
      getDataNext() {
        this.page += 1;
        this.getData();
      },
      getDataSearch() {
        this.page = 1;
        this.getData();
      },
      getData() {
        const param = {
          "q":this.searchTerm,
          "jabatan":this.selectJabatan,
          "page":this.page
        }
        this.$http.post('v1/pegawai/index', JSON.stringify(param))
          .then((res) => {
            this.pegawaiData = res.data.data
            this.nextPage = res.data.data.length == 15;
          })
          .catch((err) => {
            console.log(err)
          });
      },
      jenisKelamin(val) {
        if (val === 'l') return 'Laki-laki'
        else return 'Perempuan' 
      },
      statusSiswa(val) {
        if (val == 1) return 'Aktif'
        else 'Tidak aktif'
      },
      reset() {
        this.page = 1;
        this.searchTerm = '';
        this.selectJabatan = null;
        this.getData();
        },
    },
  }
</script>