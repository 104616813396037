<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          Tambah tagihan
        </b>
        <div class="body">
          <div class="form-group w-50">
            <label>Tahun ajaran</label>
            <input type="text" :value="tahunAjaran.deskripsi" disabled>
          </div>
          <div class="form-group w-50">
            <label>Jenis tagihan<i class="required ml-6">*</i></label>
            <el-select v-model="jenis_pembayaran">
              <el-option
                v-for="item in jenisPembayaran"
                :key="item.id_jenis_pembayaran"
                :value="item.id_jenis_pembayaran"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group w-50">
            <label>Tipe tagihan<i class="required ml-6">*</i></label>
            <el-select v-model="tipe_pembayaran" :disabled="type !== 'add'">
              <el-option
                v-for="item in tipePembayaran"
                :key="item.id"
                :value="item.id"
                :label="item.label"/>
            </el-select>
          </div>
          <div v-if="tipe_pembayaran === 1" class="form-group w-50">
            <label>Tahun<i class="required ml-6">*</i></label>
            <el-select v-model="tahun">
              <el-option
                v-for="item in tahunArray"
                :key="item"
                :value="item"
                :label="item"/>
            </el-select>
          </div>
          <div class="grid">
            <div class="form-group w-50 w-100">
              <label>Bulan<i class="required ml-6">*</i></label>
              <el-select v-model="bulan_awal">
                <el-option
                  v-for="item in bulanArray"
                  :key="item.id"
                  :value="item.id"
                  :label="item.label"/>
              </el-select>
            </div>
            <div v-if="tipe_pembayaran === 2" class="form-group w-90">
              <label>Sampai<i class="required ml-6">*</i></label>
              <el-select v-model="bulan_akhir">
                <el-option
                  v-for="item in bulanArray"
                  :key="item.id"
                  :value="item.id"
                  :label="item.label"/>
              </el-select>
            </div>
          </div>
          <div class="form-group w-50">
            <label>Tanggal jatuh tempo<i class="required ml-6">*</i></label>
            <el-select v-model="jatuh_tempo">
              <el-option
                v-for="item in tanggalArray"
                :key="item"
                :value="item"
                :label="item"/>
            </el-select>
          </div>
          <div v-if="type !== 'add'" class="form-group w-50-100">
            <label>Nominal tagihan<i class="required ml-6">*</i></label>
            <input type="text" v-model="nominal" @keydown="inputNumber" placeholder="88888">
          </div>
          <!-- <div v-if="type !== 'add'" class="form-group w-50-100">
            <label>Tagihan berdasarkan?</label>
            <el-radio-group v-model="kategori">
              <el-radio :label="1" @click="copyData(1)">Kategori Orang tua</el-radio>
              <el-radio :label="2" @click="copyData(2)">Siswa</el-radio>
            </el-radio-group>
          </div> -->
          <div v-if="kategori === 1" class="form-group w-50">
            <label>Kategori wali<i class="required ml-6">*</i></label>
            <el-select v-model="kategori_wali">
              <el-option
                v-for="item in kategoriWali"
                :key="item.id_kategori_wali"
                :value="item.id_kategori_wali"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div v-if="kategori === 1" class="form-group w-50">
            <label>Kelas<i class="required ml-6">*</i></label>
            <el-select v-model="kelas">
              <el-option
                v-for="item in kelasArray"
                :key="item.id_kelas"
                :value="item.id_kelas"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div v-if="type === 'add'" class="form-group w-50-100">
            <label>Nominal tagihan<i class="required ml-6">*</i></label>
            <input type="text" v-model="nominal" @keydown="inputNumber" placeholder="88888">
          </div>
          <div v-if="kategori === 2" class="form-group w-50-100 flex between">
            <label>Tambah siswa</label>
            <button class="btn link" @click="openModal">Tambah siswa</button>
          </div>
          <div v-if="kategori === 2 && siswaTerpilih.length" class="form-group w-50-100">
            <label>Siswa terpilih</label>
            <table>
                <thead>
                  <tr>
                    <th v-for="header in header" :key="header">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(siswa, index) in siswaTerpilih"
                  :key="siswa.id">
                  <td >{{ siswa.nis }}</td>
                  <td class="single-line pt-24">{{ siswa.nama_lengkap }}</td>
                  <td>{{ siswa.kelas }}</td>
                  <td> <button class="btn danger w-100" @click="hapusSiswa(index)">Hapus</button></td>
                </tr>
                <tr>
                  <td colspan="6" class="text-center">
                    <span
                      v-if="siswa.length"
                      class="pagination">
                      <button
                        v-if="page > 1"
                        @click="getDataPrevious"
                        class="btn link"> 
                          <ArrowLeft class="fa"/>
                          Sebelumnya
                        </button>
                        <button
                          v-if="nextPage"
                          @click="getDataNext"
                          class="btn link">
                          Selanjutnya
                          <ArrowRight class="fa"/> 
                        </button>
                    </span>
                    <span v-else>
                      Tidak ada data
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="footer gap-16">
            <button class="btn danger" @click="$router.go(-1)">Batal</button>
            <button class="btn primary" @click="save">Simpan</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="Pilih siswa"
      width="80vh">
      <span class="form">
        <div class="input-suffix w-50">
          <input
            v-model="searchTerm"
            placeholder="Cari siswa / NIS"
            @keyup="getDataSearch">
          <Search class="fa"/>
        </div> 
        <table>
          <thead>
            <tr>
              <th v-for="header in header" :key="header">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="siswa in siswa"
              :key="siswa.id">
              <td >{{ siswa.nis }}</td>
              <td class="single-line pt-24">{{ siswa.nama_lengkap }}</td>
              <td>{{ siswa.kelas }}</td>
              <td> <button class="btn primary w-100" @click="pilihSiswa(siswa)">Pilih</button></td>
            </tr>
            <tr>
              <td colspan="6" class="text-center">
                <span
                  v-if="siswa.length"
                  class="pagination">
                  <button
                    v-if="page > 1"
                    @click="getDataPrevious"
                    class="btn link"> 
                      <ArrowLeft class="fa"/>
                      Sebelumnya
                    </button>
                    <button
                      v-if="nextPage"
                      @click="getDataNext"
                      class="btn link">
                      Selanjutnya
                      <ArrowRight class="fa"/> 
                    </button>
                </span>
                <span v-else>
                  Tidak ada data
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
      <template #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn danger" @click="dialogVisible = false">Batal</button>
        </span>
      </template>
    </el-dialog>
  </LayoutMain>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import { tipePembayaran, bulan, tanggal, tahun } from '@/constant'
  import helpers from '@/lib/helper'

  export default {
    name: 'FormTagihanSiswa',
    components: { LayoutMain },
    props: {
      isEdit: {
        Type: Boolean,
        require: false,
        default: false
      }
    },

    data() {
      return {
        tipePembayaran: tipePembayaran,
        tahunArray: tahun,
        bulanArray: bulan,
        tanggalArray: tanggal,
        tipe_pembayaran: 1,
        jenis_pembayaran: 1,
        tahun: null,
        bulan_awal: null,
        bulan_akhir: null,
        jatuh_tempo: null,
        kategori_wali: null,
        kelas: null,
        nominal: '',
        type: this.$route.params.additional,
        kategori: 1,
        dialogVisible: false,
        siswa_tinggal_kelas: [],
        page: 1,
        nextPage: null,
        searchTerm: '',
        header: ['Nomor induk siswa', 'Nama lengkap', 'Kelas saat ini', ''],
        siswaTerpilih: [],
        siswa: [],
      }
    },
    computed: {
      ...mapGetters({
        jenisPembayaran: 'jenisPembayaran',
        tahunAjaran: 'tahunAjaran',
        kategoriWali: 'kategoriWali',
        kelasArray: 'kelas',

      }),
    },
    mounted() {
   this.jenis_pembayaran=this.jenisPembayaran[0].id_jenis_pembayaran
    },
    methods: {
      openModal() {
        this.searchTerm = ''
        this.isConfirm = false
        this.dialogVisible = true
        this.getDataSiswa()
      },
      pilihSiswa(item) {
        this.siswaTerpilih.push({
          id: item.id,
          nis: item.nis,
          nama_lengkap: item.nama_lengkap,
          kelas: item.kelas
        })
        this.siswa = this.siswa.filter(item => !this.siswaTerpilih.map(x => x.id).includes(item.id))
        this.dialogVisible = false
      },
      hapusSiswa(index) {
        this.siswaTerpilih.splice(index, 1)
      },
      inputNumber($event) {
        helpers.numberOnly($event)
      },
      getDataPrevious() {
        this.page -= 1;
        this.getDataSiswa();
      },
      getDataNext() {
        this.page += 1;
        this.getDataSiswa();
      },
      getDataSearch() {
        this.page = 1;
        this.getDataSiswa();
      },
      getDataSiswa() {
        const param = {
          "q":this.searchTerm,
          "status":null,
          "kelas":null,
          "page":this.page
        }
        this.$http.post('v1/siswa/index', JSON.stringify(param))
          .then((res) => {
            this.siswa = res.data.data;
            this.siswa = this.siswa.filter(item => !this.siswaTerpilih.map(x => x.id).includes(item.id))
            this.nextPage = res.data.data.length == 15;
          })
          .catch((err) => {
            console.log(err)
          });
      },
      save() {
        if(this.type === 'add') {
          this.saveAdd();
        } else {
          this.saveAdditional();
        }
      },
      async saveAdd() {
        const data = {
          "tipe_pembayaran": this.tipe_pembayaran,
          "jenis_pembayaran": this.jenis_pembayaran,
          "tahun": this.tahun,
          "bulan": this.bulan_awal,
          "kategori_wali": this.kategori_wali,
          "kelas": this.kelas,
          "tahun_ajaran": this.tahunAjaran.tahun_ajaran.id_tahun_ajaran,
          "nominal": this.nominal,
          "exp": this.jatuh_tempo,
          "bulan_awal": this.bulan_awal,
          "bulan_akhir": this.bulan_akhir
          }
        this.$http.put('v1/configtahunajaran/tagihan', data)
        .then(() => {
          this.$router.push({ name: 'KonfigurasiUmum' })
        })
        .catch((err) => {
          console.log(err)
        });
      },
      async saveAdditional() {
        const data = {
          "kategori": this.kategori,
          "tipe_pembayaran": this.tipe_pembayaran,
          "jenis_pembayaran": this.jenis_pembayaran,
          "tahun": this.tahun,
          "bulan": this.bulan_awal,
          "kategori_wali": this.kategori_wali,
          "kelas": this.kelas,
          "tahun_ajaran": this.tahunAjaran.tahun_ajaran.id_tahun_ajaran,
          "nominal": this.nominal,
          "exp": this.jatuh_tempo,
          "siswa_terpilih": this.siswaTerpilih.map(item => item.id),
          }
        this.$http.put('v1/configtahunajaran/addtagihan', data)
        .then(() => {
          this.$router.push({ name: 'KonfigurasiUmum' })
        })
        .catch((err) => {
          console.log(err)
        });
      },
      onFileChange(e) {
        this.avatar = e.target.files[0];
        this.img = URL.createObjectURL(this.avatar);
        this.$http.post(
          'v1/attachment/upload',
          { "UploadFile":this.avatar },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        )
        .then((res) => {
          console.log(res)
          this.avatar = res.data ? res.data.data : null;
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
  }
</script>