<template>
  <LayoutMain>
    <div class="dashboard">
      <div class="flex flex-column gap-16">
        <div class="grid">
          <div class="flex flex-column mr-8">
            <label class="title mb-8">Pegawai</label>
            <doughnut-chart
              v-if="pegawaiChart.length"
              :data="pegawaiChart"
              :total="jumlahPegawai"
              url="Kepegawaian"/>
            <div v-else class="box center"> Tidak ada data </div>
          </div>
          <div class="flex flex-column ml-8">
            <label class="title mb-8">Pembayaran</label>
            <doughnut-chart
              v-if="pembayaranChart.length"
              :data="pembayaranChart"
              :total="jumlahPembayaran"
              url="Pembayaran"/>
            <div v-else class="box center"> Tidak ada data </div>
          </div>
        </div>
        <div class="flex flex-column">
          <label class="title mb-8">Presensi pegawai</label>
          <bar-chart
            v-if="presensiChart"
            :chart-data="presensiChart"
            url="Kepegawaian"
            class="box center"/>
        </div>
        <div class="flex flex-column gap-8">
          <div class="flex between">
            <label class="title mb-8">Riwayat pembayaran</label>
            <el-select v-model="filterStatus" placeholder="Status pembayaran">
              <el-option
                v-for="item in statusPembayaran"
                :key="item.status"
                :label="item.statusString"
                :value="item.status"
                @click="getDataPembayaran"/>
            </el-select>
          </div>
          <table>
            <thead>
              <tr>
                <th>Kategori</th>
                <th>Nama</th>
                <th>Tanggal</th>
                <th>Jumlah</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pembayaranData" :key="item">
                <td>{{ '-' }}</td>
                <td>{{ item.namaSiswa }}</td>
                <td>{{ dateFormat(item.tanggal) }}</td>
                <td>{{ formatRupiah(item.nominal) }}</td>
                <td :class="statusClass(item.status)">{{ item.statusString }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="widget flex flex-column gap-16">
        <div class="flex flex-column">
          <label class="title mb-8">Pengumuman</label>
          <div v-if="pengumumanData.length" class="box pengumuman gap-16">
            <div
              v-for="item in pengumumanData"
              :key="item.id"
              class="list">
              <div class="flex between">
                <b class="title">{{ item.judul }}</b>
              </div>
              <p class="short-content">{{ item.description }}</p>
              <span v-if="item.files.length">
                <img v-if="item.files[0].extensi !== 'pdf'" :src="`${item.files[0].url}`" class="">
                <p v-else>
                  <a :href="item.files[0].url" target="_blank" class="mb-16">{{ item.files[0].nama }}</a>
                </p>
              </span>
              <p>
                <label class="sub-title mr-8">{{dateFormat(item.CreatedTime)}}</label>
                <router-link
                  :to="{ name: 'ViewPengumuman', params: {id:item.id} }" 
                  class="text-link">
                  Lihat detil
                </router-link>
              </p>
            </div>
          </div>
          <div v-else class="box center"> Tidak ada data </div>
        </div>
        <div class="flex flex-column">
          <label class="title mb-8">Kalender</label>
          <div class="box flex center">
            <label>{{ currentMonth }}</label>
            <el-calendar v-model="date">
            <template #header="{ currentMonth }">
              {{ currentMonth }}
            </template>
          </el-calendar>
          </div>
        </div>
      </div>
    </div>
  </LayoutMain>
</template>
    
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import BarChart from '@/components/components/ChartBar.vue'
  import DoughnutChart from '@/components/components/ChartDoughnut.vue'
  import 'dayjs/locale/id'
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex';
  
  export default {
    name: "DashboardPage",
    components: {
      LayoutMain,
      BarChart,
      DoughnutChart,
    },
    data() {
      return {
        pengumumanData: [],
        pegawaiChart:[],
        jumlahPegawai: 0,
        pembayaranChart: [],
        jumlahPembayaran: 0,
        labelNya: [],
        valueNya: [],
        statusPembayaran: [],
        pembayaranData: [],
        filterStatus: '',
        currentMonth: new Date().toLocaleString('default', { month: 'long', year:'numeric' }),
        bulan: [
          'Januari',
          'Febuari',
          'Maret',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Agustus',
          'September',
          'Oktober',
          'November',
          'Desember',
        ],
        presensiChart: {
          labels: ['January', 'February'],
          datasets: [
            {
              label: 'Hadir',
              backgroundColor: '#165BAA',
              data: [40, 39]
            },
          ]},
      }
    },
    computed: {
      ...mapGetters({
        jabatanArray: 'jabatan',
      }),
    },
    created() {
      const param = {
          "q":"",
          "penerima":null,
          "page":1
      }
      this.$http.post('v1/pengumuman/index', JSON.stringify(param))
        .then((res) => {
          this.pengumumanData = res.data.data ?? [];
        })
        .catch((err) => {
          console.log(err)
        });

        this.$http.post('v1/dashboard/index', JSON.stringify(param))
        .then((res) => {
          this.pegawaiChart = res.data.pegawai.map((data) => ({
            label: data.label,
            color: '#'+Math.floor(Math.random()*16777215).toString(16),
            value: data.value,
          }));
          res.data.pegawai.forEach(data => {
            this.jumlahPegawai = Number(this.jumlahPegawai) + Number(data.value)
          });

          this.pembayaranChart = res.data.pembayaran.map((data) => ({
            label: data.label,
            color: '#'+Math.floor(Math.random()*16777215).toString(16),
            value: data.value,
          }));
          res.data.pembayaran.forEach(data => {
            this.jumlahPembayaran = Number(this.jumlahPembayaran) + Number(data.value)
          });
        })
        .catch((err) => {
          console.log(err)
        });

        this.$http.post('v1/dashboard/presensi', JSON.stringify(param))
        .then((res) => {
          res.data.data.forEach(data => {
            this.labelNya.push(this.bulan[data.bulan-1])
          });
          res.data.data.forEach(data => {
            this.valueNya.push(data.jumlah)
          });
          this.presensiChart = {
            labels: this.labelNya,
            datasets: [
              {
                label: 'Hadir',
                backgroundColor: '#165BAA',
                data: this.valueNya
              },
            ]
          }
        })
        .catch((err) => {
          console.log(err)
        });


        this.$http.get(`v1/dashboard/pembayaranstatus`)
        .then((res) => {
          this.statusPembayaran = res.data.data;
        })
        .catch((err) => {
          console.log(err)
        });
        this.getDataPembayaran();

    },
    methods: {
      getDataPembayaran() {
        this.$http.get(`v1/dashboard/pembayaran?status=${this.filterStatus}`)
        .then((res) => {
          this.pembayaranData = res.data.data;
        })
        .catch((err) => {
          console.log(err)
        });
      },
      statusClass(status) {
        let statusClass = 'success'
        switch (status) {
          case 1:
            statusClass = 'primary'
            break
          case 2:
            statusClass = 'warning'
            break
          default:
            statusClass = 'success'
            break
        }
        return statusClass
      },
      dateFormat(date) {
        return dayjs(date).locale('id').format('DD-MMMM-YYYY')
      },
      formatRupiah(value) {
        var formatter = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR'
      });
      return formatter.format(Number(value));
      }
    }
  }
</script>