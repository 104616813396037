<template>
  <div class="flex gap-16 m-tb-8 w-100">
    <el-select v-model="searchJenis" placeholder="Jenis pembayaran">
      <el-option
        v-for="item in jenisPembayaran"
        :key="item.id_jenis_pembayaran"
        :label="item.keterangan"
        :value="item.id_jenis_pembayaran"
        @click="getDataSearch"/>
    </el-select>
    <el-select v-model="searchKategoriWali" placeholder="Kategori wali">
      <el-option
        v-for="item in kategoriWali"
        :key="item.id_kategori_wali"
        :label="item.keterangan"
        :value="item.id_kategori_wali"
        @click="getDataSearch"/>
    </el-select>
    <el-select v-model="searchKelas" placeholder="Kelas">
      <el-option
        v-for="item in kelasArray"
        :key="item.id_kelas"
        :label="item.keterangan"
        :value="item.id_kelas"
        @click="getDataSearch"/>
    </el-select>
    <button class="btn reset" @click="reset">Atur ulang</button>
    <router-link
      :to="{ name: 'FormTagihanSiswa', params: {additional:!canAddTagihan?'additional':'add'} }"
      class="btn link right p-16">
      Tambah tagihan siswa
    </router-link>
  </div>
  <table>
    <thead>
      <tr>
        <th v-for="header in header" :key="header">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in tagihan_data"
        :key="item.id_tagihan">
        <td>{{ item.jenisPembayaranString }}</td>
        <td>{{ item.BulanString }}</td>
        <td>{{ item.kategoryWaliString }}</td>
        <td>{{ item.kelasString }}</td>
        <td>{{ item.tahunAjaranString }}</td>
        <td>{{ currencyFormat(item.nominal) }}</td>
        <td>{{ dateFormat(item.exp) }}</td>
      </tr>
      <tr>
        <td colspan="7" class="text-center">
          <span
            v-if="tagihan_data.length"
            class="pagination">
            <button
              v-if="page > 1"
              @click="getDataPrevious"
              class="btn link"> 
                <ArrowLeft class="fa"/>
                Sebelumnya
              </button>
              <button
                v-if="nextPage"
                @click="getDataNext"
               class="btn link">
                Selanjutnya
                <ArrowRight class="fa"/> 
              </button>
          </span>
          <span v-else>
            Tidak ada data
          </span>
        </td>
      </tr>
    </tbody>
    <tfoot>
    </tfoot>
  </table>
</template>
    
<script>
  import { mapGetters } from 'vuex';
  import helpers from '@/lib/helper'

  export default {
    name: 'TableTagihanSiswa',
    props: {
      canAddTagihan: {
        Type: Boolean,
        require: false,
        default: false,
      }
    },

    components: {  },
    data() {
      return {
        searchJenis: null,
        searchKategoriWali: null,
        searchKelas: null,
        header: ['Jenis pembayaran', 'Bulan', 'Kategori wali', 'Kelas', 'Tahun ajaran', 'Nominal', 'Jatuh tempo'],
        tagihan_data: [],
        nextPage: false,
        page: 1,
      }
    },
    computed: {
      ...mapGetters({
        jenisPembayaran: 'jenisPembayaran',
        kategoriWali: 'kategoriWali',
        kelasArray: 'kelas',

      }),
    },
    mounted() {
      this.getData();
    },

    methods: {
      getDataPrevious() {
        this.page -= 1;
        this.getData();
      },
      getDataNext() {
        this.page += 1;
        this.getData();
      },
      getDataSearch() {
        this.page = 1;
        this.getData();
      },
      dateFormat(date) {
        return helpers.dateFormat(date)
      },
      getData() {
        const param = {
          "jenis_pembayaran":this.searchJenis,
          "kelas":this.searchKelas,
          "kategori_wali":this.searchKategoriWali,
          "page": this.page 
        }
      this.$http.post('v1/configtahunajaran/tagihan', JSON.stringify(param))
        .then((res) => {
          this.tagihan_data = res.data.data
          this.nextPage = res.data.data.length == 15;
        })
        .catch((err) => {
          console.log(err)
        });
      },
    reset() {
      this.page = 1;
      this.searchJenis = null;
      this.searchKelas = null;
      this.searchKategoriWali = null;
      this.getData();
      },
      currencyFormat(price) {
        return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR"}).format(price)
      },
    },
  }
</script>