<template>
  <LayoutMain>
    <button class="btn link distance" @click="$router.go(-1)">
      <Back class="fa" />
      {{ 'Kembali' }}
    </button>
    <div class="form-add">
      <div class="main">
        <div class="flex between">
          <div class="card profile">
            <img class="avatar" :src="`${avatarSiswa}`" />
            <div class="info">
              <span class="title single-line">{{ namaSiswa }}</span>
              <span class="desc">{{ nis }}</span>
              <span class="btn">Kelas {{ kelas }}</span>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="Detil pembayaran" name="Pembayaran" class="flex flex-column gap-16">
            <div class="body">
              <div class="form-group">
                <label>No Transaksi</label>
                <input disabled type="text" v-model="noTransaksi">
              </div>
              <div class="form-group">
                <label>Jenis pembayaran</label>
                <input disabled type="text" v-model="jenisPembayaran">
              </div>
              <div class="form-group">
                <label>Bulan</label>
                <input disabled type="text" v-model="bulan">
              </div>
              <div class="form-group">
                <label>Jatuh Tempo</label>
                <input disabled type="text" v-model="jatuhTempo">
              </div>
              <div class="form-group">
                <label>Jumlah</label>
                <input disabled type="text" v-model="jumlah">
              </div>
              <div class="form-group">
                <label>Bukti pembayaran</label>
                <input disabled type="text" value="Download" style="text-align:right; color:#0197CF80;">
              </div>
              <div class="form-group">
                <label>Status</label>
                <input disabled type="text" v-model="status">
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="footer gap-24">
          <button v-if="!angsuran" class="btn default"
            @click="this.$router.push({ name: 'FormAngsuran', params: { id: $route.params.id } })">Angsuran</button>
          <button class="btn primary" @click="dialogVisible = true">Bayar tunai</button>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="Pembayaran Tunai" width="90vh">
      <span class="form">

        <div class="form-add">
          <div class="main">
            <div class="body">
              <div class="form-group">
                <label>No Transaksi</label>
                <input disabled type="text" v-model="noTransaksi">
              </div>
              <div class="form-group">
                <label>Jenis pembayaran</label>
                <input disabled type="text" v-model="jenisPembayaran">
              </div>
              <div class="form-group">
                <label>Bulan</label>
                <input disabled type="text" v-model="bulan">
              </div>
              <div class="form-group">
                <label>Jatuh Tempo</label>
                <input disabled type="text" v-model="jatuhTempo">
              </div>
              <div class="form-group">
                <label>Jumlah</label>
                <input disabled type="text" v-model="jumlah">
              </div>
            </div>
          </div>
        </div>
      </span>
      <template #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn danger" @click="dialogVisible = false">Batal</button>
          <button class="btn primary" @click="save"> Simpan</button>
        </span>
      </template>
    </el-dialog>
  </LayoutMain>
</template>
  
<script>
// import axios from 'axios'
import LayoutMain from '@/components/layout/LayoutMain.vue'
import { ElMessage } from 'element-plus'
export default {
  name: 'FormPembayaran',
  components: { LayoutMain },
  props: {
    isEdit: {
      Type: Boolean,
      require: false,
      default: false
    }
  },

  data() {
    return {
      activeName: 'Pembayaran',
      dialogVisible: false,
      noTransaksi: '',
      jenisPembayaran: '',
      bulan: '',
      jatuhTempo: '',
      jumlah: '',
      status: '',
      namaSiswa: '',
      kelas: '',
      nis: '',
      avatarSiswa: '',
      angsuran: null
    }
  },
  created() {
    this.getData();
  },
  methods: {

    getData() {
      const param = {
        "id": this.$route.params.id,
      }
      this.$http.post('v1/tagihansiswa/index', JSON.stringify(param))
        .then((res) => {
          this.noTransaksi = res.data.data.id;
          this.jenisPembayaran = res.data.data.kategori;
          this.bulan = res.data.data.bulanString;
          this.jatuhTempo = res.data.data.jatuhTempo;
          this.jumlah = res.data.data.nominal;
          this.status = res.data.data.StatusString;
          this.angsuran = res.data.data.angsuran;
          this.namaSiswa = res.data.data.namaSiswa;
          this.avatarSiswa = res.data.data.avatarSiswa;
          this.kelas = res.data.data.kelas;
          this.nis = res.data.data.nis;

        })
        .catch((err) => {
          console.log(err)
        });
    },
    async save() {
      const data = {
        "id_pembayar": 1,
        "metode_pembayaran": 1,
        "id_bank": null,
        "no_rek": null,
        "id_rek": null,
        "bukti_code": null,
        "tagihan": [this.$route.params.id],
      }
      this.$http.put('v1/pembayaran/index', data)
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
            this.$router.push('/pembayaran');
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            var arrErr = JSON.parse(err.response.data.message);
            console.log(arrErr);
            var textErr = '';
            for (const key in arrErr) {
              textErr += arrErr[key][0] + '\n';
            }
            ElMessage({
              showClose: true,
              message: textErr,
              type: 'error',
            })
          }
        });
    },
  },
}
</script>