<template>
  <LayoutMain>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Siswa" name="siswa" class="flex flex-column gap-16">
        <div class="flex gap-16 m-tb-8 w-100">
          <el-select v-model="searchTahunAjaran" placeholder="Tahun ajaran">
            <el-option
              v-for="item in tahunAjaran"
              :key="item"
              :label="item"
              :value="item"/>
            </el-select>
          <el-select v-model="searchBulan" placeholder="Bulan">
            <el-option
              v-for="item in bulan"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <el-select v-model="searchstatusSiswa" placeholder="Status">
            <el-option
              v-for="item in statusSiswa"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <router-link to="#" class="btn link right p-16 mr-8">
            <span class="mr-8">Cari</span> 
            <Search class="fa"/>
          </router-link>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Pegawai" name="pegawai" class="flex flex-column gap-16">
        <div class="flex gap-16 m-tb-8 w-100">
          <el-select v-model="searchTahunAjaran" placeholder="Tahun ajaran">
            <el-option
              v-for="item in tahunAjaran"
              :key="item"
              :label="item"
              :value="item"/>
            </el-select>
          <el-select v-model="searchBulan" placeholder="Bulan">
            <el-option
              v-for="item in bulan"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <el-select v-model="searchstatusSiswa" placeholder="Status">
            <el-option
              v-for="item in statusSiswa"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <router-link to="#" class="btn link right p-16 mr-8">
            <span class="mr-8">Cari</span> 
            <Search class="fa"/>
          </router-link>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Absensi pegawai" name="absensi pegawai" class="flex flex-column gap-16">
        <div class="flex gap-16 m-tb-8 w-100">
          <el-select v-model="searchTahunAjaran" placeholder="Tahun ajaran">
            <el-option
              v-for="item in tahunAjaran"
              :key="item"
              :label="item"
              :value="item"/>
            </el-select>
          <el-select v-model="searchBulan" placeholder="Bulan">
            <el-option
              v-for="item in bulan"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <el-select v-model="searchstatusSiswa" placeholder="Status">
            <el-option
              v-for="item in statusSiswa"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <router-link to="#" class="btn link right p-16 mr-8">
            <span class="mr-8">Cari</span> 
            <Search class="fa"/>
          </router-link>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Gaji pegawai" name="gaji pegawai" class="flex flex-column gap-16">
        <div class="flex gap-16 m-tb-8 w-100">
          <el-select v-model="searchTahunAjaran" placeholder="Tahun ajaran">
            <el-option
              v-for="item in tahunAjaran"
              :key="item"
              :label="item"
              :value="item"/>
            </el-select>
          <el-select v-model="searchBulan" placeholder="Bulan">
            <el-option
              v-for="item in bulan"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <el-select v-model="searchstatusSiswa" placeholder="Status">
            <el-option
              v-for="item in statusSiswa"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <router-link to="#" class="btn link right p-16 mr-8">
            <span class="mr-8">Cari</span> 
            <Search class="fa"/>
          </router-link>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Pembayaran" name="pembayaran" class="flex flex-column gap-16">
        <div class="flex gap-16 m-tb-8 w-100">
          <el-select v-model="searchTahunAjaran" placeholder="Tahun ajaran">
            <el-option
              v-for="item in tahunAjaran"
              :key="item"
              :label="item"
              :value="item"/>
            </el-select>
          <el-select v-model="searchBulan" placeholder="Bulan">
            <el-option
              v-for="item in bulan"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <el-select v-model="searchstatusSiswa" placeholder="Status">
            <el-option
              v-for="item in statusSiswa"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <router-link to="#" class="btn link right p-16 mr-8">
            <span class="mr-8">Cari</span> 
            <Search class="fa"/>
          </router-link>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Keuangan" name="keuangan" class="flex flex-column gap-16">
        <div class="flex gap-16 m-tb-8 w-100">
          <el-select v-model="searchTahunAjaran" placeholder="Tahun ajaran">
            <el-option
              v-for="item in tahunAjaran"
              :key="item"
              :label="item"
              :value="item"/>
            </el-select>
          <el-select v-model="searchBulan" placeholder="Bulan">
            <el-option
              v-for="item in bulan"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <el-select v-model="searchstatusSiswa" placeholder="Status">
            <el-option
              v-for="item in statusSiswa"
              :key="item.id"
              :label="item.label"
              :value="item.id"/>
          </el-select>
          <router-link to="#" class="btn link right p-16 mr-8">
            <span class="mr-8">Cari</span> 
            <Search class="fa"/>
          </router-link>
        </div>
      </el-tab-pane>
    </el-tabs>
  </LayoutMain>
</template>
    
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import { tahun, bulan, statusSiswa } from '@/constant'
  
  export default {
    name: "LaporanPage",
    components: { LayoutMain },
    data() {
      return {
        activeName: 'siswa',
        tahunAjaran: tahun,
        bulan: bulan,
        statusSiswa: statusSiswa,
        searchTahunAjaran: null,
        searchBulan: null,
        searchstatusSiswa: null,
      }
    },
    created() {
      //created code
    },
    methods: {
      // code
    },
  }
  </script>