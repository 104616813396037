<template>
  <LayoutMain>
    <button class="btn link distance" @click="$router.go(-1)">
      <Back class="fa" />
      {{ "Kembali" }}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header"> Angsuran </b>
        <div class="body">
          <div class="form-group">
            <label>No Tagihan</label>
            <input disabled type="text" v-model="noTransaksi" />
          </div>
          <div class="form-group">
            <label>Nama lengkap</label>
            <input disabled type="text" v-model="namaSiswa" />
          </div>
          <div class="form-group">
            <label>No Induk Siswa</label>
            <input disabled type="text" v-model="nis" />
          </div>
          <div class="form-group">
            <label>Jenis pembayaran</label>
            <input disabled type="text" v-model="jenisPembayaran" />
          </div>
          <div class="form-group">
            <label>Bulan</label>
            <input disabled type="text" v-model="bulan" />
          </div>
          <div class="form-group">
            <label>Jatuh Tempo</label>
            <input disabled type="text" v-model="jatuhTempo" />
          </div>
          <div class="form-group">
            <label>Jumlah yang harus dibayar</label>
            <input disabled type="text" v-model="jumlah" />
          </div>
          <div class="form-group">
            <label>Tipe</label>
            <input disabled type="text" value="Angsuran" />
          </div>
          <div class="form-group">
            <label>Jumlah angsuran</label>
            <input type="text" v-model="jumlahAngsuran" />
          </div>
          <div
            class="form-group"
            v-for="index in parseInt(jumlahAngsuran > 0 ? jumlahAngsuran : 0)"
            :key="index"
          >
            <label>Tanggal Angsuran {{ index }}<i class="required ml-6">*</i></label>
            <el-date-picker
              v-model="tanggalAngsuran[index - 1]"
              type="date"
              placeholder="Pilih tanggal"
            />
          </div>
        </div>
        <div class="footer gap-24">
          <!-- <button class="btn default" @click="dialogVisible=true">Angsuran</button> -->
          <button class="btn primary" @click="dialogVisible = true">Simpan</button>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="Pembayaran Tunai" width="464px">
      <span class="form">
        <p>
          Pastikan Anda sudah mengecek rincian angusran dengan teliti sebelum menyimpan.
          Apakah Anda ingin melanjutkan?
        </p>
      </span>
      <template #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn danger" @click="dialogVisible = false">Batal</button>
          <button class="btn primary" @click="save">Ya</button>
        </span>
      </template>
    </el-dialog>
  </LayoutMain>
</template>

<script>
// import axios from 'axios'
import LayoutMain from "@/components/layout/LayoutMain.vue";
import { ElMessage } from 'element-plus'
  import 'dayjs/locale/id'
  import dayjs from 'dayjs'
export default {
  name: "FormAngsuran",
  components: { LayoutMain },
  props: {
    isEdit: {
      Type: Boolean,
      require: false,
      default: false,
    },
  },

  data() {
    return {
      activeName: "Pembayaran",
      dialogVisible: false,
      noTransaksi: "",
      jenisPembayaran: "",
      bulan: "",
      jatuhTempo: "",
      jumlah: "",
      namaSiswa: "",
      nis: "",
      status: "",
      jumlahAngsuran: 0,
      tanggalAngsuran: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      const param = {
        id: this.$route.params.id,
      };
      this.$http
        .post("v1/tagihansiswa/index", JSON.stringify(param))
        .then((res) => {
          this.noTransaksi = res.data.data.id;
          this.jenisPembayaran = res.data.data.kategori;
          this.bulan = res.data.data.bulanString;
          this.jatuhTempo = res.data.data.jatuhTempo;
          this.jumlah = res.data.data.nominal;
          this.status = res.data.data.StatusString;
          this.namaSiswa = res.data.data.namaSiswa;
          this.nis = res.data.data.nis;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async save() {
      let tgl_angsuran=[];
      this.tanggalAngsuran.forEach(element => {
        tgl_angsuran.push(dayjs(element).format('YYYY-MM-DD'));
       });
      const data = {
        "id": this.$route.params.id,
        "jumlah_angsuran": this.jumlahAngsuran,
        "tanggal_angsuran": tgl_angsuran,
      }
      this.$http.post('v1/tagihansiswa/angsuran', data)
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
            this.$router.push('/pembayaran');
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            var arrErr = JSON.parse(err.response.data.message);
            console.log(arrErr);
            var textErr = '';
            for (const key in arrErr) {
              textErr += arrErr[key][0] + '\n';
            }
            alert(textErr)
          }
        });
    },
  },
};
</script>
