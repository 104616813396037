import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/main.styl';
import {
    ElSelect,
    ElTabs,
    ElDialog,
    ElCalendar,
    ElDatePicker,
    ElTimePicker,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElMessage,
    ElRadioGroup,
    ElRadio,
} from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from './store.js';
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as VueGoogleMaps from '@fawmi/vue-google-maps';
import 'leaflet/dist/leaflet.css';

const app = createApp(App)
app.use(router)
app.use(ElDialog)
app.use(ElSelect)
app.use(ElTabs)
app.use(ElCalendar)
app.use(ElDatePicker)
app.use(ElTimePicker)
app.use(ElDropdown)
app.use(ElDropdownMenu)
app.use(ElDropdownItem)
app.use(ElMessage)
app.use(ElRadioGroup)
app.use(ElRadio)
app.use(store)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(VueAxios, axios)

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common.Accept = '*/*';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.langPreferred = getCookie('langPrefs') ? getCookie('langPrefs') : 'en';
window.axiosInterceptor = axios.interceptors.request.use(async (config) => {
  try {
    // const accessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiNjUxMzhkYzZmMzJlZDguMzUwNTQyNDcifQ.wAzk8M8mWgFUqe-S7FIkji7NN6LCRw1JzD7g9qw_eMQ';
    const accessToken = localStorage.getItem("token");
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  } catch (e) {
    // eslint-disable-next-line no-param-reassign
    config.baseURL += '/v1/auth/';
  }
  return config;
});

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCQXfwB4CmopBp2uUEQgEtSSTBGf-QpLAo', //VUE_APP_GMAP_API_KEY,
    libraries: 'places', // jika Anda memerlukan perpustakaan tambahan seperti tempat
  },
});

app.mount('#app')