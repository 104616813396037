
import 'dayjs/locale/id'
import dayjs from 'dayjs'

const helpers = {
  numberOnly($event) {
    const allowControll = [8,9,37,38,39,40]
    if (!/[0-9]/gm.test($event.key) && !allowControll.includes($event.keyCode)) {
      $event.preventDefault();
    }
  },
  formatCurrency(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },
  formatTime($event) {
    const allowControll = [8,9,37,38,39,40]
    if (!/[0-9:]/gm.test($event.key) && !allowControll.includes($event.keyCode)) {
      $event.preventDefault();
    }
  },
  dateFormat(date) {
    return dayjs(date).locale('id').format('DD-MMMM-YYYY');
  },
} 

export default helpers