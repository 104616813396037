<template>
  <div class="flex gap-16 m-tb-8">
    <div v-if="id === 0" class="input-suffix">
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Cari pegawai / NIP"
        @keyup="getData">
      <Search class="fa"/>
    </div> 
    <el-select v-model="searchBulan" placeholder="Bulan">
      <el-option
        v-for="item in bulanArray"
        :key="item.id"
        :label="item.label"
        :value="item.id"
        @click="getData"/>
    </el-select>
    <button class="btn reset" @click="reset">Atur ulang</button>
    <router-link
      v-if="id === 0"  
      :to="{ name: 'FormPegawaiGaji' }"
      class="btn link right p-16">
      Input gaji
    </router-link>
  </div>
  <div class="w-100">
    <table>
      <thead>
        <tr v-if="id !== 0">
          <th v-for="headerDetil in headerDetil" :key="headerDetil">{{ headerDetil }}</th>
        </tr>
        <tr v-else>
          <th v-for="header in header" :key="header">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="gaji in data"
          :key="gaji.id_gaji">
          <td v-if="id === 0" class="single-line">
            <router-link
              :to="{ name: 'ViewPegawai', params: {id:gaji.pegawai_id} }" 
              class="text-link">
              {{ gaji.nama_lengkap }}
            </router-link>
          </td>
          <td v-if="id === 0">{{ gaji.nip }}</td>
          <td>{{ gaji.tahun }}</td>
          <td>{{ gaji.bulan }}</td>
          <td>{{ gaji.jumlah_hadir }}</td>
          <td>{{ formatRupiah(gaji.gaji_pokok) }}</td>
          <td>
            <span
              @click="showmodalTunjangan(
                gaji.tj_jabatan,
                gaji.tj_makan,
                gaji.tj_transport,
                gaji.tj_hadir,
                gaji.tj_hari_raya)"
              class="primary">
              {{ formatRupiah(gaji.total_tunjangan) }}
            </span>
          </td>
          <td>
            <span 
              @click="showmodalPotongan(gaji.ptg_pph21, gaji.ptg_bpjs, gaji.ptg_lainya)"
              class="primary">
              {{ formatRupiah(gaji.total_potongan) }}
            </span>
          </td>
          <td>{{ formatRupiah(gaji.total_gaji) }}</td>
          <td>
            <button @click="download(gaji.id_gaji, gaji.pegawai_id)">
              <Download class="fa"/>
            </button>
          </td>
        </tr>
        <tr>
        <td colspan="6" class="text-center">
          <span
            v-if="data.length"
            class="pagination">
            <button
              v-if="page > 1"
              @click="getDataPrevious"
              class="btn link"> 
                <ArrowLeft class="fa"/>
                Sebelumnya
              </button>
              <button
                v-if="nextPage"
                @click="getDataNext"
                class="btn link">
                Selanjutnya
                <ArrowRight class="fa"/> 
              </button>
          </span>
          <span v-else>
            Tidak ada data
          </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>


  <el-dialog
    v-model="modalPotongan"
    title="Detil potongan"
    width="40vh">
    <span class="flex flex-column gap-16">
      <span class="flex flex-column gap-4">
        <label>BPJS</label>
        <input type="text" :value="formatRupiah(ptg_bpjs)" disabled>
      </span>
      <span class="flex flex-column gap-4">
        <label>PPH21</label>
        <input type="text" :value="formatRupiah(ptg_pph21)" disabled>
      </span>  
      <span class="flex flex-column gap-4">
        <label>Lain-lain</label>
        <input type="text" :value="formatRupiah(ptg_lainya)" disabled>
      </span>
    </span>
    <template #footer>
      <span class="dialog-footer flex gap-24 end">
        <button class="btn info" @click="modalPotongan = false">Tutup</button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="modalTunjangan"
    title="Detil Tunjangan"
    width="40vh">
    <span class="flex flex-column gap-16">
      <span class="flex flex-column gap-4">
        <label>Jabatan</label>
        <input type="text" :value="formatRupiah(tj_jabatan)" disabled>
      </span>
      <span class="flex flex-column gap-4">
        <label>Kehadiran</label>
        <input type="text" :value="formatRupiah(tj_kehadiran)" disabled>
      </span>
      <span class="flex flex-column gap-4">
        <label>Makan</label>
        <input type="text" :value="formatRupiah(tj_makan)" disabled>
      </span>
      <span class="flex flex-column gap-4">
        <label>Transportasi</label>
        <input type="text" :value="formatRupiah(tj_transport)" disabled>
      </span>
      <span class="flex flex-column gap-4">
        <label>Hari raya</label>
        <input type="text" :value="formatRupiah(tj_lebaran)" disabled>
      </span>
    </span>
    <template #footer>
      <span class="dialog-footer flex gap-24 end">
        <button class="btn info" @click="modalTunjangan = false">Tutup</button>
      </span>
    </template>
  </el-dialog>
</template>
    
<script>
//   import axios
import { bulan } from '@/constant'
export default {
  name: 'TablePegawaiRekapGaji',
  props: {
    id: {
        type: Number,
        required: false,
        default: 0,
      }
  },

  components: {  },
  data() {
    return {
      searchTahun: null,
      searchBulan: null,
      searchTerm: '',
      bulanArray: bulan,
      header: [
        'Nama',
        'NIP',
        'Tahun',
        'Bulan',
        'Kehadiran',
        'Gaji pokok',
        'Tunjangan',
        'Potongan',
        'Total gaji',
        '',
      ],
      headerDetil: [
        'Tahun',
        'Bulan',
        'Kehadiran',
        'Gaji pokok',
        'Tunjangan',
        'Potongan',
        'Total gaji',
        ''
      ],
      data: [],
      page: 1,
      modalTitle: 'Potongan',
      modalPotongan: false,
      modalTunjangan: false,
      tj_jabatan: 0,
      tj_makan: 0,
      tj_transport: 0,
      tj_kehadiran: 0,
      tj_lebaran: 0,
      ptg_pph21: 0,
      ptg_bpjs: 0,
      ptg_lainya: 0
    }
  },
  computed: {
    title() {
      return this.modalTitle;
    }
  },
  mounted() {
      this.getData()
  },
  methods: {
    showmodalPotongan(pph21, bpjs, lainya) {
      this.ptg_bpjs = bpjs
      this.ptg_pph21 = pph21
      this.ptg_lainya = lainya
      this.modalPotongan = true
    },
    showmodalTunjangan(jabatan, makan, transport, hadir, lebaran) {
      this.tj_jabatan = jabatan
      this.tj_makan = makan
      this.tj_transport = transport
      this.tj_kehadiran = hadir
      this.tj_lebaran = lebaran
      this.modalTunjangan = true
    },
    reset() {
      this.searchTahun = null;
      this.searchBulan = null;
      this.searchTerm = '';
      this.getData();
    },
    getDataPrevious() {
      this.page -= 1;
      this.getData();
    },
    getDataNext() {
      this.page += 1;
      this.getData();
    },
    getDataSearch() {
      this.page = 1;
      this.getData();
    },
    getData() {
      const param = {
          "q": this.searchTerm,
          "id_pegawai": this.id,
          "tahun":this.searchTahun,
          "bulan":this.searchBulan,
          "page": this.page
        }
      this.$http.post('v1/pegawai/gaji', JSON.stringify(param))
        .then((res) => {
          this.data = res.data.data
          this.nextPage = res.data.data.length == 15;
        })
        .catch((err) => {
          console.log(err)
        });
    },
    formatRupiah(value) {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR'
    });
    return formatter.format(value);
    },
    download(id, pegawai_id) {
      this.$http.put('v1/gaji/download', JSON.stringify(
        {
          'id': id,
          'id_pegawai':pegawai_id,
        }))
        .then((res) => {
          const link = document.createElement('a');
          link.href = res.data.data.url;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          link.remove();
          return false;
        })
        .catch((err) => {
          console.log(err)
        });
    },
  },
}
</script>