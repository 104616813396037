<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali' }}
    </button>
    <div class="box gap-32 p-32">
      <div class="flex between">
          <div class="card profile">
            <img class="avatar" :src="`${pegawai.avatar}`"/>
            <div class="info">
              <span class="title single-line">{{ pegawai.nama_lengkap }}</span>
              <span class="desc">{{ pegawai.nip }}</span>
              <span class="btn">{{ pegawai.jabatan }}</span>
            </div>
          </div>
          <router-link
            :to="{ name: 'FormPegawaiEdit' }"
            class="btn link p-16">
            Edit pegawai
          </router-link>
        </div>
      <aside>
        <el-tabs v-model="activeName">
          <el-tab-pane label="Biodata" name="biodata" class="flex flex-column gap-16">
            <div class="form-group">
              <label>Nama lengkap</label>
              <input v-model="pegawai.nama_lengkap" disabled>
            </div>
            <div class="form-group">
              <label>Nama panggilan</label>
              <input v-model="pegawai.nama_panggilan" disabled>
            </div><div class="form-group">
            <label> Nomor induk pegawai </label>
            <input
              type="text"
              v-model="pegawai.nip"
              disabled>
          </div>
          <div class="form-group">
            <label> Nomor induk kependudukan </label>
            <input
              type="text"
              v-model="pegawai.nik"
              disabled>
          </div>
          <div class="form-group">
            <label>
              NPWP
            </label>
            <input
              type="text"
              v-model="pegawai.npwp"
              disabled>
          </div>
          <div class="form-group">
            <label>Jabatan</label>
            <input type="text" v-model="pegawai.jabatan" disabled>
          </div>
          <div class="form-group">
            <label>
              Tunjangan jabatan
            </label>
            <input
              type="text"
              v-model="pegawai.tunjangan_jabatan"
              disabled>
          </div>
          <div class="form-group">
            <label>
              Gaji pokok
            </label>
            <input
            type="text"
            v-model="pegawai.gaji_pokok"
            disabled>
          </div>
          <div class="form-group">
            <label>Jenis Kelamin </label>
            <input type="text" v-model="pegawai.jenisKelaminString" disabled>
          </div>
          <div class="form-group">
            <label>Tempat lahir</label>
            <input
              type="text"
              v-model="pegawai.tempat_lahir"
              disabled>
          </div>
          <div class="form-group">
            <label>Tanggal lahir</label>
            <input type="text" :value="dateFormat(pegawai.tanggal_lahir)" disabled>
          </div>
          <div class="form-group">
            <label>Kewarganegaraan</label>
            <input
              type="text"
              v-model="pegawai.kewarganegaraan"
              disabled>
          </div>
          <div class="form-group">
            <label>Agama</label>
            <input type="text" v-model="pegawai.agama" disabled>
          </div>
          <div class="form-group">
            <label>Alamat lengkap</label>
            <input
              type="text"
              v-model="pegawai.alamat"
              disabled>
          </div>
          <div class="form-group">
              <label/>
              <div class="flex flex-column gap-16">
                <div class="form-group">
                  <label>Kelurahan/desa</label>
                  <input v-model="pegawai.desa" disabled>
                </div>
                <div class="form-group">
                  <label>Kecamatan</label>
                  <input v-model="pegawai.kecamatan" disabled>
                </div>
                <div class="form-group">
                  <label>Kabupaten/kota</label>
                  <input v-model="pegawai.kota" disabled>
                </div>
                <div class="form-group">
                  <label>provinsi</label>
                  <input v-model="pegawai.provinsi" disabled>
                </div>
                <div class="form-group">
                  <label>Kode pos</label>
                  <input v-model="pegawai.kodepos" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
            <label>E-mail</label>
            <input v-model="pegawai.email" disabled>
          </div>
          <div class="form-group">
            <label>No. handphone</label>
            <input v-model="pegawai.no_telp" disabled>
          </div>
          <div class="form-group">
            <label>No. rekening</label>
            <input v-model="pegawai.no_rekening" disabled>
          </div>
          <div class="form-group">
            <label>No. BPJS</label>
            <input v-model="pegawai.no_bpjs" disabled>
          </div>
          <div class="form-group">
            <label>BPJS</label>
            <input v-model="pegawai.bpjs" disabled>
          </div>
          <div class="form-group">
            <label>PPH21</label>
            <input v-model="pegawai.pph21" disabled>
          </div>
          <div class="form-group">
            <label>Lain-lain</label>
            <input v-model="pegawai.lainnya" disabled>
          </div>
          </el-tab-pane>
          <el-tab-pane label="Detil presensi" name="presensi" class="flex flex-column gap-16 view-pegawai">
            <table-pegawai-absensi
              :id="$route.params.id"/>
          </el-tab-pane>
          <el-tab-pane label="Detil gaji" name="gaji"  class="flex flex-column gap-16 view-pegawai">
            <table-pegawai-rekap-gaji
              :id="$route.params.id"/>
          </el-tab-pane>
        </el-tabs>
      </aside>
    </div>
  </LayoutMain>
</template>
  
<script>
import 'dayjs/locale/id'
import dayjs from 'dayjs'
import LayoutMain from '../layout/LayoutMain.vue';
import TablePegawaiAbsensi from '@/components/components/TablePegawaiAbsensi.vue'
import TablePegawaiRekapGaji from '@/components/components/TablePegawaiRekapGaji.vue'

export default {
  name: "ViewPgawai",
  components: {
    LayoutMain,
    TablePegawaiAbsensi,
    TablePegawaiRekapGaji,
  },
  data() {
    return {
      pegawai: {},
      activeName: 'biodata',
    }
  },
  
  mounted() {
    const param = {
      "id":this.$route.params.id,
    }
    this.$http.post('v1/pegawai/detilpegawai', JSON.stringify(param))
      .then((res) => {
        this.pegawai = res.data.data
      })
      .catch((err) => {
        console.log(err)
      });
  },
  methods: {
    dateFormat(date) {
      return dayjs(date).locale('id').format('DD-MMMM-YYYY')
    },
  },
}
</script>