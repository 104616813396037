<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="back">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          {{ header }}
        </b>
        <div class="body">
          <label class="title">Biodata pegawai</label>
          <div class="form-group">
            <label>Photo profile</label>
            <div class="flex">
              <div class="avatar">
                <Camera
                  v-if="!img"
                  class="fa" />
                <img
                  v-else
                  :src="img">
              </div>
              <a class="link" @click="$refs.avatar.click()">Ubah foto</a>
              <input
                ref="avatar"
                type="file"
                class="none"
                accept="image/png,image/jpg,image/jpeg"
                @change="onFileChange">
            </div>
          </div>
          <div class="form-group">
            <label>
              Nama lengkap
              <i class="required ml-6">*</i>
            </label>
            <input
              type="text"
              v-model="pegawai.nama_lengkap"
              maxlength="190">
          </div>
          <div class="form-group">
            <label>Nama panggilan</label>
            <input
              type="text"
              v-model="pegawai.nama_panggilan"
              maxlength="190">
          </div>
          <div class="form-group">
            <label>
              Nomor induk pegawai
              <i class="required ml-6">*</i>
            </label>
            <input
              type="text"
              v-model="pegawai.nip"
              placeholder="NIP"
              maxlength="25">
          </div>
          <div class="form-group">
            <label>
              Nomor induk kependudukan
              <i class="required ml-6">*</i>
            </label>
            <input
              type="text"
              v-model="pegawai.nik"
              placeholder="NIK"
              @keydown="inputNumber"
              maxlength="18">
          </div>
          <div class="form-group">
            <label>
              NPWP
              <i class="required ml-6">*</i>
            </label>
            <input
              type="text"
              v-model="pegawai.npwp"
              placeholder="NPWP"
              maxlength="25">
          </div>
          <div class="form-group">
            <label>Jabatan<i class="required ml-6">*</i></label>
            <el-select v-model="pegawai.jabatan">
              <el-option
                v-for="item in jabatanArray"
                :key="item.id_jabatan"
                :value="item.id_jabatan"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>
              Tunjangan jabatan
              <i class="required ml-6">*</i>
            </label>
            <input
              type="text"
              v-model="pegawai.tunjangan_jabatan"
              placeholder="Rp."
              @keydown="inputNumber"
              maxlength="10">
          </div>
          <div class="form-group">
            <label>
              Gaji pokok
              <i class="required ml-6">*</i>
            </label>
            <input
            type="text"
            v-model="pegawai.gaji_pokok"
            placeholder="Rp."
            @keydown="inputNumber"
            maxlength="10">
          </div>
          <div class="form-group">
            <label>Jenis Kelamin<i class="required ml-6">*</i></label>
            <el-select v-model="pegawai.jenis_kelamin">
              <el-option
                v-for="item in jenisKelaminArray"
                :key="item.id"
                :value="item.id"
                :label="item.label"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Tempat lahir<i class="required ml-6">*</i></label>
            <input
              type="text"
              v-model="pegawai.tempat_lahir"
              placeholder="Tempat lahir"
              maxlength="190">
          </div>
          <div class="form-group">
            <label>Tanggal lahir<i class="required ml-6">*</i></label>
            <el-date-picker
              v-model="pegawai.tanggal_lahir"
              type="date"
              placeholder="Pilih tanggal"/>
          </div>
          <div class="form-group">
            <label>Kewarganegaraan<i class="required ml-6">*</i></label>
            <input
              type="text"
              v-model="pegawai.kewarganegaraan"
              placeholder="WNI"
              maxlength="100">
          </div>
          <div class="form-group">
            <label>Agama<i class="required ml-6">*</i></label>
            <el-select v-model="pegawai.agama" placeholder="Pilih">
              <el-option
                v-for="item in agamaArray"
                :key="item.id_agama"
                :value="item.id_agama"
                :label="item.keterangan"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>Alamat lengkap<i class="required ml-6">*</i></label>
            <input
              type="text"
              v-model="pegawai.alamat"
              placeholder="Alamat lengkap">
          </div>
          <div class="form-group">
            <label/>
            <div class="flex flex-column gap-16">
              <div class="form-group">
                <label>Kelurahan/desa</label>
                <input
                  type="text"
                  v-model="pegawai.desa"
                  placeholder="Desa/kelurahan"
                  maxlength="190">
              </div>
              <div class="form-group">
                <label>Kecamatan</label>
                <input
                  type="text"
                  v-model="pegawai.kecamatan"
                  placeholder="Kecamatan"
                  maxlength="190">
              </div>
              <div class="form-group">
                <label>Kabupaten/kota</label>
                <input
                  type="text"
                  v-model="pegawai.kota"
                  placeholder="Kabupaten/kota"
                  maxlength="190">
              </div>
              <div class="form-group">
                <label>Provinsi</label>
                <input
                  type="text"
                  v-model="pegawai.provinsi"
                  placeholder="Provinsi"
                  maxlength="190">
              </div>
              <div class="form-group">
                <label>Kode pos</label>
                <input
                  type="text"
                  v-model="pegawai.kodepos"
                  placeholder="Kode pos"
                  maxlength="8">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>E-mail</label>
            <input
              type="email"
              v-model="pegawai.email"
              placeholder="contoh@email.com"
              maxlength="190">
          </div>
          <div class="form-group">
            <label>No. handphone</label>
            <input
              type="text"
              v-model="pegawai.no_telp"
              placeholder="No. hp"
              @keydown="inputNumber"
              maxlength="16">
          </div>
          <div class="form-group">
            <label>No. rekening</label>
            <input
              type="text"
              v-model="pegawai.no_rekening"
              placeholder="No. rek"
              @keydown="inputNumber"
              maxlength="20">
          </div>
          <div class="form-group">
            <label>No. BPJS</label>
            <input
              type="text"
              v-model="pegawai.no_bpjs"
              placeholder="No. bpjs"
              maxlength="20">
          </div>
          <div class="form-group">
            <label>BPJS</label>
            <input
            type="text"
            v-model="pegawai.bpjs"
            placeholder="Rp."
            @keydown="inputNumber"
            maxlength="8">
          </div>
          <div class="form-group">
            <label>PPH21</label>
            <input
            type="text"
            v-model="pegawai.pph21"
            placeholder="Rp."
            @keydown="inputNumber"
            maxlength="8">
          </div>
          <div class="form-group">
            <label>Lain-lain</label>
            <input
            type="text"
            v-model="pegawai.lainnya"
            placeholder="Rp."
            @keydown="inputNumber"
            maxlength="8">
          </div>
        </div>
        <div class="footer flex gap-16">
          <button class="btn danger" @click="back">Batal</button>
          <button class="btn primary" @click="save">Simpan</button>
        </div>
      </div>
      <!-- <div class="aside">
        <button class="btn link"  @click="get">Import CSV</button>
        {{ 'Unduh template file' }}
        <router-link to="#">Di sini</router-link>
      </div> -->
    </div>
  </LayoutMain>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import { jenisKelamin } from '@/constant'
  import helpers from '@/lib/helper'
  import { ElMessage } from 'element-plus'

  export default {
    name: 'FormPegawai',
    components: { LayoutMain },

    data() {
      return {
        jenisKelaminArray: jenisKelamin,
        img: null,
        pegawai: {
          nama_lengkap: "",
          nama_panggilan: "",
          nip: "",
          nik: "",
          npwp: "",
          jabatan: null,
          jenis_kelamin: "",
          tempat_lahir: "",
          tanggal_lahir: null,
          kewarganegaraan: "",
          agama: null,
          alamat: "",
          desa: "",
          kecamatan: "",
          kota: "",
          provinsi: "",
          kodepos: "",
          email: "",
          no_telp: "",
          no_rekening: "",
          no_bpjs: "",
          tunjangan_jabatan: null,
          gaji_pokok: null,
          bpjs: null,
          pph21: null,
          lainnya: null,
          photo:"",
        },
        route: this.$route.params.id,
      }
    },
    computed: {
      ...mapGetters({
        agamaArray: 'agama',
        jabatanArray: 'jabatan',
      }),
      header() {
        return this.route ? 'Edit pegawai' : 'Tambah pegawai'
      },
    },
    mounted() {
      if(this.route) {
        this.$http.post('v1/pegawai/detilpegawai', JSON.stringify({"id":this.route}))
        .then((res) => {
          this.pegawai = res.data.data
          this.img =  res.data.data.avatar
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
    methods: {
      back() {
        return this.$router.push({ path: '/kepegawaian', query: { tab: 'pegawai' } })
      },
      inputNumber($event) {
        helpers.numberOnly($event)
      },
      async save() {
        const data = {
          "id": this.route ?this.route : null,
          "nama_lengkap": this.pegawai.nama_lengkap,
          "nama_panggilan": this.pegawai.nama_panggilan,
          "nip": this.pegawai.nip,
          "nik": this.pegawai.nik,
          "npwp": this.pegawai.npwp,
          "jabatan": this.pegawai.jabatan,
          "tunjangan_jabatan": this.pegawai.tunjangan_jabatan,
          "gaji_pokok": this.pegawai.gaji_pokok,
          "jenis_kelamin": this.pegawai.jenis_kelamin,
          "tempat_lahir": this.pegawai.tempat_lahir,
          "tanggal_lahir": this.pegawai.tanggal_lahir,
          "kewarganegaraan": this.pegawai.kewarganegaraan,
          "agama": this.pegawai.agama,
          "alamat": this.pegawai.alamat,
          "desa": this.pegawai.desa,
          "kecamatan": this.pegawai.kecamatan,
          "kota": this.pegawai.kota,
          "provinsi": this.pegawai.provinsi,
          "kodepos": this.pegawai.kodepos,
          "email": this.pegawai.email,
          "no_telp": this.pegawai.no_telp,
          "no_rekening": this.pegawai.no_rekening,
          "no_bpjs": this.pegawai.no_bpjs,
          "bpjs": this.pegawai.bpjs,
          "pph21": this.pegawai.pph21,
          "lainnya": this.pegawai.lainnya,
          "photo": this.pegawai.photo
        }
        if(!this.route) {
          this.$http.put('v1/pegawai/index', JSON.stringify(data))
          .then(() => {
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
            this.back()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: 'Gagal simpan, '+ err.response.data.message,
              type: 'error',
            })
          });
        } else {
          this.$http.patch('v1/pegawai/index', JSON.stringify(data))
          .then(() => {
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
            this.back()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: 'Gagal simpan, '+ err.message,
              type: 'error',
            })
          });
        }
      },
      onFileChange(e) {
        const image = e.target.files[0];
        this.img = URL.createObjectURL(image);
        this.$http.post(
          'v1/attachment/upload',
          { "UploadFile":image },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        )
        .then((res) => {
          this.pegawai.photo = res.data ? res.data.data : null;
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            message: err,
            type: 'error',
          })
        });
      }
    },
  }
</script>