<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="$router.go(-1)">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          Mulai tahun ajaran baru
        </b>
        <div class="body">
          <p>
            Siswa umumnya naik kelas secara otomatis pada awal tahun ajaran baru. Namun, jika ada 
            siswa yang tinggal kelas, admin sekolah dapat menambahkannya. Ini bertujuan untuk 
            memberikan kesempatan pada siswa untuk berkembang. Data siswa harus dikelola dengan 
            baik untuk memastikan kebijakan ini berjalan dengan lancar.</p>
          <button class="btn link" @click="openModal">Tambah siswa tinggal kelas</button>
          <table v-if="siswaTerpilih.length">
            <thead>
              <tr>
                <th v-for="header in header" :key="header">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(siswa, index) in siswaTerpilih"
                :key="siswa.id">
                <td >{{ siswa.nis }}</td>
                <td class="single-line pt-24">{{ siswa.nama_lengkap }}</td>
                <td>{{ siswa.kelas }}</td>
                <td> <button class="btn danger w-100" @click="hapusSiswa(index)">Hapus</button></td>
              </tr>
              <tr>
                <td colspan="6" class="text-center">
                  <span
                    v-if="siswa.length"
                    class="pagination">
                    <button
                      v-if="page > 1"
                      @click="getDataPrevious"
                      class="btn link"> 
                        <ArrowLeft class="fa"/>
                        Sebelumnya
                      </button>
                      <button
                        v-if="nextPage"
                        @click="getDataNext"
                      class="btn link">
                        Selanjutnya
                        <ArrowRight class="fa"/> 
                      </button>
                  </span>
                  <span v-else>
                    Tidak ada data
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footer gap-16">
          <button class="btn danger" @click="$router.go(-1)">Batal</button>
          <button class="btn primary" @click="showConfirm">Mulai tahun ajaran baru</button>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      width="80vh">
      <span
        v-if="!isConfirm"
        class="form">
        <div class="input-suffix w-50">
          <input
            v-model="searchTerm"
            placeholder="Cari siswa / NIS"
            @keyup="getDataSearch">
          <Search class="fa"/>
        </div> 
        <table>
          <thead>
            <tr>
              <th v-for="header in header" :key="header">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="siswa in siswa"
              :key="siswa.id">
              <td >{{ siswa.nis }}</td>
              <td class="single-line pt-24">{{ siswa.nama_lengkap }}</td>
              <td>{{ siswa.kelas }}</td>
              <td> <button class="btn primary w-100" @click="pilihSiswa(siswa)">Pilih</button></td>
            </tr>
            <tr>
              <td colspan="6" class="text-center">
                <span
                  v-if="siswa.length"
                  class="pagination">
                  <button
                    v-if="page > 1"
                    @click="getDataPrevious"
                    class="btn link"> 
                      <ArrowLeft class="fa"/>
                      Sebelumnya
                    </button>
                    <button
                      v-if="nextPage"
                      @click="getDataNext"
                    class="btn link">
                      Selanjutnya
                      <ArrowRight class="fa"/> 
                    </button>
                </span>
                <span v-else>
                  Tidak ada data
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
      <span v-else>
        Apakah Anda yakin ingin memulai tahun ajaran baru dengan data siswa yang tinggal kelas ini? 
        Pastikan keputusan ini telah didiskusikan dan disetujui oleh pihak sekolah terlebih dahulu.
      </span>
      <template 
        v-if="!isConfirm"
        #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn danger" @click="dialogVisible = false">Batal</button>
        </span>
      </template>
      <template 
        v-else
        #footer>
        <span class="dialog-footer flex gap-24 end">
          <button class="btn primary" @click="dialogVisible = false">Batal</button>
          <button class="btn danger" @click="save">Simpan</button>
        </span>
      </template>
    </el-dialog>
  </LayoutMain>
</template>
  
<script>
  import LayoutMain from '@/components/layout/LayoutMain.vue'

  export default {
    name: 'FormStartTahunAjaran',
    components: { LayoutMain },

    data() {
      return {
        dialogVisible: false,
        siswa_tinggal_kelas: [],
        page: 1,
        nextPage: null,
        searchTerm: '',
        header: ['Nomor induk siswa', 'Nama lengkap', 'Kelas saat ini', ''],
        siswaTerpilih: [],
        siswa: [],
        isConfirm: false,
        title: 'Tambah siswa tinggal kelas',
      }
    },
    methods: {
      showConfirm() {
        this.isConfirm = true
        this.dialogVisible = true
        this.title = 'Konfirmasi'
      },
      openModal() {
        this.searchTerm = ''
        this.isConfirm = false
        this.dialogVisible = true
        this.getDataSiswa()
      },
      pilihSiswa(item) {
        this.siswaTerpilih.push({
          id: item.id,
          nis: item.nis,
          nama_lengkap: item.nama_lengkap,
          kelas: item.kelas
        })
        this.siswa = this.siswa.filter(item => !this.siswaTerpilih.map(x => x.id).includes(item.id))
        this.dialogVisible = false
      },
      hapusSiswa(index) {
        this.siswaTerpilih.splice(index, 1)
      },
      async save() {
        const data = {
          "id_siswa_tl": this.siswaTerpilih.map(item => item.id)
          }
        this.$http.post('v1/configtahunajaran/start', JSON.stringify(data))
        .then(() => {
          this.$router.push({ path: 'tahun-ajaran' })
        })
        .catch((err) => {
          console.log(err)
        });
      },
      getDataPrevious() {
        this.page -= 1;
        this.getDataSiswa();
      },
      getDataNext() {
        this.page += 1;
        this.getDataSiswa();
      },
      getDataSearch() {
        this.page = 1;
        this.getDataSiswa();
      },
      getDataSiswa() {
        const param = {
          "q":this.searchTerm,
          "status":null,
          "kelas":null,
          "page":this.page
        }
        this.$http.post('v1/siswa/index', JSON.stringify(param))
          .then((res) => {
            this.siswa = res.data.data;
            this.siswa = this.siswa.filter(item => !this.siswaTerpilih.map(x => x.id).includes(item.id))
            this.nextPage = res.data.data.length == 15;
          })
          .catch((err) => {
            console.log(err)
          });
      },
    },
  }
</script>