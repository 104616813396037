<template>
    <div class="flex gap-16 m-tb-8 w-100">
        <div class="input-suffix">
            <input type="text" v-model="searchTerm" placeholder="Cari siswa / NIS" @keyup="getDataSearch">
            <Search class="fa"/>
        </div>
        <el-select v-model="selectKategori" placeholder="Kategori">
            <el-option
                v-for="item in kategoriPembayaran"
                :key="item.id"
                :label="item.label"
                :value="item.id"/>
        </el-select>
        <el-select v-model="selectBulan" placeholder="Bulan">
            <el-option
                v-for="item in bulan"
                :key="item.id"
                :label="item.label"
                :value="item.id"/>
        </el-select>
        <el-select v-model="selectStatus" placeholder="Status">
            <el-option
                v-for="item in statusPembayaran"
                :key="item.id"
                :label="item.label"
                :value="item.id"/>
        </el-select>
        <button class="btn reset" @click="reset">Atur ulang</button>
    </div>
    <table>
        <thead>
            <tr>
                <th v-for="header in header" :key="header">{{ header }}</th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="pembayaran in pembayaran"
                :key="pembayaran.nip"
                class="text-link"
                @click="gotoDetile(pembayaran.id)">
                <td class="single-line">{{ pembayaran.id }}</td>
                <td> {{ pembayaran.namaSiswa }} </td>
                <td>{{ pembayaran.nis }}</td>
                <td>{{ pembayaran.bank }}</td> 
                <td>{{ currencyFormat(pembayaran.nominal) }}</td>
                <td>{{ pembayaran.statusString }}</td>
            </tr>
      <tr>
        <td colspan="6" class="text-center">
          <span
            v-if="pembayaran.length"
            class="pagination">
            <button
              v-if="page > 1"
              @click="getDataPrevious"
              class="btn link"> 
                <ArrowLeft class="fa"/>
                Sebelumnya
              </button>
              <button
                v-if="nextPage"
                @click="getDataNext"
               class="btn link">
                Selanjutnya
                <ArrowRight class="fa"/> 
              </button>
          </span>
          <span v-else>
            Tidak ada data
          </span>
        </td>
      </tr>
        </tbody>
    </table>
</template>
    
<script>
  //   import axios
import { bulan, kategoriPembayaran, statusPembayaran } from '@/constant'
export default {
    name: 'TableVerifikasiPembayaran',
    props: {

    },

    components: {  },
    data() {
    return {
        selectKategori: null,
        selectBulan: null,
        selectStatus: null,
        searchTerm: '',
        bulan: bulan,
        kategoriPembayaran: kategoriPembayaran,
        statusPembayaran: statusPembayaran,
        jenisPegawai: [
        {
            value: 1,
            label: 'Guru'
        },
        {
            value: 2,
            label: 'Pegawai'
        },
        {
            value: 3,
            label: 'Staf'
        },
        {
            value: 4,
            label: 'Maggang'
        }
        ],
        header: ['No.Transaksi','Nama lengkap', 'NIS', 'Nama Bank', 'Jumlah', 'Status'],
        pembayaran: [],
        nextPage: false,
        page: 1,
        searchStatus: null,
    }
    },

    mounted() {
    this.getData();
    },

    methods: { 
        getDataPrevious() {
        this.page -= 1;
        this.getData();
      },
      getDataNext() {
        this.page += 1;
        this.getData();
      },
      getDataSearch() {
        this.page = 1;
        this.getData();
      },
      gotoDetile(param) {
        this.$router.push({ name: 'FormKonfirmasiPembayaran', params: { id:param } })
      },
      getData() {
        const param = {
        "q":this.searchTerm,
        "status":this.searchStatus,
        "kelas":this.searchKelas,
        "page":this.page
      }
      this.$http.post('v1/pembayaran/listverifikasipembayaran', JSON.stringify(param))
        .then((res) => {
          this.pembayaran = res.data.data
          this.nextPage = res.data.data.length == 15;
        })
        .catch((err) => {
          console.log(err)
        });
      },
      reset() {
        this.selectKategori = null;
        this.selectBulan = null;
        this.selectStatus = null;
        this.searchTerm = '';
      },
      currencyFormat(price) {
        return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR"}).format(price)
      }
    },
}
</script>