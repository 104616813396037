<template>
    <div class="layout">
        <left-menu/>
        <div class="main">
            <top-menu/>
            <div class="main-content">
                <slot/>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import LeftMenu from '@/components/layout/LeftMenu.vue';
  import TopMenu from '@/components/layout/TopMenu.vue';

  export default {
    name: 'LayoutMain',

  components: {
    LeftMenu,
    TopMenu 
  },
  }
  </script>