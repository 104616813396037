<template>
  <LayoutMain>
    <div>
      <button class="btn link distance" @click="$router.go(-1)">
        <Back class="fa" />
        {{ "Kembali" }}
      </button>
      <div class="box gap-32 p-32">
        <div class="flex between">
          <div class="card profile">
            <img class="avatar" :src="`${admin.avatar}`" />
            <div class="info">
              <span class="title single-line">{{ admin.nama }}</span>
              <span class="desc">{{ admin.email }}</span>
              <span class="btn">Admin</span>
            </div>
          </div>
          <!-- <button v-if="activeName == 'admin'" class="btn link">Ubah data admin</button> -->
          <router-link :to="{ name: 'FormAkunAdminEdit' }" class="btn link p-16"
            >Ubah data admin</router-link
          >
        </div>
        <aside>
          <el-tabs v-model="activeName">
            <el-tab-pane
              label="Biodata admin"
              name="admin"
              class="flex flex-column gap-16"
            >
              <div class="form-group">
                <label>Nama</label>
                <input v-model="admin.nama" disabled />
              </div>
              <div class="form-group">
                <label>Email</label>
                <input v-model="admin.email" disabled />
              </div>
              <div class="form-group">
                <label>No. Handphone</label>
                <input v-model="admin.no_hp" disabled />
              </div>
              <div class="form-group">
                <label>Alamat lengkap</label>
                <input v-model="admin.alamat" disabled />
              </div>
              <div class="form-group">
                <label />
                <div class="flex flex-column gap-16">
                  <div class="form-group">
                    <label>Kelurahan/desa</label>
                    <input v-model="admin.kelurahan" disabled />
                  </div>
                  <div class="form-group">
                    <label>Kecamatan</label>
                    <input v-model="admin.kecamatan" disabled />
                  </div>
                  <div class="form-group">
                    <label>Kabupaten/kota</label>
                    <input v-model="admin.kota" disabled />
                  </div>
                  <div class="form-group">
                    <label>provinsi</label>
                    <input v-model="admin.provinsi" disabled />
                  </div>
                  <div class="form-group">
                    <label>Kode pos</label>
                    <input v-model="admin.kode_pos" disabled />
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="Ubah kata sandi"
              name="sandi"
              class="flex flex-column gap-16"
            >
              <div class="form-group">
                <label>Kata sandi baru</label>
                <input v-model="password" type="password" placeholder="Kata sandi baru" />
              </div>
              <div class="form-group">
                <label>Ulangi kata sandi baru</label>
                <input v-model="repassword"  type="password"  placeholder="Kata sandi baru" />
              </div>
              <div class="footer gap-24">
                <button class="btn primary" @click="ubahSandi()">
                  Simpan
                </button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </aside>
      </div>
    </div>
  </LayoutMain>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutMain from "../layout/LayoutMain.vue";
import { jenisKelamin } from "@/constant";
import { ElMessage } from 'element-plus'

export default {
  name: "ViewAdminSekolah",
  components: { LayoutMain },
  data() {
    return {
      jenisKelaminArray: jenisKelamin,
      activeName: "admin",
      admin: [],
      password: "",
      repassword: "",
    };
  },
  computed: {
    ...mapGetters({
      agamaArray: "agama",
      kategoriWali: "kategoriWali",
      kelasArray: "kelas",
    }),
  },
  mounted() {
    const param = {
      id: this.$route.params.id,
    };
    this.$http
      .post("v1/account/detiladmin", JSON.stringify(param))
      .then((res) => {
        // console.log(res.data);
        this.admin = res.data.admin;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    ubahSandi() {
      if( this.password=== this.repassword){
      this.$http
        .post("v1/account/ubahsandi", {
          password: this.password,
          id: this.$route.params.id,
        })
        .then(() => {
          ElMessage({
              showClose: true,
              message: 'Password berhasil di ubah',
              type: 'success',
            })
            this.password='';
            this.repassword='';
        })
        .catch((err) => {
          console.log(err);
        });
      }else{
        ElMessage({
              showClose: true,
              message: 'Anda harus memasukan password yang sama.  ',
              type: 'error',
            });
            this.password='';
            this.repassword='';
      }
    },
  },
};
</script>
