<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <router-view :key="$route.path"/>
    </div>
  </el-config-provider>
</template>

<script>

import { ElConfigProvider } from 'element-plus'
import indonesia from '@/lib/locale/id.mjs'

export default {
  name: 'App',
  components: {
      ElConfigProvider,
    },
  setup() {
    return {
      locale: indonesia,
    }
  },
  created() {
    if (localStorage.getItem("token") !== 'null') {
      this.$store.dispatch('reload');
    }
  },
}
</script>