<template>
    <div class="top-menu">
      <div class="content">
        <div class="auth-dropdown">
          <img class="avatar" src="/svg/user.svg"/> Admin
          <ArrowDown class="fa" @click="dropdownMenu = !dropdownMenu"/>
            <div
              v-if="dropdownMenu"
              class="dropdown">
              <button class="btn" @click.prevent="logout"> Logout</button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    name: "TopMenu",
    props: {
        msg: String
    },
    data: () => ({
        dropdownMenu: false,
    }),
    methods: {
      
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push(`/login`);
      });
    }
    },
}
  </script>