<template>
  <LayoutMain>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Jenis pembayaran" name="jenis_pembayaran" class="flex flex-column gap-16">
        <konfig-jenis-pembayaran/>
      </el-tab-pane>
      <el-tab-pane label="Kategori wali" name="kategori_wali" class="flex flex-column gap-16">
        <konfig-kategori-wali/>
      </el-tab-pane>
      <el-tab-pane label="Kelas" name="kelas" class="flex flex-column gap-16">
        <konfig-kelas/>
      </el-tab-pane>
      <!-- <el-tab-pane label="Agama" name="agama" class="flex flex-column gap-16">
        <konfig-agama/>
      </el-tab-pane> -->
      <el-tab-pane label="Jabatan" name="jabatan" class="flex flex-column gap-16">
        <konfig-jabatan/>
      </el-tab-pane>
    </el-tabs>
  </LayoutMain>
  </template>
  
<script>
import LayoutMain from '@/components/layout/LayoutMain.vue';
import KonfigKelas from '@/components/components/KonfigKelas'
// import KonfigAgama from '@/components/components/KonfigAgama'
import KonfigKategoriWali from '@/components/components/KonfigKategoriWali'
import KonfigJenisPembayaran from '@/components/components/KonfigJenisPembayaran'
import KonfigJabatan from '@/components/components/KonfigJabatan'

export default {
  name: "KonfigurasiMasterDataPage",
  components: {
    LayoutMain,
    KonfigKelas,
    // KonfigAgama,
    KonfigKategoriWali,
    KonfigJenisPembayaran,
    KonfigJabatan
  },
  data() {
    return {
      activeName: 'jenis_pembayaran',
    }
  },
}
</script>