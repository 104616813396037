<template>
  <div class="flex gap-16 m-tb-8 w-100">
    <div class="input-suffix">
      <input
        v-model="searchTerm"
        placeholder="Cari tahun ajaran"
        @keyup="getDataSearch">
      <Search class="fa"/>
    </div> 
    <button class="btn reset" @click="reset">Atur ulang</button>
    <router-link
      :to="canAddTagihan ? { name: 'FormStartTahunAjaran' }: ''"
      :class="!canAddTagihan?'disabled': ''"
      class="btn link right p-16"
      :disabled="!canAddTagihan">Mulai tahun ajaran baru</router-link>
    </div>
  <table>
    <thead>
      <tr>
        <th v-for="header in header" :key="header">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in tahun_ajaran"
        :key="item.id_tahun_ajaran">
        <td >{{ item.deskripsi }}</td>
        <td :class="getStatusClass(item.status)">{{ item.statusString }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-center">
          <span
            v-if="tahun_ajaran.length"
            class="pagination">
            <button
              v-if="page > 1"
              @click="getDataPrevious"
              class="btn link"> 
                <ArrowLeft class="fa"/>
                Sebelumnya
              </button>
              <button
                v-if="nextPage"
                @click="getDataNext"
               class="btn link">
                Selanjutnya
                <ArrowRight class="fa"/> 
              </button>
          </span>
          <span v-else>
            Tidak ada data
          </span>
        </td>
      </tr>
    </tbody>
    <tfoot>
    </tfoot>
  </table>
</template>
    
<script>
  export default {
    name: 'TableTahunAjaran',
    props: {
      canAddTagihan: {
        Type: Boolean,
        require: false,
        default: false,
      }
    },
    data() {
      return {
        searchTerm: '',
        header: ['Tahun ajaran', 'Status'],
        tahun_ajaran: [],
        nextPage: false,
        page: 1,
      }
    },

    mounted() {
      this.getData();
    },

    methods: {
      getStatusClass(val) {
        let className = ''
        switch(val) {
          case 0:
            className = 'danger'
            break
          case 1:
            className = 'warning'
            break
          default:
            className = 'success'
            break
        }
        return className
      },
      getDataPrevious() {
        this.page -= 1;
        this.getData();
      },
      getDataNext() {
        this.page += 1;
        this.getData();
      },
      getDataSearch() {
        this.page = 1;
        this.getData();
      },
      getData() {
        const param = {
          'q': this.searchTerm
        }
        this.$http.post('v1/configtahunajaran/index', param)
        .then((res) => {
          this.tahun_ajaran = res.data.data
        })
        .catch((err) => {
          console.log(err)
        });
      },
      reset() {
        this.page = 1;
        this.searchTerm = '';
        this.searchKelas = null;
        this.searchStatus = null;
        this.getData();
        },
    },
  }
</script>