<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="back">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="box pengumuman">
      <div class="flex between">
        <b class="title">{{ pengumuman.judul }}</b>
        <button
          class="text-red pointer"
          @click="deletePengumuman(pengumuman.id)">Hapus</button>
      </div>
      <p>{{ pengumuman.description }}</p>
      <span v-for="file in pengumuman.files" :key="file.id">
        <img v-if="file.extensi !== 'pdf'" :src="`${file.url}`">
        <div v-else>
          <a :href="file.url" target="_blank">{{ file.nama }}</a>
        </div>
      </span>
    </div>
  </LayoutMain>
</template>
  
<script>
import LayoutMain from '@/components/layout/LayoutMain.vue'

export default {
  name: "ViewPengumuman",
  components: { LayoutMain },
  data() {
    return {
      pengumuman: {},
    }
  },
  created() {
    const param = {
        "id":this.$route.params.id,
      }
      this.$http.post('v1/pengumuman/detilpengumuman', JSON.stringify(param))
        .then((res) => {
          this.pengumuman = res.data.data
        })
        .catch((err) => {
          console.log(err)
        });
  },
  methods: {
      back() {
        return this.$router.push({ path: '/pengumuman' })
      },
      deletePengumuman(id) {
        console.log('delete')
        this.$http.delete(`v1/pengumuman/index?id=${id}`)
          .then(() => {
            this.back()
          })
          .catch((err) => {
            console.log(err)
          });
      }
  }
}
</script>