import { createRouter, createWebHistory } from 'vue-router'
import Login from './components/auth/Login.vue'
import ResetPassword from './components/auth/ResetPassword.vue'
import Home from './components/pages/Dashboard.vue'
import Kepegawaian from './components/pages/Kepegawaian.vue'
import Kesiswaan from './components/pages/Kesiswaan.vue'
import Pembayaran from './components/pages/Pembayaran.vue'
import Keuangan from './components/pages/Keuangan.vue'
import Laporan from './components/pages/Laporan.vue'
import Pengumuman from './components/pages/Pengumuman.vue'
import FormSiswa from './components/pages/FormSiswa.vue'
import FormPegawai from './components/pages/FormPegawai.vue'
import FormSekolah from './components/pages/FormSekolah.vue'
import FormPengumuman from './components/pages/FormPengumuman.vue'
import FormTagihanSiswa from './components/pages/FormTagihanSiswa.vue'
import FormPegawaiAbsen from './components/pages/FormPegawaiAbsen.vue'
import FormPegawaiGaji from './components/pages/FormPegawaiGaji.vue'
import FormPembayaran from './components/pages/FormPembayaran.vue'
import FormKonfirmasiPembayaran from './components/pages/FormKonfirmasiPembayaran.vue'
import FormAngsuran from './components/pages/FormAngsuran.vue'
import FormStartTahunAjaran from './components/pages/FormStartTahunAjaran.vue'
import FormAkunWali from './components/pages/FormAkunWali.vue'
import ViewSiswa from './components/pages/ViewSiswa.vue'
import ViewAdminSekolah from './components/pages/ViewAdminSekolah.vue'
import ViewPegawai from './components/pages/ViewPegawai.vue'
import ViewPengumuman from './components/pages/ViewPengumuman.vue'
import KonfigurasiUmum from './components/pages/Konfigurasi.vue'
import KonfigurasiMasterData from './components/pages/KonfigurasiMasterData.vue'
import KonfigurasiSekolah from './components/pages/KonfigurasiSekolah.vue'
import KonfigurasiAkun from './components/pages/KonfigurasiAkun.vue'
import KonfigurasiAkunAdmin from './components/pages/KonfigurasiAkunAdmin.vue'
import KonfigurasiAkunPegawai from './components/pages/KonfigurasiAkunPegawai.vue'
import FormAkunAdmin from './components/pages/FormAkunAdmin.vue'
import FormAkunPegawai from './components/pages/FormAkunPegawai.vue'
// import store from './store.js'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kepegawaian',
    name: 'Kepegawaian',
    component: Kepegawaian
  },
  {
    path: '/kepegawaian/pegawai-add',
    name: 'FormPegawai',
    component: FormPegawai
  },
  {
    path: '/kepegawaian/pegawai-edit/:id',
    name: 'FormPegawaiEdit',
    component: FormPegawai
  },
  {
    path: '/kepegawaian/view/:id',
    name: 'ViewPegawai',
    component: ViewPegawai
  },
  {
    path: '/kepegawaian/absen',
    name: 'FormPegawaiAbsen',
    component: FormPegawaiAbsen
  },
  {
    path: '/kepegawaian/absen/:id/:tanggal',
    name: 'FormPegawaiAbsenEdit',
    component: FormPegawaiAbsen
  },
  {
    path: '/kepegawaian/gaji',
    name: 'FormPegawaiGaji',
    component: FormPegawaiGaji
  },
  {
    path: '/kesiswaan',
    name: 'Kesiswaan',
    component: Kesiswaan
  },
  {
    path: '/kesiswaan/siswa',
    name: 'FormSiswa',
    component: FormSiswa
  },
  {
    path: '/kesiswaan/siswa-edit/:id',
    name: 'FormSiswaEdit',
    component: FormSiswa
  },
  {
    path: '/kesiswaan/view/:id',
    name: 'ViewSiswa',
    component: ViewSiswa
  },
  {
    path: '/pembayaran',
    name: 'Pembayaran',
    component: Pembayaran
  },
  {
    path: '/keuangan',
    name: 'Keuangan',
    component: Keuangan
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: Laporan
  },
  {
    path: '/pengumuman',
    name: 'Pengumuman',
    component: Pengumuman
  },
  {
    path: '/pengumuman/view/:id',
    name: 'ViewPengumuman',
    component: ViewPengumuman
  },
  {
    path: '/pengumuman/add',
    name: 'FormPengumuman',
    component: FormPengumuman
  },
  {
    path: '/pengumuman/edit/:id',
    name: 'FormPengumumanEdit',
    component: FormPengumuman
  },
  {
    path: '/konfigurasi/tahun-ajaran',
    name: 'KonfigurasiUmum',
    component: KonfigurasiUmum
  },
  {
    path: '/konfigurasi/tagihan-siswa/:additional',
    name: 'FormTagihanSiswa',
    component: FormTagihanSiswa
  },
  {
    path: '/konfigurasi/mulai-tahun-ajaran-baru',
    name: 'FormStartTahunAjaran',
    component: FormStartTahunAjaran
  },
  {
    path: '/konfigurasi/sekolah',
    name: 'KonfigurasiSekolah',
    component: KonfigurasiSekolah
  },
  {
    path: '/konfigurasi/sekolah/edit',
    name: 'FormSekolah',
    component: FormSekolah
  },
  {
    path: '/konfigurasi/akun',
    name: 'KonfigurasiAkun',
    component: KonfigurasiAkun
  },
  {
    path: '/konfigurasi/master-data',
    name: 'KonfigurasiMasterData',
    component: KonfigurasiMasterData
  },
  {
    path: '/pembayaran/pembayaran/:id',
    name: 'FormPembayaran',
    component: FormPembayaran
  },
  {
    path: '/pembayaran/angsuran/:id',
    name: 'FormAngsuran',
    component: FormAngsuran
  },
  {
    path: '/pembayaran/konfirmasi/:id',
    name: 'FormKonfirmasiPembayaran',
    component: FormKonfirmasiPembayaran
  },
  {
    path: '/add-akunwali/',
    name: 'FormAkunWali',
    component: FormAkunWali
  },
  {
    path: '/edit-akunwali/:id',
    name: 'EditFormAkunWali',
    component: FormAkunWali
  },
  {
    path: '/konfigurasi/akun-admin',
    name: 'KonfigurasiAkunAdmin',
    component: KonfigurasiAkunAdmin
  },
  {
    path: '/konfigurasi/akun-pegawai',
    name: 'KonfigurasiAkunPegawai',
    component: KonfigurasiAkunPegawai
  },
  {
    path: '/add-akunadmin/',
    name: 'FormAkunAdmin',
    component: FormAkunAdmin
  },
  {
    path: '/edit-akunadmin/:id',
    name: 'FormAkunAdminEdit',
    component: FormAkunAdmin
  },
  {
    path: '/konfigurasi/add-akunpegawai',
    name: 'FormAkunPegawai',
    component: FormAkunPegawai
  },
  {
    path: '/konfigurasi/akun-admin/view-admin-sekolah/:id',
    name: 'ViewAdminSekolah',
    component: ViewAdminSekolah
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to) => {
  if (
    (!localStorage.getItem("token") || localStorage.getItem("token") === 'null') &&
    to.name !== 'ResetPassword' &&
    to.name !== 'Login'
    ) {
    return { name: 'Login' }
  }
  if (
    (localStorage.getItem("token") && localStorage.getItem("token") !== 'null') &&
    (to.name === 'ResetPassword' || to.name === 'Login')
    ) {
    return { name: 'Home' }
  }
})
export default router
