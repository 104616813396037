<template>
  <LayoutMain>
    <button
      class="btn link distance"
      @click="back">
      <Back class="fa"/>
      {{'Kembali'}}
    </button>
    <div class="form-add">
      <div class="main">
        <b class="header">
          {{ header }}
        </b>
        <div class="body">
          <div class="form-group">
            <label>
              Penerima
              <i class="required ml-6">*</i>
            </label>
            <el-select v-model="pengumuman.penerima" placeholder="Penerima" class="w-50">
              <el-option
                v-for="item in penerimaArray"
                :key="item.id"
                :label="item.label"
                :value="item.id"/>
            </el-select>
          </div>
          <div class="form-group">
            <label>
              Judul
              <i class="required ml-6">*</i>
            </label>
            <input
              type="text"
              v-model="pengumuman.judul"
              maxlength="190">
          </div>
          <div class="form-group">
            <label>
              Deskripsi
              <i class="required ml-6">*</i>
            </label>
            <textarea
              type="text"
              v-model="pengumuman.deskripsi"
              rows="5" />
          </div>
          <div class="form-group">
            <label>
              Lampiran
              <div style="color:#697586; font-size:12px">(jpg/png/pdf)</div>
            </label>
            <div class="flex gap-16">
              <div
                class="avatar rounded p-16 pointer"
                @click="$refs.avatar.click()">
                <UploadFilled class="icon" />
                <span style="white-space: nowrap">Unggah file</span>
                <input
                  ref="avatar"
                  type="file"
                  class="none"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  @change="onFileChange">
              </div>
              <div class="flex column w-100">
                <span>Mengunggah file:</span>
                <div
                  v-for="item in pengumuman.lampiran"
                  :key="item.path"
                  class="flex between bt py-8">
                  <div class="flex center gap-8">
                    <Document class="fa-24"/>
                    <span>{{ item.name }} </span>
                  </div>
                  <Delete class="fa-24 f-right pointer" @click="deleteLampiran(item)" />
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="footer flex gap-16">
          <button class="btn danger" @click="back">Batal</button>
          <button class="btn primary" @click="save">Simpan</button>
        </div>
      </div>
    </div>
  </LayoutMain>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import LayoutMain from '@/components/layout/LayoutMain.vue'
  import helpers from '@/lib/helper'
  import { ElMessage } from 'element-plus'

  export default {
    name: 'FormPengumuman',
    components: { LayoutMain },

    data() {
      return {
        img: null,
        pengumuman: {
          penerima: "",
          judul: "",
          deskripsi: "",
          lampiran: [],
        },
        penerimaArray: [
          {
            id: 1,
            label: 'Semua'
          },
          {
            id: 2,
            label: 'Guru'
          },
          {
            id: 3,
            label: 'Siswa'
          },
        ],
        route: this.$route.params.id,
      }
    },
    computed: {
      ...mapGetters({
        agamaArray: 'agama',
        jabatanArray: 'jabatan',
      }),
      header() {
        return this.route ? 'Siarkan pengumuman' : 'Siarkan pengumuman'
      },
    },
    mounted() {
      if(this.route) {
        this.$http.post('v1/pengumuman/detilpengumuman', JSON.stringify({"id":this.route}))
        .then((res) => {
          this.pengumuman = res.data.data
          this.img =  res.data.data.avatar
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
    methods: {
      back() {
        return this.$router.push({ path: '/pengumuman' })
      },
      inputNumber($event) {
        helpers.numberOnly($event)
      },
      async save() {
        const data = {
          "id": this.route ?this.route : null,
          "penerima": this.pengumuman.penerima,
          "judul": this.pengumuman.judul,
          "deskripsi": this.pengumuman.deskripsi,
          "files": this.pengumuman.lampiran
        }
        if(!this.route) {
          this.$http.put('v1/pengumuman/index', JSON.stringify(data))
          .then(() => {
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
            this.back()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: 'Gagal simpan, '+ err.message,
              type: 'error',
            })
          });
        } else {
          this.$http.patch('v1/pengumuman/index', JSON.stringify(data))
          .then(() => {
            ElMessage({
              showClose: true,
              message: 'Data berhasil di simpan',
              type: 'success',
            })
            this.back()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: 'Gagal simpan, '+ err.message,
              type: 'error',
            })
          });
        }
      },
      onFileChange(e) {
        const image = e.target.files[0];
        this.img = URL.createObjectURL(image);
        this.$http.post(
          'v1/attachment/upload',
          { "UploadFile":image },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        )
        .then((res) => {
          this.pengumuman.lampiran.push({
            name: res.data.name,
            path: res.data.data,
            ext: res.data.ext,
          });
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            message: err,
            type: 'error',
          })
        });
      },
      deleteLampiran(item) {
        this.pengumuman.lampiran.splice(this.pengumuman.lampiran.indexOf(item), 1);
        this.$http.get('v1/attachment/deletefile?name='+item.path)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            message: err,
            type: 'error',
          })
        });
      }
    },
  }
</script>